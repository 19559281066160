import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

const MySelect = (props) => {
  if (props) {
    // props.allowSelectAll
    return (
      <ReactSelect 
        {...props}
        options={[...props.options]}
        onChange={(selected) => {
          // if (
          //   selected !== null &&
          //   selected.length > 0 &&
          //   selected[selected.length - 1].value === props.allOption.value
          // )
          // {
          //   return props.onChange(props.allOption, props.options);
          // }
          return props.onChange(selected);
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: 56,
            overflow: "auto",
            width:"400px",
            // 500px 
            paddingTop: 3,
          }),
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

MySelect.propTypes = {
  options: PropTypes.array,
  // value: PropTypes.any,
  // onChange: PropTypes.func,
  // allowSelectAll: PropTypes.bool,
  // allOption: PropTypes.shape({
  // label: PropTypes.string,
  // value: PropTypes.string,
  // placeholder:"all selected"
  // }),
};

MySelect.defaultProps = {
  allOption: {
    label: "selectAll",

    value: "*",
  },
};

export default MySelect;
