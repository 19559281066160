import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../../../../utils";
import graph from "../../../../assests/graph.png";
import PosTable from "./PosTable";
import "./pos.css";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../../../logic.js";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

let API_CONFIG = {
  pos_Results: {
    method: "GET",
    url: "/pos/",
  },
};

const COL_LIST1 = [
  {
    key: "clinicalId",
    label: "Clinical ID",
  },
  { key: "pos", label: "PoS" },
];

const COL_LIST2 = [
  {
    key: "feature",
    label: "Features",
  },
  { key: "correlation", label: "Correlation" },
];

const Pos = () => {
  const { hashid } = useParams();
  const [posData, setPosData] = useState({ data: null });
  const dispatch = useDispatch();
  const apiCall = (hashId) => {
    const apiPayload = { ...API_CONFIG.pos_Results };
    apiPayload.url += `?hashid=${hashId}`;

    apiClient(apiPayload)
      .then((res) => {
        setPosData({ data: res });
        //console.log("from pos Screen",res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    apiCall(hashid);
  }, [hashid]);

  return (
    <>
      <div style={{ display: "flex", padding: "2%", alignItems: "center" }}>
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "1px 1px #D3D3D3",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
          onClick={() => {
            window.history.go(-1);
          }}
        >
          <ArrowBackIcon fontSize="small" />
          Back
        </Button>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
        >
          PoS- {posData.data?.data?.[0]?.ticker}{" "}
        </Typography>
      </div>
      <div className="pos_page">
        <p>Probability of success as fetched from our CTP model</p>
        <div className="pos_data">
          <section className="container" style={{ backgroundColor: "white" }}>
            <div
              className="tables"
              style={{ width: 900, margin: 30, padding: 10 }}
            >
              <PosTable
                className=""
                columnList={COL_LIST1}
                //  data={data?.results}
                data={posData.data?.data ? posData.data.data[0].posResults : []}
              />
              <h3 style={{ fontWeight: "bold", paddingTop: 50 }}>
                Top Features driving the PoS
              </h3>
              <PosTable
                className=""
                columnList={COL_LIST2}
                //  data={data?.results}
                data={posData.data?.data ? posData.data.data[0].topFeature : []}
              />
            </div>
            <div
              className="analysis zoom"
              style={{
                margin: 50,
                width: 800,
                height: 600,
                padding: 10,
                boxShadow: "5px 5px #D3D3D3",
                textAlign: "center",
              }}
            >
              <h3>SHAP analysis plot</h3>
              <p>
                (Showing Features <br /> Importance and Direction)
              </p>
              <div style={{ width: 20, height: 50 }}></div>
              <img
                src={posData.data?.data ? posData.data.data[0].images : graph}
                alt="graph"
                style={{ height: 400 }}
              />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Pos;
