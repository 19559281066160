import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Box,
  Autocomplete,
  Typography,
  Stack,
  FormControlLabel,
  TextField,
  Switch,
  Input,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import apiClient from "../../utils";
import "./AddPosition.scss";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../logic.js";

const ModalForm = ({ modalOpen, setModalOpen }) => {
  const modalStyle = {
    backgroundColor: "white",
    border: "2px solid #000",
    padding: "40px",

    maxWidth: "90%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  };
  let API_CONFIG = {
    COMPANY_NAME: {
      method: "GET",
      url: "/company_info/",
      data: {},
    },
    Add_Position: {
      method: "POST",
      url: "/addPosition/",
      data: {},
    },
  };

  const [option, setOption] = useState("");
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    portfolioName: "portfolio1",
    ticker: "",
    date: "",
    buySell: "buy",
    entryPrice: "",
    quantity: "",
    stopLoss: "",
    targetPrice: "",
  });

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleAddPosition = () => {
    const apiPayload = { ...API_CONFIG.Add_Position };
    apiPayload.data = formData;
    apiClient(apiPayload)
      .then((res) => {})
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
    setModalOpen(false);
  };

  const setOptions = () => {
    const apiPayload = { ...API_CONFIG.COMPANY_NAME };

    apiClient(apiPayload)
      .then((res) => {
        let arr = res?.data.map((data, idx) => {
          return { label: data.company_name, value: data.ticker };
        });
        setOption(arr);
      })
      .catch((err) => {
        console.log({ message: err });

        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    setOptions();
  }, []);

  return (
    <div className="modal-form">
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box style={modalStyle}>
          <div className="modal-content">
            <Autocomplete
              className="autocomplete-wrap"
              popupIcon={<ExpandMoreIcon />}
              options={option}
              value={formData.ticker || ""}
              onChange={(e, newValue) => {
                const ticker = newValue.value;
                handleInputChange("ticker", ticker);
              }}
              renderInput={(params) => (
                <TextField
                  className="autocomplete-options"
                  {...params}
                  placeholder="Ticker"
                  variant="outlined"
                />
              )}
            />
            <div className="date-and-switch">
              <div className="date-component">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    placeholder="Date"
                    className="date"
                    // value={formData.date}
                    onChange={(date) => {
                      const formatteddate = date.format("YYYY-MM-DD");
                      handleInputChange("date", formatteddate);
                    }}
                    // renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                className="switch-component"
              >
                <Typography style={{ color: "#ff0000" }}>Sell</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.buySell === "buy"}
                      onChange={() =>
                        handleInputChange(
                          "buySell",
                          formData.buySell === "buy" ? "sell" : "buy"
                        )
                      }
                      inputProps={{ "aria-label": "Buy" }}
                      className={
                        formData.buySell === "buy"
                          ? "sell-switch"
                          : "buy-switch"
                      }
                    />
                  }
                />
                <Typography style={{ color: "#05c00d" }}>Buy</Typography>
              </Stack>
            </div>
            <div className="date-and-switch">
              <div className="row-div">
                <Typography>Entry Price</Typography>
                <Input
                  className="text-field"
                  variant="outlined"
                  value={formData.entryPrice}
                  onChange={(e) =>
                    handleInputChange("entryPrice", parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="row-div">
                <Typography>Quantity</Typography>
                <Input
                  className="text-field"
                  variant="outlined"
                  value={formData.quantity}
                  onChange={(e) =>
                    handleInputChange("quantity", parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
            <div className="date-and-switch">
              <div className="row-div">
                <Typography>Stop Loss</Typography>
                <Input
                  className="text-field"
                  variant="outlined"
                  value={formData.stopLoss}
                  onChange={(e) =>
                    handleInputChange("stopLoss", parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="row-div">
                <Typography>Target Price</Typography>
                <Input
                  className="text-field"
                  variant="outlined"
                  value={formData.targetPrice}
                  onChange={(e) =>
                    handleInputChange("targetPrice", parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-actions">
            <Button
              onClick={() => setModalOpen(false)}
              variant="contained"
              style={{
                width: "70%",
                backgroundColor: "#757575",
                color: "#fffff",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddPosition}
              variant="contained"
              style={{
                width: "70%",
                backgroundColor: "#002e64",
                color: "#ffffff",
              }}
            >
              Add Position
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalForm;
