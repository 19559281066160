import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
//import apiCall from "../login/logic"; */
//import { session } from "../../utils";
//import { loginSuccess } from "../TempraryReducer";
import { MarketData } from "react-ts-tradingview-widgets";
// import Grid from "@mui/material/Grid";
import Button from "@material-ui/core/Button";
//import logo from "../assests/g10.png";
import dashPic from "../assests/landinghero.png";
import stockPic from "../assests/stocks.png";
import image373 from "../assests/image373.png";
import image374 from "../assests/image374.png";
import image375 from "../assests/image375.png";
import { ReactSVG } from "react-svg";
import logoMain from "../assests/logo.svg";
import "./LandingPage.css";
import Login from "../login";
import { CreateAccount } from "./CreateAccount";
import { Grid, Box } from "@mui/material";
import { EmailModal } from "./EmailModal";
import { OtpModal } from "./OtpModal";

const LandingPage = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenAccount = () => {
    setOpenAccount(true);
  };

  const { flag } = useSelector((state) => state.userData);

  useEffect(() => {
    /*      if (session.checkIfLogin()) {
      history.push("/");
    }  */
    if (flag === true) {
      history.push("/home");
    }
  }, [flag]);

  return (
    <>
      <div className="landing_container">
        <header className="menu_items">
          <div className="brandlogo">
            <ReactSVG src={logoMain} />
            {/* svg used for showing quality image / logo */}
          </div>
          <div style={{ display: "flex" }}>
            <Button
              style={{ marginRight: "4%" }}
              className="menu_btn"
              variant="contained"
              type="reset"
              size="large"
              onClick={handleOpenAccount}
            >
              Create Account
            </Button>
            <Button
              className="menu_btn"
              variant="contained"
              type="reset"
              size="large"
              onClick={handleOpen}
            >
              Login
            </Button>
          </div>
        </header>
        <Login
          open={open}
          setOpen={setOpen}
          setOpenAccount={setOpenAccount}
          setEmailModal={setEmailModal}
        />
        <CreateAccount
          openAccount={openAccount}
          setOpenAccount={setOpenAccount}
        />
        <EmailModal
          emailModal={emailModal}
          setEmailModal={setEmailModal}
          setOtpModal={setOtpModal}
        />
        <OtpModal otpModal={otpModal} setOtpModal={setOtpModal} />

        {/* LOGIN MODAL START */}

        {/* LOGIN MODAL END */}

        <section className="section_1">
          <Grid container spacing={1} columns={21}>
            <Grid item xs={21} sm={10} md={10} lg={10} xl={10}>
              <div className="section_left">
                <h2>Hello !</h2>
                <h3>
                  Here’s a mechanism helping you pick right stock at right time
                  in the Biopharma Universe.
                </h3>
              </div>
            </Grid>

            <Box
              component={Grid}
              item
              sm={10}
              md={10}
              lg={10}
              xl={10}
              display={{ xs: "none", sm: "block" }}
            >
              <div className="section_right">
                <img src={dashPic} alt="pic" className="res_pic" />
              </div>
            </Box>
          </Grid>
        </section>
        <section className="section_2">
          <Grid container spacing={1} columns={21}>
            <Grid item xs={21} sm={10} md={10} lg={10} xl={10}>
              <div style={{ paddingLeft: "15%" }}>
                <h1
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    marginLeft: "2%",
                  }}
                  className="section2_heading"
                >
                  Our Platform Elements
                </h1>
                <div className="link_card">
                  <h3>Analysed Readouts</h3>

                  <p>
                    Upcoming readouts which have been analysed in-depth by
                    analyst looking into biological and commercial aspects of
                    the drug along with company financials
                  </p>
                </div>
                <div className="link_card">
                  <h3>All upcoming Readouts</h3>
                  <p>
                    Details of all upcoming readouts. Attractive Readouts are
                    picked from this list for further Analysis.
                  </p>
                </div>
                <div className="link_card">
                  <h3>Historical Readouts</h3>

                  <p>
                    Details of all Historicals readouts along with stock
                    performance.
                  </p>
                </div>
              </div>
            </Grid>
            <Box
              component={Grid}
              item
              sm={10}
              md={10}
              lg={10}
              xl={10}
              display={{ xs: "none", sm: "block" }}
            >
              <div className="section_right">
                <img src={stockPic} alt="pic" className="sect2_pic" />
              </div>
            </Box>
          </Grid>
        </section>
        <section className="section_3">
          <h1>
            Why <span style={{ color: "#076281" }}>Us?</span>
          </h1>
          <div className="section_down">
            <div className="section3_down">
              <img src={image373} alt="pic" />
              <h3>
                Track all the upcoming readouts with real time date updates.
              </h3>
            </div>
            <div className="section3_down">
              <img src={image374} alt="pic" />
              <h3>
                Get Trusted AI results and expert’s opinion on any Clinical
                trial readout.{" "}
              </h3>
            </div>
            <div className="section3_down">
              <img src={image375} alt="pic" />
              <h3>
                Get real time updates on latest news and price movement in any
                stock
              </h3>
            </div>
          </div>
        </section>
        <section className="section_4">
          <h1>
            Market{" "}
            <span
              style={{
                color: "#076281",
              }}
            >
              Quotes
            </span>
          </h1>
          <div>
            <MarketData
              colorTheme="light"
              max-width="100%"
              height="40vh"
              //  symbolsGroups={MarketDataSymbolsGroup}
            ></MarketData>
            {/*  <div id="myContainer">
              <div className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget"></div>
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default LandingPage;

{
  /*  <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmit}>
            <Box sx={style}>
              <Stack direction="row">
                <Box sx={{ width: "50%", padding: "5%", margin: "0 auto" }}>
                  <ReactSVG src={pbloginlogo} style={{ textAlign: "center" }} />
                  <Typography
                    id="modal-modal-title"
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{ fontWeight: "bold" }}
                  >
                    LOGIN TO YOUR ACCOUNT
                  </Typography>
                  <Box
                    sx={{
                      padding: "4% 0",
                    }}
                  >
                    <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                      Username*
                    </Typography>
                    <input
                      placeholder="write your username"
                      className="loginInputSection"
                      onChange={handleEmail}
                      value={username}
                      name="email"
                    />
                  </Box>
                  <Box>
                    <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                      Password*
                    </Typography>
                    <input
                      placeholder="Write your password"
                      className="loginInputSection"
                      onChange={handlePassword}
                      value={password}
                      name="password"
                    />
                    <Typography
                      variant="subtitle2"
                      display="block"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        color: "#4F57AA",
                        marginRight: "10%",
                      }}
                    >
                      Forget password?
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "3% 0" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="loginPageLoginButton"
                    >
                      Login
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      display="block"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      not registered yet?
                      <span style={{ color: "#4F57AA" }}>
                        {" "}
                        Create an account
                      </span>{" "}
                    </Typography>
                  </Box>
                </Box>

                <div style={{ width: "50%" }}>
                  <img
                    src={loginSideImage}
                    alt="bioPharmaImage"
                    className="sideImageLogo"
                  />
                </div>
              </Stack>
            </Box>
          </form>
        </Modal> */

  {
    /* <Button
              className="dashboard_btn"
              variant="contained"
              type="submit"
              size="large"
              // onClick={handleModal}
            >
              Dashboard
            </Button> */
  }
}
