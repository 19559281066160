import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ProgressCircleWrapper } from "./ProgressArc";
import Reward from "./Reward";
import { CatalystData } from "./Catalyst";
import { Button } from "@material-ui/core";
import apiClient from "../../../utils";
import { apiFailure } from "../../../logic";
import { useDispatch } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton } from "@mui/material";
let API_CONFIG = {
  analyzedResults: {
    method: "GET",
    url: "/analysed_catalysts/docDownload",
    responseType: "blob",
  },
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AnalysedTable({ columnList, data }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDownload = (index) => {
    return null;
  };

  const handlePDFDownload = (hashID) => {
    const apiPayload = { ...API_CONFIG.analyzedResults };

    apiPayload.url += `?docId=${hashID}`;
    // setLoading(true);

    apiClient(apiPayload)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/pdf",
          })
        );

        const a = document.createElement("a");
        a.href = url;
        a.download = "Document.pdf";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const renderHeadCols = () => {
    return columnList.map((row, index) => {
      return (
        <StyledTableCell
          className="heading-col"
          key={`head_${row.lable}_${index}`}
          // align="center"
        >
          {row.label}
        </StyledTableCell>
      );
    });
  };

  const handleClick = (keyData, index, symbol, drug, indication, nct_id) => {
    console.log(keyData, index, symbol, drug);
    const url = `https://www.drugs.com/search.php?searchterm=${drug}`;
    const wikiUrl = `https://en.wikipedia.org/wiki/${indication}`;
    const nctUrl = `https://clinicaltrials.gov/study/${nct_id}`;
    if (index === 0) {
      history.push(`/company_info/?symbol=${symbol}`);
    }
    if (index === 1) {
      // history.push(`/company_info/?symbol=${symbol}`);
      window.open(url, "_blank");
    }
    if (index === 2) {
      // history.push(`/company_info/?symbol=${symbol}`);
      window.open(wikiUrl, "_blank");
    }
    if (index === 3) {
      history.push(`/riskreward/${keyData}`);
    }
    if (index === 4) {
      history.push(`/checklists/${keyData}`);
    }
    if (index === 5) {
      history.push(`/pos/${keyData}`);
    }
    if (index === 6) {
      history.push(`/moa/${keyData}`);
    }
    if (index === 7) {
      window.open(nctUrl, "_blank");
    }
    // if (index === 5) {
    //   history.push(`/priorresults/${keyData}`);
    // }
  };

  const renderResults = (identity, cellData, index) => {
    if (cellData !== undefined) {
      if (identity === "pos") {
        return <ProgressCircleWrapper recPercent={cellData} />;
      }
      if (identity === "checklist_score_avg") {
        return <ProgressCircleWrapper recPercent={cellData} />;
      }
      if (identity === "moa") {
        return cellData;
      }
      if (identity === "drug_name") {
        return cellData;
      }
      if (identity === "indication") {
        return cellData;
      }
      if (identity === "nct_id") {
        return cellData;
      }
      if (identity === "prior_result") {
        return cellData;
      }
      if (identity === "risk_rewards") {
        return <Reward reWard={cellData} />;
      }
      if (identity === "catalyst_infos") {
        return <CatalystData cataLystData={cellData} />;
      }
      if (identity === "download") {
        return <Button onClick={() => handleDownload(index)}></Button>;
      }
    }
  };

  // const renderCells = (row) => {
  //   return columnList.map((col, index) => (
  //     <StyledTableCell
  //       key={`row_${col.key}_${index}`}
  //       className="cells"
  //       onClick={() =>
  //         handleClick(row.hashId, index, row.catalyst_infos.ticker)
  //       }
  //     >
  //       {console.log("col", col)}
  //       {row?.[col.key] !== null
  //         ? renderResults(col.key, row[col.key], index)
  //         : "-"}
  //     </StyledTableCell>
  //   ));
  // };

  const renderCells = (row) => {
    return columnList.map((col, index) => (
      <StyledTableCell
        key={`row_${col.key}_${index}`}
        className="cells"
        onClick={() =>
          col.key !== "Analysis" &&
          handleClick(
            row.hashId,
            index,
            row.catalyst_infos.ticker,
            row.drug_name,
            row.indication,
            row?.nct_id
          )
        }
      >
        {col.key === "Analysis" ? (
          // <Button
          //   className="analysis-button"
          //   style={{
          //     backgroundColor: "#002e64",
          //     color: "#ffffff",
          //     width: "1%",
          //   }}
          //   onClick={() => handlePDFDownload(row.hashId)}
          // >
          //   <FileDownloadIcon />
          // </Button>
          <IconButton onClick={() => handlePDFDownload(row.hashId)}>
            <FileDownloadIcon />
          </IconButton>
        ) : (
          <>
            {row?.[col.key] !== null
              ? renderResults(col.key, row[col.key], index)
              : "-"}
          </>
        )}
      </StyledTableCell>
    ));
  };

  const renderTableRows = () => {
    return data.map((row, index) => (
      <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
    ));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <p style={{ paddingRight: " 2%" }}>
          {" "}
          {/*  Showing {data.length} of {count?.count} results */}
          Showing {data.length} results
        </p>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "70vh", //
        }}
        className="ana_Table"
      >
        <Table
          sx={{ minWidth: 600 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>

          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
