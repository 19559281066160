/* eslint-disable no-unused-expressions */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
    // border: "1px solid black",
    // borderRadius: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // border: "1px solid black",
    // borderRadiusLeftTop: "10px",
    // borderRadiusRightTop: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: "1px solid black",
    // borderRadius: "10px",
  },
}));

export default function PosTable({ columnList, data, className = "" }) {
  const renderHeadCols = () => {
    return columnList.map((row, index) => (
      <StyledTableCell key={`head_${row.label}_${index}`} align="center">
        {row.label}
      </StyledTableCell>
    ));
  };

  const renderCells = (row) => {
    return columnList.map((col, index) => (
      <StyledTableCell
        className={col.tableCellClassName || ""}
        key={`row_${col.key}_${index}`}
        align="center"
      >
        {col.customCell?.(row, index) || row?.[col.key]}
      </StyledTableCell>
    ));
  };

  const renderTableRows = () => {
    return data.map((row, index) => (
      <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
    ));
  };

  return (
    <TableContainer component={Paper} className="Upcoming_table">
      {/*     <p style={{ textAlign: "right", padding: "5px" }}>Showing 1 out of 1</p> */}
      <Table sx={{ minWidth: 500 }} aria-label="customized table">
        <TableHead>
          <TableRow>{renderHeadCols()}</TableRow>
        </TableHead>
        <TableBody>{renderTableRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}
