import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Box, Stack, Button } from "@mui/material";
import loginSideImage from "../assests/loginSidePopupImage.png";
import pbloginlogo from "../assests/pbloginlogo.svg";
// import { ReactSVG } from "react-svg";
import axios from "axios";
// import apiClient from "../../utils/apiClient";
import { useDispatch } from "react-redux";
//  import {useSelector} from "react-redux";
import { apiFailure } from "../../logic";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  margin: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

// let API_CONFIG = {
//     creatingUser:{
//      method:"POST",
//     url:"/createuser/",
//     data:{},
//     }

// };

export const CreateAccount = ({ openAccount, setOpenAccount }) => {
  const dispatch = useDispatch();
  //  const errorMessage = useSelector((state)=>state.apiStatus);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
  });

  const userEntryInDb = (userData) => {
    const { first_name, last_name, username, password, confirm_password } =
      userData;
    if (
      first_name === "" ||
      last_name === "" ||
      username === "" ||
      password === "" ||
      confirm_password === ""
    ) {
      alert("Field Cannot be empty");
    } else if (password !== confirm_password) {
      alert("Passwords do not match");
      setUserData({ ...userData, password: "", confirm_password: "" });
    } else {
      //  const apiPayload = {...API_CONFIG.creatingUser};
      //  apiPayload.data = userData;
      axios
        .post(
          "https://thealphaexplorer.perpetualblock.io/api/createuser/",
          userData
        )
        .then((res) => {
          if (res.status === 201) {
            alert("Registration successful, login with your credentials");
            setOpenAccount(false);
          }
          setUserData({
            first_name: "",
            last_name: "",
            email: "",
            username: "",
            password: "",
            confirm_password: "",
          });
        })
        .catch((err) => {
          console.log({ message: err.message }, "from error");
          const message = { message: err.message };
          console.log(message.message, "from error2");
          if (message.message === "Request failed with status code 400") {
            alert("Username Already Exist Please Try Another");
            setUserData({ ...userData, username: "" });
          }
          dispatch(apiFailure(err));
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(userData);
    userEntryInDb(userData);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData((val) => ({ ...val, [name]: value }));
    //console.log(userData);
  };

  const handleClose = () => {
    setOpenAccount(false);
  };

  return (
    <Modal
      open={openAccount}
      onClose={handleClose}
      aria-labelledby="modal-modal-title1"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={handleSubmit}>
        <Box sx={style}>
          <Stack direction="row">
            <Box sx={{ width: "50%", padding: "1% 5%", margin: "0 auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0",
                }}
              >
                <img
                  src={pbloginlogo}
                  style={{ textAlign: "center", width: "25%" }}
                  alt=""
                />
              </div>
              <Typography
                id="modal-modal-title1"
                gutterBottom
                variant="h6"
                component="h2"
                style={{ fontWeight: "bold" }}
              >
                Create Account
              </Typography>
              <Box
                sx={{
                  padding: "1% 0",
                }}
              >
                <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                  First Name
                </Typography>
                <input
                  placeholder="write your first name"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.first_name || ""}
                  name="first_name"
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  padding: "0 0",
                }}
              >
                <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                  Last Name
                </Typography>
                <input
                  placeholder="write your last name"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.last_name || ""}
                  name="last_name"
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  padding: "1% 0",
                }}
              >
                {/* <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                   Email
                </Typography>
                <input
                  placeholder="write your email address"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.email || ""}
                  name="email"
                  autoComplete="off"
                  type="email"
                /> */}
              </Box>
              <Box
                sx={{
                  padding: "1% 0",
                }}
              >
                <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                  Email*
                </Typography>
                <input
                  placeholder="write your email"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.username || ""}
                  name="username"
                  type="email"
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ padding: "1% 0" }}>
                <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                  Password*
                </Typography>
                <input
                  placeholder="write your password"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.password || ""}
                  name="password"
                  autoComplete="off"
                  type="password"
                />
              </Box>

              <Box sx={{ padding: "1% 0" }}>
                <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                  Confirm Password*
                </Typography>
                <input
                  placeholder="write your confirm password"
                  className="loginInputSection"
                  onChange={handleChange}
                  value={userData.confirm_password}
                  name="confirm_password"
                  autoComplete="off"
                  type="password"
                />
              </Box>
              <Box sx={{ margin: "2% 0 3% 0" }}>
                <Button
                  type="submit"
                  variant="contained"
                  className="loginPageLoginButton"
                >
                  Submit
                </Button>
              </Box>
              {/* <Box>
                <Typography
                  gutterBottom
                  variant="subtitle2"
                  display="block"
                  style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  not registered yet?
                  <span style={{ color: "#4F57AA" }}>
                    {" "}
                    Create an account
                  </span>{" "}
                </Typography>
              </Box> */}
            </Box>

            <div style={{ width: "50%" }}>
              <img
                src={loginSideImage}
                alt="bioPharmaImage"
                className="sideImageLogo"
              />
            </div>
          </Stack>
        </Box>
      </form>
    </Modal>
  );
};
