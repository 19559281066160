import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {},
}));

export const CompanyFundTable = ({ columnList, data }) => {
  //console.log(columnList,data,"from fund Table");
  const renderHeadCols = () => {
    return columnList.map((row, index) => (
      <StyledTableCell key={`head_${row.label}_${index}`} align="center">
        {row.label}
      </StyledTableCell>
    ));
  };

  // const renderResults = (identity,cellData,) => {
  // if(cellData !== undefined){
  //     if(identity==="catalyst_info"){
  //         return <CompanyCatalystInfo data={cellData} bol={bol}/>;
  //     }
  //     if(identity==="analysed1"){
  //         return <Typography variant="subtitle2" component="div" style={cellData.analysed1==="TRUE" ? {color:"green"} : {color:"red"}}>{cellData.analysed1=== "TRUE" ? "YES" : "NO" }</Typography>;
  //     }
  // }
  // console.log(identity,cellData,"from renderResults");
  // };

  const renderCells = (row) => {
    return columnList.map((col, index) => (
      <StyledTableCell
        key={`row_${col.key}_${index}`}
        align={index === 0 ? "left" : "center"}
      >
        {index === 2 ? row[col.key].toFixed(2) : row[col.key]}
        {/* {row!==null ? renderResults(col.key,row) : "-"} */}
      </StyledTableCell>
    ));
  };

  const renderTableRows = () => {
    // console.log("from render rows function",data);

    return data[0] ? (
      data.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ))
    ) : (
      <Typography
        variant="h6"
        component="h2"
        style={{
          textAlign: "left",
          fontWeight: "bold",
          fontStyle: "italic",
          padding: "5% 0",
        }}
      >
        "No Data Found"
      </Typography>
    );
  };

  return (
    <div>
      <Typography variant="h6" component="h3" sx={{ fontWeight: "bold" }}>
        Institutional Holdings
      </Typography>
      <TableContainer
        sx={{ maxHeight: "450px", borderBottom: "1px solid #808080" }}
      >
        <Table sx={{}} aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
