import React from "react";
import { Button, Typography, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DCFImage from "../../../../../assests/DCF.png";

// import {useHistory} from "react-router-dom";

export const DCFInstructionPage = () => {
  return (
    <div>
      <div style={{ display: "flex", padding: "2%", alignItems: "center" }}>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "1px 1px #D3D3D3",
          }}
          onClick={() => {
            window.history.go(-1);
          }}
        >
          <ArrowBackIcon fontSize="small" />
          Back
        </Button>
        <Typography
          variant="h6"
          gutterBottom
          component="h2"
          style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
        >
          DCF screen - instruction
        </Typography>
      </div>
      <Typography
        variant="h6"
        gutterBottom
        component="h5"
        style={{ fontWeight: "bold", margin: "0 2% 2% 2%", textAlign: "left" }}
      >
        POS Assumptions
      </Typography>
      <div style={{ margin: "0 2%" }}>
        <Paper style={{ padding: "3%" }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h5"
            style={{ fontWeight: "bold", margin: "2.5% 0", textAlign: "left" }}
          >
            Phase wise average success rate of Clinical Trials in every
            Therapeutic
          </Typography>
          <Typography
            variant="button"
            component="h5"
            style={{ fontWeight: "bold", marginTop: "1%", textAlign: "left" }}
          >
            Page 9 : Clinical Development Success Rates 2006-2015 - BIO,
            Biomedtracker, Amplion 2016.pdf
          </Typography>
          <div style={{ display: "flex" }}>
            <a
              target="_blank"
              href="https://www.bio.org/sites/default/files/legacy/bioorg/docs/Clinical%20Development%20Success%20Rates%202006-2015%20-%20BIO,%20Biomedtracker,%20Amplion%202016.pdf"
            >
              https://www.bio.org/sites/default/files/legacy/bioorg/docs/Clinical%20Development%20Success%20Rates%202006-2015%20-%20BIO,%20Biomedtracker,%20Amplion%202016.pdf
            </a>
          </div>
          <img src={DCFImage} alt="DCF" style={{ width: "100%" }} />
        </Paper>
      </div>
    </div>
  );
};
