import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./index.css";
import {
  Form,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Modal,
  Row,
  Select,
  Input,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";

import apiClient from "../../../utils";

// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";

let API_CONFIG = {
  uniqueTA: {
    method: "GET",
    url: "/uniqueTA/",
  },
};

const AdvanceSearchModal = ({
  // setHistorical,
  // setReRunUseEffect,
  optionMcap,
  setOptionMcap,
  optionStage,
  setOptionStage,
  optionSentiment,
  setOptionSentiment,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  option,
  setOption,
  resetAll,
  form,
  setSearch,
  setTA,
}) => {
  const [modal2Open, setModal2Open] = useState(false);

  // const HistoricalRedouutesFilteredData = useSelector(
  //   (state) => state.globalState.upComingFilteredData
  // );
  // console.log(
  //   "Historical readouts filtered data : ",
  //   HistoricalRedouutesFilteredData
  // );

  const getData = async () => {
    const payload = { ...API_CONFIG.uniqueTA };
    apiClient(payload)
      .then((res) => {
        // console.log(res);

        const arr = res.data.map((item) => {
          return { label: item.uniqueTA, value: item.uniqueTA };
        });
        setOption(arr);
      })
      .catch((err) => console.log(err));
  };

  const renderOption = () => {
    return option?.map?.((item, idx) => {
      return (
        <Select.Option key={idx} value={item?.value}>
          {item.value}
        </Select.Option>
      );
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeMcap = (checkedValues1) => {
    // console.log("checked values from mcap = ", checkedValues1);

    setOptionMcap(checkedValues1);
  };
  const onChangeStage = (checkedValues2) => {
    setOptionStage(checkedValues2);
  };
  const onChangeSentiment = (checkedValues3) => {
    // console.log(optionSentiment);
    setOptionSentiment(checkedValues3);
  };

  const { Item: FormItem } = Form;

  const mCapOptions = [
    {
      label: "Mega Cap - 200Bln and More",
      value: "200000-2000000", // split parse
    },
    {
      label: "Large Cap - 10Bln to 200 Bln",
      value: "10000-200000",
    },
    {
      label: "Mid Cap - 1Bln to 10 Bln",
      value: "1000-10000",
    },
    {
      label: "Small Cap - 100 Mln to 1 Bln",
      value: "100-1000",
    },
    {
      label: "Micro Cap - Less than 100 Mln",
      value: "0-100",
    },
  ];

  const phaseOptions = [
    {
      label: "Phase 1",
      value: "Phase 1",
    },
    {
      label: "Phase 1/2",
      value: "Phase 1/2",
    },
    {
      label: "Phase 2",
      value: "Phase 2",
    },
    {
      label: "Phase 2/3",
      value: "Phase 2/3",
    },
    {
      label: "Phase 3",
      value: "Phase 3",
    },
    {
      label: "Phase 4",
      value: "Phase 4",
    },
    {
      label: "PDUFA",
      value: "PDUFA",
    },
  ];

  const sentimentOptions = [
    {
      label: "Positive",
      value: "Positive",
    },
    {
      label: "Negative",
      value: "Negative",
    },
    {
      label: "Inconclusive",
      value: "Inconclusive",
    },
  ];

  const handleSubmit = (Form) => {
    // console.log("handle submit form : ", Form);
    const { search, TherapeuticArea } = Form;
    setSearch(search);

    setTA(TherapeuticArea); //

    // const { search, marketCap,stage, sentiment , startdate , enddate , marketCapStart} = formValues;
  };

  // const handleSetEndDate = (date) => {
  //   console.log(
  //     "start date",
  //     new Date(startDate).getTime() < new Date(date).getTime()
  //   );
  //   if (new Date(startDate).getTime() < new Date(date).getTime()) {
  //     setEndDate(date?.format("YYYY-MM-DD"));
  //   } else {
  //     setEndDate(null);
  //   }
  // };

  return (
    <>
      {" "}
      <span
        className="modal"
        style={{
          cursor: "pointer",
          fontSize: "18px",
          color: "black",
          fontWeight: "bold",
        }}
        onClick={() => setModal2Open(true)}
      >
        <FilterOutlined />
        &nbsp; Filter View
      </span>
      <Modal
        title="Historical Readouts Advance Search "
        centered="true"
        width={1000}
        visible={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={null}
      >
        <div>
          <Form
            className="search"
            form={form}
            onFinish={handleSubmit}
            style={{
              flexDirection: "column",
              gap: "10px",
              padding: "34px",
            }}
          >
            <Row>
              <label htmlFor="" style={{ width: "200px" }}>
                Search :{" "}
              </label>
              <FormItem
                style={{ width: "66.0%", fontSize: "24px" }}
                name="search"
              >
                <Input
                  type="search"
                  placeholder="Search for Ticker , drug , disease"
                  size="large"
                  allowClear
                  name="search"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
              </FormItem>
            </Row>

            <Row>
              <label htmlFor="" style={{ width: "200px" }}>
                Therapeutic Area :
              </label>
              <FormItem
                label=""
                name="TherapeuticArea"
                style={{ width: "66.0%", fontSize: "24px" }}
              >
                <Select
                  size="large"
                  mode="multiple"
                  allowClear
                  placeholder="Please select"
                  showArrow
                  showSearch={true}
                  // options={option}
                >
                  {renderOption()}
                </Select>
              </FormItem>
            </Row>

            <Row>
              <label htmlFor="" style={{ width: "200px" }}>
                Date:
              </label>

              <Col>
                <FormItem
                  label=""
                  name="lastAuctionDateFrom"
                  style={{ width: "400px" }}
                >
                  {" "}
                  From :
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    placeholder="(YYYY-MM-DD)"
                    onChange={(date) =>
                      setStartDate(date?.format("YYYY-MM-DD"))
                    }
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  label=""
                  name="lastAuctionDateTo"
                  style={{ width: "200px" }}
                >
                  {" "}
                  To :
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    placeholder="(YYYY-MM-DD)"
                    onChange={(date) => setEndDate(date?.format("YYYY-MM-DD"))}

                    //disabledDate={disabledDate}
                    // setSelected={setSelected}
                    // disabledDate={(current) => {
                    //   return moment().add(-1, 'days')  >= current ||
                    //        moment().add(1, 'month')  <= current;
                    //   }}

                    //minDate={startDate}
                    //maxDate={Date.now}
                    // onChange={handleSetEndDate}
                  />
                </FormItem>
              </Col>
              <br></br>
            </Row>

            <Row>
              <Col className="Alabel" htmlFor="">
                Market Cap:
              </Col>
              <Col className="checkboxOp">
                <Checkbox.Group
                  options={mCapOptions}
                  defaultValue={[""]}
                  onChange={onChangeMcap}
                  checked={false}
                  value={optionMcap}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col
                className="Alabel"
                htmlFor=""
                // style={{ width: "200px" }}
              >
                Stage:
              </Col>
              <Col className="checkboxOp">
                <Checkbox.Group
                  options={phaseOptions}
                  defaultValue={[""]}
                  onChange={onChangeStage}
                  value={optionStage}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col
                className="Alabel"
                htmlFor=""
                // style={{ width: "200px" }}
              >
                Sentiment:
              </Col>
              <Col className="checkboxOp">
                <Checkbox.Group
                  options={sentimentOptions}
                  defaultValue={[""]}
                  onChange={onChangeSentiment}
                  value={optionSentiment}
                />
              </Col>
            </Row>
            <Row
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px",
              }}
            >
              <Button
                className="btn_model"
                htmlType="submit"
                onClick={() => {
                  setModal2Open(false);
                  // handleSubmit();
                }}
              >
                APPLY
              </Button>
              <Button
                // htmlType="reset"
                className="btn_model"
                onClick={resetAll}
                defaultChecked={true}
              >
                RESET
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default AdvanceSearchModal;
