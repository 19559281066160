import React, { useEffect, useRef } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

export const TradingChart = ({ symbol }) => {
  const tradingRef = useRef(null);

  useEffect(() => {
    console.log("useEffect trading view", tradingRef?.current?.props?.symbol);
  }, []);

  try {
    {
      return (
        <TradingViewWidget
          symbol={`${symbol}`}
          theme={Themes.LIGHT}
          locale="en"
          ref={tradingRef}
          autosize
        />
      );
    }
  } catch (error) {
    console.log("Exception catched in trading view ", error);
    return <></>;
  }
};
