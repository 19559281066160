import React from "react";

export const CompanyCatalystInfo = ({data,bol}) => {
    //console.log("from CompanyCatalystInfo", data);
  return (
    <div>
       <p><b>Drug :  </b> {data.drug_name}</p>
       <p><b>Disese : </b>{data.indication}</p>
       <p><b>Stage : </b>{data.label}</p>
       <p><b>Catalyst Date : </b>{bol ? data.catalyst_date : data.date}</p>
    </div>
  );
};
