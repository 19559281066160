import React from "react";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
// import Button from "@mui/material/Button";

export const MainSidebar = ({ history }) => {
  // const [key,setKey] = useState("9");
  // const handleTabs = () => {
  //    setKey("12");
  // };
  const myKey = useSelector((state) => state.globalState.sideBarKeyValue);

  return (
    <div>
      {/* <Button variant="contained" onClick={handleTabs}>Tabs</Button>  */}
      <Sidebar history={history} myKey={myKey} />
    </div>
  );
};
