import { combineReducers } from "redux";
import { loginDataReducer } from "../component/login/logic";
import { upcomingReducer } from "../component/MainBody/AllUpcomingReadouts/logic";
import { commonApiStatusReducer } from "../logic";
import { getUpcomingListReducer } from "../component/MainBody/AllUpcomingReadouts/logic";
import { historicalReducer } from "../component/MainBody/HistoricalReadouts/logic";
// import { historicalReducer } from "../component/MainBody/HistoricalReadouts/logic";

//import { temporaryReducer } from "../component/TempraryReducer";
import { appGlobalStateReducer } from "../component/MainBody/GlobalStateReducer/GlobalState";

export default combineReducers({
  ...commonApiStatusReducer,
  ...loginDataReducer,
  ...upcomingReducer,
  ...getUpcomingListReducer,
  ...historicalReducer,
  ...appGlobalStateReducer,
});
