import { useEffect, useState } from "react";

export function useColorIndication(passPercent) {
  const [colorIndicator, setColorIndicator] = useState("red");
  useEffect(() => {
    if (passPercent <= 30) {
      setColorIndicator("#CF1D08");
    } else if (passPercent <= 50) {
      setColorIndicator("#EF500B");
    } else {
      setColorIndicator("#009379");
    }
  }, [passPercent]);
  return colorIndicator;
}
