import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import "./index.css";

export const CustomHeader = (props) => {
  const [ascSort, setAscSort] = useState("inactive");
  const [descSort, setDescSort] = useState("inactive");
  const [noSort, setNoSort] = useState("inactive");
  const refButton = useRef(null);
  const [filterActive, setFilterActive] = useState(false);

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? "active" : "inactive");
    setDescSort(props.column.isSortDescending() ? "active" : "inactive");
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
        ? "active"
        : "inactive"
    );
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  const onFilterChanged = () => {
    if (props.column.isFilterActive()) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
    }
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
    props.column.addEventListener("filterChanged", onFilterChanged);
    onFilterChanged();
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className="customHeaderMenuButton"
        onClick={() => onMenuClicked()}
      >
        <Tooltip title="Filter Options">
          <div>
            <i className={`fa ${props.menuIcon}`}></i>
          </div>
        </Tooltip>
      </div>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <div style={{ display: "inline-block" }}>
        {filterActive && (
          <div className="customFilterLabel">
            <Tooltip title="Filter is active">
              <div>
                <i className={`fa fa-filter`}></i>
              </div>
            </Tooltip>
          </div>
        )}
        <div
          onClick={(event) => onSortRequested("asc", event)}
          onTouchEnd={(event) => onSortRequested("asc", event)}
          className={`customSortDownLabel ${ascSort}`}
        >
          <div>
            <Tooltip title="Ascending Sort">
              <div>
                <i class="fa fa-long-arrow-alt-down"></i>
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          onClick={(event) => onSortRequested("desc", event)}
          onTouchEnd={(event) => onSortRequested("desc", event)}
          className={`customSortUpLabel ${descSort}`}
        >
          <Tooltip title="Descending Sort">
            <div>
              <i class="fa fa-long-arrow-alt-up"></i>
            </div>
          </Tooltip>
        </div>
        <div
          onClick={(event) => onSortRequested("", event)}
          onTouchEnd={(event) => onSortRequested("", event)}
          className={`customSortRemoveLabel ${noSort}`}
        >
          <Tooltip title="Cancel Sort">
            <div>
              <i class="fa fa-times"></i>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div>
      {menu}
      <div className="customHeaderLabel">{props.displayName}</div>
      {sort}
    </div>
  );
};
