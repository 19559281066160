/* eslint-disable no-unused-expressions */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import { makeStyles } from "@material-ui/core";
import "./index.css";
import { useHistory } from "react-router-dom";
//import { TablePagination } from "@material-ui/core";
import apiClient from "../../../utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //
    backgroundColor: "#076281",
    color: theme.palette.common.white,
    //fontSize:12,
    // border: "1px solid black",
    // borderRadius: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    // border: "1px solid black",
    // borderRadiusLeftTop: "10px",
    // borderRadiusRightTop: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: "1px solid black",
    // borderRadius: "10px",
  },
}));

let API_CONFIG = {
  upcomingCalender: {
    method: "PUT",
    url: "/calendar/upcomings/",
  },
};

export default function CustomizedTables({ columnList, data, count, isAdmin }) {
  const history = useHistory();
  // const dispatch = useDispatch();
  //  const searchParam = useSelector(
  //   (state) => state.UpcomingSearchParamData.searchParam
  // );

  const apiCall = (queryData) => {
    const apiPayload = { ...API_CONFIG.upcomingCalender };

    apiPayload.url += `${queryData}`;
    // setLoading(true);

    apiClient(apiPayload)
      .then((res) => {
        //renderStatusChange(res);
        //console.log(res);

        let statusPutMessage = res.data.message;
        console.log(statusPutMessage);
        window.location.reload();
      })
      .catch((err) => console.log({ message: err }));
  };

  const handleStatusChange = (id) => {
    // const searchParamQuery = new URLSearchParams(searchParam);
    apiCall(id);
    // dispatch(startUpcomingSearch(searchParamQuery.toString()));
    // history.push("/");
  };

  const renderHeadCols = () => {
    return columnList.map((row, index) => {
      return (
        <StyledTableCell
          key={`head_${row.label}_${index}`} //
          align="center"
          className="coutom_headCol"
        >
          {row.label}
        </StyledTableCell>
      );
    });
  };

  const handleTradeViewClick = (symbol) => {
    history.push(`/company_info/?symbol=${symbol}`);
  };

  const renderCells = (row) => {
    return columnList.map((col, index) => {
      if (index === 0) {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells firstCellOFEachRow"
            onClick={() => handleTradeViewClick(row[col.key])}
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
          </StyledTableCell>
        );
      } else if (index === 9) {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells"
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
            {isAdmin === "YES" && row?.[col.key] === "to be analysed" && (
              <p
                className="analysedText"
                style={{ color: "#076281", fontSize: "12px" }}
                onClick={() => handleStatusChange(row?.[col.id])}
              >
                Change Status
              </p>
            )}
          </StyledTableCell>
        );
      } else {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells"
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
          </StyledTableCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (data.length > 0) {
      return data.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ));
    } else {
      return <h3>No data found</h3>;
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "5px",
          marginBottom: "-5px",
        }}
      >
        <p>*All price related values in $</p>
        <p style={{ paddingRight: " 2%" }}>
          {" "}
          Showing {data.length} of {count?.count} results
        </p>
      </div>
      <TableContainer
        component={Paper}
        //sx={{ maxHeight: "63.8vh" }}  // 60
        className="Upcoming_table1"
        //    classes={{ root: classes.customTableContainer }}
      >
        {/*  <p style={{ textAlign: "right", padding: "5px", padding: "1%" }}>
        Showing {data.length} of {count?.count} results
      </p> */}
        <Table
          sx={{ minWidth: 500 }}
          aria-label="customized sticky table"
          stickyHeader
          className="Upcoming_table1_content"
        >
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
          {/*  <TableFooter>
            <TableRow>
              <TablePagination count={10} />
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
      {/*   <TablePagination
        sx={{ position: "relative" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}GlobalUsername
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
}
