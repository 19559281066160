import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import {Chart as ChartJS,BarElement, CategoryScale,
//    LinearScale,} from 'chart.js';
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarDiagram = ({ bardata }) => {
  const {
    pastWeekIndex1to0Avg,
    pastWeekStock1to0Avg,
    pastMonthIndex1to0Avg,
    pastMonthStock1to0Avg,
    sinceFeb21Index1to0Avg,
    sinceFeb21Stock1to0Avg,
    beforeFeb21Index1to0Avg,
    beforeFeb21Stock1to0Avg,
    count1,
    count2,
    count3,
    count4,
    pastWeekNasdaq1to0Avg,
    pastMonthNasdaq1to0Avg,
    sinceFeb21Nasdaq1to0Avg,
    beforeFeb21Nasdaq1to0Avg,
  } = bardata;

  //console.log(copyOfTableData,"from bardiagram screen");
  // const data = [
  //     { readout: "Past Week", stock: Number(pastWeekStock1to0Avg), index: Number(pastWeekIndex1to0Avg) },
  //     { readout: "Past Month", stock: Number(pastMonthStock1to0Avg), index: Number(pastMonthIndex1to0Avg) },
  //     { readout: "Since Feb21", stock: Number(sinceFeb21Stock1to0Avg), index: Number(sinceFeb21Index1to0Avg) },
  //     { readout: "Before Feb21", stock: Number(beforeFeb21Stock1to0Avg) , index: Number(beforeFeb21Index1to0Avg) },
  // ];
  // [Number(pastWeekStock1to0Avg), Number(pastMonthStock1to0Avg), Number(sinceFeb21Stock1to0Avg), Number(beforeFeb21Stock1to0Avg)]
  // [Number(pastWeekIndex1to0Avg),Number(pastMonthIndex1to0Avg),Number(sinceFeb21Index1to0Avg),Number(beforeFeb21Index1to0Avg)]
  const data = {
    labels: [
      "Readouts in past Week",
      "Readouts in past Month",
      "Readouts since Feb 21",
      "Readouts before Feb 21",
    ],
    datasets: [
      {
        label: "Avg. Stock Returns",
        data: [
          {
            x: "Readouts in past Week",
            y: Number(pastWeekStock1to0Avg),
            count: Number(count1),
          },
          {
            x: "Readouts in past Month",
            y: Number(pastMonthStock1to0Avg),
            count: Number(count2),
          },
          {
            x: "Readouts since Feb 21",
            y: Number(sinceFeb21Stock1to0Avg),
            count: Number(count3),
          },
          {
            x: "Readouts before Feb 21",
            y: Number(beforeFeb21Stock1to0Avg),
            count: Number(count4),
          },
        ],
        type: "bar",
        fill: false,

        position: "bottom",
        backgroundColor: ["#ED7D31"],
        borderColor: ["#ED7D31"],
        borderWidth: 1,
      },
      {
        label: "Avg. Index Returns ($xbi) ",

        data: [
          {
            x: "Readouts in past Week",
            y: Number(pastWeekIndex1to0Avg),
            count: Number(count1),
          },
          {
            x: "Readouts in past Month",
            y: Number(pastMonthIndex1to0Avg),
            count: Number(count2),
          },
          {
            x: "Readouts since Feb 21",
            y: Number(sinceFeb21Index1to0Avg),
            count: Number(count3),
          },
          {
            x: "Readouts before Feb 21",
            y: Number(beforeFeb21Index1to0Avg),
            count: Number(count4),
          },
        ],
        type: "bar",
        fill: false,

        position: "bottom",
        backgroundColor: ["#4472C4"],
        borderColor: ["#4472C4"],
        borderWidth: 1,
      },
      {
        label: "Avg. Nasdaq Composite Returns ",

        data: [
          {
            x: "Readouts in past Week",
            y: Number(pastWeekNasdaq1to0Avg),
            count: Number(count1),
          },
          {
            x: "Readouts in past Month",
            y: Number(pastMonthNasdaq1to0Avg),
            count: Number(count2),
          },
          {
            x: "Readouts since Feb 21",
            y: Number(sinceFeb21Nasdaq1to0Avg),
            count: Number(count3),
          },
          {
            x: "Readouts before Feb 21",
            y: Number(beforeFeb21Nasdaq1to0Avg),
            count: Number(count4),
          },
        ],
        type: "bar",
        fill: false,

        position: "bottom",
        backgroundColor: ["#E1523D"],
        borderColor: ["#E1523D"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          title: (context) => `value:${context[0].raw.y}`,
          label: (context) => `count:${context.raw.count}`,
        },
      },

      title: {
        display: true,
        text: "Avg. Stocks Return & Avg. Index Returns",
        font: { size: 17 },
      },
    },
    scales: {
      x: { title: { display: true, text: "Time Frames", font: { size: 13 } } },
      y: {
        title: { display: true, text: "Avg Returns in %", font: { size: 13 } },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} height={340} />
    </div>
  );
};

export const BarDiagram1 = React.memo(BarDiagram);
