import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Bubble } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const BubblePlot = ({ dataSet }) => {
  const { data1, data2, data3, data4 } = dataSet;

  // console.log(dataSet);
  const history = useHistory();

  const data = {
    datasets: [
      {
        label: "0-100Mn",
        data: data1,
        backgroundColor: "rgba(238, 29, 35, 0.5)",
      },
      {
        label: "100Mn-1Bn",
        data: data2,
        backgroundColor: "rgba(255, 148, 0, 0.5)",
      },
      {
        label: "1Bn-10Bn",
        data: data3,
        backgroundColor: "rgba(0, 84, 65, 0.5)",
      },
      {
        label: "10Bn+",
        data: data4,
        backgroundColor: "rgba(139, 200, 0, 0.5)",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "Biotech Universe : Cash Runway Map",
        font: { size: 20 },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.raw.ticker}(${context.raw.company})`;
          },
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
            mode: "xy",
            speed: 0.01,
          },
          drag: {
            enabled: true,
            mode: "xy",
            speed: 0.1,
            backgroundColor: "rgba(0,0,255,0.3)",
            borderColor: "rgba(0,0,255,1)",
            borderWidth: 1,
          },
        },
        pan: {
          //Not working along with drag therefore added with ctrl button ==>
          enabled: true,
          mode: "xy",
          speed: 0.1,
          modifierKey: "ctrl",
        },
        limits: {
          x: { min: 0, max: 50 },
          y: { min: 0, max: 2000 },
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: (context) => {
            if (context.tick.value === 8) {
              return "#000";
            } else {
              return "#D3D3D3";
            }
          },
        },
        title: {
          display: true,
          text: "Quarters of Cash Runway",
          font: { size: 20 },
        },
        min: 0,
        max: 20,
      },
      y: {
        grid: {
          color: (context) => {
            if (context.tick.value === 100) {
              return "#000";
            } else {
              return "#D3D3D3";
            }
          },
        },
        title: {
          display: true,
          text: "Net Cash / Market Cap (%)",
          font: { size: 20 },
        },
        min: 0,
        max: 300,
      },
    },
    onClick: function (e, i) {
      const bubbleSelected = i[0];

      const symbol = bubbleSelected.element.$context.raw.ticker;

      history.push(`/company_info/?symbol=${symbol}`);
    },
  };

  return (
    <div style={{ height: "80vh", margin: "2% 0" }}>
      <Bubble data={data} options={options} />
    </div>
  );
};
