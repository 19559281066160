import { apiBegin, apiSuccess, apiFailure } from "../../../logic";
import apiClient from "../../../utils";

const API_CONFIG = {
  upcomingCalender: {
    method: "GET",
    url: "/api/calendar/upcomings",
  },
};



// Api to downlaod data from form

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};



/* ___________ACTIONS_______________ */

const getUpcomingCalenderData = () => ({
  type: "GET_UPCOMING_DATA",
});

const getUpcomingCalenderSuccess = (data) => ({
  type: "GET_UPCOMING_DATA_SUCCESS",
  data,
});

const getUpcomingCalenderError = (error) => ({
  type: "GET_UPCOMING_DATA_ERROR",
  error,
});

export const getUpcomingCalenderReset = () => ({
  type: "GET_UPCOMING_DATA_RESET",
});
// NEWLY  ADDED ACTION 

/* ____________REDUCER____________ */
const getUpcomingCalenderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "GET_UPCOMING_DATA":
      return {
        loading: true,
        isError: false,
        data: null,
        error: null,
        flag: false,
      };
    case "GET_UPCOMING_DATA_SUCCESS":
      return {
        loading: false,
        isError: false,
        data: action.data,
        error: null,
        flag: true,
      };
    case "GET_UPCOMING_DATA_ERROR":
      return {
        loading: false,
        isError: true,
        data: null,
        error: action.error,
        flag: false,
      };

    case "GET_UPCOMING_DATA_RESET":
      return {
        ...defaultState,
      };

    default:
      return { ...state };
  }
};





/* ____________API CALL______________ */

const startGetUpcomingCalenderApiCall =
  (queryParams = "") =>
  (dispatch) => {
    const apiConfig = { ...API_CONFIG.upcomingCalender };
    if (queryParams) {
      apiConfig.url += `?${queryParams}`;
    }
    dispatch(apiBegin());
    dispatch(getUpcomingCalenderData());
    apiClient(apiConfig)
      .then((result) => {
        dispatch(apiSuccess());
        dispatch(getUpcomingCalenderSuccess(result.data));
        console.log(result.data);
      })
      .catch((err) => {
        dispatch(apiFailure(err));
        dispatch(getUpcomingCalenderError(err));
      });
  };

export default startGetUpcomingCalenderApiCall;

export const startUpcomingSearch = (data) => ({
  type: "UPCOMING_SEARCH_UPDATE",
  data,
});

const getUrlParam = () => {
  const queryParam = new URLSearchParams(window.location.search);
  // console.log(queryParam);
  /* searchParam.delete("advanceSearch");
  searchParam.delete("source");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 10);
  
 */
  queryParam.set("page", 1);
  queryParam.set("page_size", 10);

  return queryParam.toString();
};

const StartUpcomingSearchReducer = (state, action) => {
  if (action.type === "UPCOMING_SEARCH_UPDATE") {
    return { searchParam: action.data };
  }
  return {
    searchParam: getUrlParam(),
  };
};

export const getUpcomingListReducer = {
  // countriesList: getCountriesListReducer,
  UpcomingSearchParamData: StartUpcomingSearchReducer,
};

export const upcomingReducer = {
  upcomingCalender: getUpcomingCalenderReducer,
};
