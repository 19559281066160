import React from "react";
// import {useHistory} from "react-router-dom";
// import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography, Grid, Paper, TextField, Box } from "@mui/material";
// import { Filter } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import "./index.css";

const Form = ({ checkListFilter, category }) => {
  // const history = useHistory();
  const categoryList = [
    {
      display: "All",
      value: "All",
    },
    {
      display: "Drug related",
      value: "Drug related",
    },
    {
      display: "Commercial Potential / Drug Market",
      value: "Commercial Potential / Drug Market",
    },
    { display: "Technology/ MoA", value: "Technology/ MoA" },
    {
      display: "Company Financials",
      value: "Company Financials",
    },
    {
      display: "Equity Market related",
      value: "Equity Market related",
    },
    {
      display: "Company Valuations",
      value: "Company Valuations",
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  // const handleReset = () => {
  //     checkListFilter("resetChecklist");
  // };

  const useStyles = makeStyles((theme) => ({
    container: {
      padding: "0 2%",
      margin: "2% 0",
    },
    item: {},
  }));
  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.container} spacing={1} columns={21}>
          <Grid item xs={20} md={20} lg={10} xl={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "flexEnd",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                style={{ alignSelf: "flexEnd" }}
              >
                Important parameters on which the company has been evaluated and
                scored
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={20} md={12} lg={4} xl={4}>
            <Paper elevation={0}>
              <TextField
                fullWidth
                select
                label="Category"
                name="category"
                value={category}
                onChange={checkListFilter}
              >
                {categoryList.map(({ display: name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Paper>
          </Grid>
          {/* <Grid item xs={20} md={4} lg={3} xl={2}>
               <Button
                 className="button customeButton filterbutton"
                 variant="contained"
                 type="submit"
                 startIcon={<Filter />}
               >
                  filter
               </Button>
             </Grid>
             <Grid item xs={20} md={4} lg={3} xl={2}>
               <Button
               className="button customeButton filterbutton"
               variant="contained"
               type="reset"
               size="large"
               onClick={handleReset}
               >
                 Reset
               </Button>
             </Grid> */}
        </Grid>
      </form>
    </>
  );
};
export default Form;
