import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import Styles from "./index.module.css";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const PipelineInputTable = ({ pipilineHeading, pipeLineData }) => {
  const history = useHistory();
  const renderHeadCols = () => {
    return pipilineHeading.map((row, index) => (
      <StyledTableCell align="center" key={`head_${row.label}_${index}`}>
        {row.label}
      </StyledTableCell>
    ));
  };

  const renderCells = (row) => {
    return pipilineHeading.map((col, index) => {
      if (index > 3 && index < 9) {
        return (
          <StyledTableCell align="center" key={`row_${col.key}_${index}`}>
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? `${(Number(row[col.key]) * 100).toFixed(2)}%`
              : "-"}
          </StyledTableCell>
        );
      } else {
        if (index === 1) {
          return (
            <StyledTableCell align="left" key={`row_${col.key}_${index}`}>
              {typeof row?.[col.key] === "number" ||
              typeof row?.[col.key] === "string"
                ? row[col.key]
                : "-"}
            </StyledTableCell>
          );
        } else if (index === 3) {
          return (
            <StyledTableCell
              align="center"
              key={`row_${col.key}_${index}`}
              className={Styles.InfoTag}
            >
              {typeof row?.[col.key] === "number" ||
              typeof row?.[col.key] === "string"
                ? row[col.key]
                : "-"}
            </StyledTableCell>
          );
        } else {
          return (
            <StyledTableCell
              align="center"
              key={`row_${col.key}_${index}`}
              className={Styles.InfoTag}
            >
              {typeof row?.[col.key] === "number" ||
              typeof row?.[col.key] === "string"
                ? row[col.key]
                : "-"}
            </StyledTableCell>
          );
        }
      }
    });
  };

  const rederTableRows = () => {
    if (pipeLineData.length > 0) {
      return pipeLineData.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ));
    }
  };
  return (
    <TableContainer component={Paper} elevation={12} sx={{ padding: "1.5%" }}>
      <Typography
        variant="h6"
        component="h2"
        style={{ margin: "5px 0", fontWeight: "bold" }}
      >
        Pipeline and revenue growth assumptions
      </Typography>

      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell
              className={Styles.pipelineTableHeader}
              align="center"
              colSpan={3}
              style={{ fontWeight: "bold" }}
            >
              Pipeline
              {/* <IconButton style={{ color: "black" }}>
                <InfoIcon />
              </IconButton> */}
            </TableCell>
            <TableCell
              className={`${Styles.pipelineTableHeader}`}
              align="center"
              colSpan={2}
              style={{ fontWeight: "bold" }}
            >
              Disease Market Size
              {/* <IconButton style={{ color: "black" }}>
                <InfoIcon />
              </IconButton> */}
              <span></span>
            </TableCell>
            <TableCell
              className={Styles.pipelineTableHeader}
              align="center"
              colSpan={1}
              style={{ fontWeight: "bold" }}
            >
              PoS
              <Tooltip title="Source">
                <IconButton
                  style={{ color: "black" }}
                  onClick={() => {
                    history.push("/dcfinstruction");
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell
              className={Styles.pipelineTableHeader}
              align="center"
              colSpan={4}
              style={{ fontWeight: "bold" }}
            >
              Revenue Growth Assumption
              <Tooltip title="Source">
                <IconButton
                  style={{ color: "black" }}
                  onClick={() => {
                    history.push("/revenuegrowth");
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
          <TableRow>{renderHeadCols()}</TableRow>
        </TableHead>
        <TableBody>{rederTableRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
