import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";

import { session, headerUtils } from "./utils";
import { resetFlag } from "./component/login/logic";
import { resetCommon } from "./logic";
// import {checkIfLogin} from "./utils/sessionManagement";
import ErrorBoundary from "./component/ErrorBoundries";
/* import { useHistory } from "react-router-dom";
import { refreshToken } from "./component/login/logic";
import { getData } from "./utils/storageService";
import jwtDecode from "jwt-decode";
 */
//import { useSelector } from "react-redux";
import AuthRoute from "./component/authRoute/";
import { MainSidebar } from "./component/Sidebar/Index";
import "antd/dist/antd.css";
import "./App.css";
import AnaLysedReadOuts from "./component/MainBody/AnalysedReadOuts";
import AllUpcomingReadouts from "./component/MainBody/AllUpcomingReadouts/index";
import { HistoricalReadoutsGrid } from "./component/MainBody/HistoricalReadouts/HistoricalReadoutsGrid";
import PriorResults from "./component/MainBody/AnalysedReadOuts/SubComponents/PriorResults";
import MoAResults from "./component/MainBody/AnalysedReadOuts/SubComponents/MoAResults";

// newly added
import NewsHeading from "./component/MainBody/Newsfeeds/NewsHeading";
import HomeComponent from "./component/MainBody/HomeComponent/index";

import RiskReward from "./component/MainBody/AnalysedReadOuts/SubComponents/RewardRisk";
import CheckList from "./component/MainBody/AnalysedReadOuts/SubComponents/Checklist/index";
import Pos from "./component/MainBody/AnalysedReadOuts/SubComponents/Pos/Pos";
import LandingPage from "./component/LandingPage";
//import { addSession } from "./utils/sessionManagement";
import CompanyInfo from "./component/MainBody/CompanyInfo/CompanyInfo";
import { CompanyScreener } from "./component/MainBody/CompanyScreener/index";
import { message } from "antd";
import { InstitutionHoldings } from "./component/MainBody/InstituitionHoldings/Index";
import { ScatterPlot } from "./component/MainBody/CompanyScatterPlot";
import { DCFInstructionPage } from "./component/MainBody/AnalysedReadOuts/SubComponents/RewardRisk/DCFScreenInstruction/DCFInstructionPage";
import { RevenueGrowth } from "./component/MainBody/AnalysedReadOuts/SubComponents/RewardRisk/RevenueGrowthScreen/RevenueGrowth";
import NewsDetail from "./component/MainBody/Newsfeeds/NewsDetail";
import Portfolio from "./component/Portfolio";

const history = createBrowserHistory({ basename: "/" });

const App = () => {
  const dispatch = useDispatch();
  const { isError, error } = useSelector((state) => state.apiStatus);
  // const {flag} = useSelector((state)=>state.userData);

  useEffect(() => {
    if (isError) {
      const response = error.response;
      if (response) {
        if (response.status === 401) {
          session.clearSession(["Refresh_Token", "Token", "isLoggedIn"]);

          headerUtils.discardHeader();
          dispatch(resetFlag());
          if (history.location.pathname !== "/landingPage") {
            history.push("/landingPage");
          }
          //message.error("Unauthorized");
        } else if (response.status === 504) {
          message.error("Request Timeout");
        } else if (response.status === 400) {
          message.error(response.data.field?.[0].message || "Bad Request"); // ?
        }
      } else {
        message.error("something went wrong please try again after sometime");
      }
      dispatch(resetCommon());
    }
  }, [isError, error]);

  /* const history1 = useHistory();
  // const incData = useSelector((state) => state.apiStatus);
  const accessToken = getData(`Token`) ? jwtDecode(getData(`Token`)) : null;
  const refreshTokenData = getData(`Refresh_Token`)
    ? getData(`Refresh_Token`)
    : null;
  //  console.log(incData);
  useEffect(() => {
    if (accessToken && refreshTokenData !== (null || undefined)) {
      var presentTime = new Date().getTime();
      let remainTime = accessToken?.exp * 1000 - presentTime;
      let payload = refreshTokenData;

      let interVal = setInterval(() => {
        dispatch(refreshToken(payload));
      }, remainTime);
      //console.log(remainTime);
      return () => clearInterval(interVal);
    }
  }, [accessToken, refreshTokenData, dispatch]);

  useEffect(() => {
    var presentTime = new Date().getTime();
    let checkRefreshToken = jwtDecode(refreshTokenData);
    let RefreshTime = checkRefreshToken?.exp * 1000 - presentTime;
    let timer = setTimeout(() => {
      session.clearSession(["isLoggedIn", "Token", "Refresh_Token"]);
      headerUtils.discardHeader();
      dispatch(resetFlag());
      history1.push("/landingPage");
    }, RefreshTime);
    return () => clearTimeout(timer);
  }, []);
 */
  return (
    <div className="App">
      <ErrorBoundary>
        <Router history={history}>
          <Switch>
            <Route exact path="/landingPage" component={LandingPage} />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <div className="container">
              <aside className="drawer">
                <MainSidebar history={history} />
              </aside>
              <main className="main">
                <AuthRoute exact path="/portfolio" component={Portfolio} />

                <AuthRoute exact path="/home" component={HomeComponent} />

                {/* /upcoming  */}
                <AuthRoute
                  exact
                  path="/upcomingReadouts"
                  component={AllUpcomingReadouts}
                />
                <AuthRoute
                  exact
                  path="/analyseddata"
                  component={AnaLysedReadOuts}
                />
                <AuthRoute
                  exact
                  path="/historicalreadouts"
                  component={HistoricalReadoutsGrid}
                />

                <AuthRoute
                  exact
                  path="/companyscreener"
                  component={CompanyScreener}
                />
                <AuthRoute exact path="/company_info" component={CompanyInfo} />
                <AuthRoute
                  exact
                  path="/riskreward/:hashid"
                  component={RiskReward}
                />

                <AuthRoute
                  exact
                  path="/checklists/:hashid"
                  component={CheckList}
                />
                <AuthRoute exact path="/pos/:hashid" component={Pos} />
                <AuthRoute exact path="/moa/:hashid" component={MoAResults} />
                <AuthRoute
                  exact
                  path="/priorresults/:hashid"
                  component={PriorResults}
                />
                <AuthRoute
                  exact
                  path="/institutionholdings"
                  component={InstitutionHoldings}
                />
                <AuthRoute exact path="/companysplot" component={ScatterPlot} />
                <AuthRoute
                  exact
                  path="/dcfinstruction"
                  component={DCFInstructionPage}
                />
                <AuthRoute
                  exact
                  path="/revenuegrowth"
                  component={RevenueGrowth}
                />
                <AuthRoute exact path="/newsplot" component={NewsHeading} />

                <AuthRoute
                  exact
                  path="/newsplot/details"
                  component={NewsDetail}
                />
              </main>
            </div>
          </Switch>
        </Router>
      </ErrorBoundary>
    </div>
  );
};

export default App;
