import React, { useState, useCallback, useRef, useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { CustomHeader } from "./CustomHeader";
import "./index.css";
import { Button, Box, Typography } from "@material-ui/core";
import apiClient from "../../../utils/apiClient";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../logic.js";
import { useHistory } from "react-router-dom";
import {
  addHistoricalFilteredData,
  // sideBarKey,
} from "../GlobalStateReducer/GlobalState";
import { addUpcomingFilteredData } from "../GlobalStateReducer/GlobalState";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

let API_CONFIG = {
  companyScreenerData: {
    method: "GET",
    url: "/company_screeners/",
  },
};

export const CompanyScreener = () => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ width: "100%", height: "83%", padding: "0 2%" }),
    []
  );
  const dispatch = useDispatch();
  const history = useHistory();

  // const [rowData,setRowData] = useState();
  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2).toString();
  };

  const usFormatter = (params) => {
    return Intl.NumberFormat("en-US").format(Number(params.value));
  };

  const handleCompanyNameClick = (name) => {
    history.push(`/company_info/?symbol=${name}`);
    //window.colState = gridRef.current.columnApi.getColumnState();
    // console.log("Saved State",gridRef.current.columnApi.getColumnState());
  };

  //  const saveState = useCallback(() => {
  //    const savedFilterModel = gridRef.current.api.getFilterModel();
  //     var keys = Object.keys(savedFilterModel);
  //     var savedFilters = keys.length > 0 ? keys.join(", ") : "(none)";
  //    // document.querySelector("#savedFilters").innerHTML = savedFilters;
  //     console.log(savedFilters);
  //   }, []);

  // const restoreState = useCallback(() => {
  //    // gridRef.current.api.setFilterModel(savedFilterModel);
  //     console.log("set Filter Called");
  //   }, []);

  const [companyScreenerRowData, setCompanyScreenerRowData] = useState({
    data: null,
  });
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ticker",
      field: "ticker",
      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "Not contains",
          "Equals",
          "Not equal",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellRendererFramework: function (params) {
        return (
          <a onClick={() => handleCompanyNameClick(params.value)}>
            {params.value}
          </a>
        );
      },
      cellClass: "tickerCell",
      maxWidth: 100,
    },
    {
      headerName: "Company",
      field: "company",
      minWidth: 280,
      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      headerClass: "companyHeader",
      cellClass: "companyCell",
    },
    {
      headerName: "Net debt By Equity",
      field: "Debt_by_equity",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 200,
    },
    {
      headerName: "EV by EBITDA",
      field: "EV_by_EBITDA",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 160,
    },
    {
      headerName: "EV by Sales",
      field: "EV_by_Sales",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 140,
    },
    {
      headerName: "Margin to Safety Ratio",
      field: "Margin_to_Safety_ratio",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 210,
      headerTooltip:
        " Calculated as - (Target Price - CMP)/(CMP - Cash Per Share)",
    },
    {
      headerName: "Market Capitalization (Mn)",
      field: "MarketCapitalizationMln",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 230,
    },
    {
      headerName: "Mcap by EV",
      field: "Mcap_by_EV",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 160,
    },
    {
      headerName: "Net Worth",
      field: "Networth",
      filter: "agNumberColumnFilter",
      valueFormatter: usFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 160,
    },
    //  {
    //   headerName:"PEG Ratio",
    //   field:"PEGRatio",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //    minWidth:125,
    // },
    {
      headerName: "PE Ratio",
      field: "PERatio",
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 120,
    },
    {
      headerName: "Percent Insiders",
      field: "PercentInsiders",
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 165,
    },
    {
      headerName: "Percent Instituitions",
      field: "PercentInstitutions",
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 200,
    },
    {
      headerName: "Price by Sales TTM",
      field: "PriceSalesTTM",
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 180,
    },
    //  {
    //   headerName:"Quick Ratio",
    //   field:"Quick_Ratio",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //   minWidth:140,

    // },
    {
      headerName: "RD exp by Mcap",
      field: "RDexp_by_Mcap",
      filter: "agNumberColumnFilter",
      valueFormatter: currencyFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 160,
    },
    //  {
    //   headerName:"RoA",
    //   field:"RoA",
    //   filter:"agNumberColumnFilter",
    //      valueFormatter:currencyFormatter,
    //        filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //    minWidth:100,
    // },
    //  {
    //   headerName:"RoE",
    //   field:"RoE",
    //   filter:"agNumberColumnFilter",
    //   valueFormatter:currencyFormatter,
    //     filterParams:{
    //     filterOptions:["inRange","lessThan","lessThanOrEqual","greaterThan","greaterThanOrEqual"],
    //     buttons:["clear","reset","apply"],
    //     closeOnApply:true,
    //     },
    //   minWidth:100,
    // },
    {
      headerName: "Cash runway (in Months)",
      field: "cash_runway_inMonths",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 225,
    },
    {
      headerName: "Cash by Mcap",
      field: "Cash_by_Mcap",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 175,
    },
  ]);

  const getCompanyScreenerData = () => {
    const apiPayload = { ...API_CONFIG.companyScreenerData };
    apiClient(apiPayload)
      .then((res) => {
        setCompanyScreenerRowData({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  });

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      suppressMovable: true,
      cellClass: ["suppress-movable-col"],
      resizable: false,
      headerComponentParams: {
        menuIcon: "fa-bars",
      },
      cellStyle: { fontSize: "0.8rem" },
    };
  }, []);

  const getFilteredUpcomingReadouts = () => {
    let filteredCompanyTicker = [];

    //console.log(filteredCompanyTicker);
    // dispatch(sideBarKey("9"));
    dispatch(addUpcomingFilteredData(filteredCompanyTicker));
    history.push(`/upcomingReadouts`);
  };

  const getFilteredHistoricalReadouts = () => {
    let filteredCompanyTicker = [];

    // console.log("company screener filter ticker", filteredCompanyTicker);
    dispatch(addHistoricalFilteredData(filteredCompanyTicker));
    // dispatch(sideBarKey("11"));
    history.push(`/historicalreadouts/`);
  };

  const handleResetAll = useCallback(() => {
    gridRef.current.api.setFilterModel(null); //Only for filter reset ==>
  });

  const handleResetSorting = useCallback(() => {
    gridRef.current.columnApi.resetColumnState(); //Only for sorting reset ==>
  });

  const handleCsvDownload = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  });

  const onGridReady = useCallback((params) => {
    //    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
    //    .then((res)=>res.json())
    //    .then((data)=>{
    //        console.log("From Screners page",data,rowData);
    //       setRowData(data);

    //    }).catch((err)=>{
    //        console.log(err);
    //        setColumnDefs([...columnDefs]);
    //    });

    getCompanyScreenerData();
  });

  return (
    <div style={containerStyle}>
      <Box>
        <Typography
          variant="h6"
          component="h3"
          style={{ fontWeight: "bold", textAlign: "left", padding: "1% 2%" }}
        >
          Company Screener
        </Typography>
      </Box>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 2% 1% 2%",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#076281",
            color: "#fff",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "13rem",
            minHeight: "3.5rem",
            fontWeight: "bold",
            marginRight: "1%",
          }}
          onClick={getFilteredUpcomingReadouts}
        >
          Upcoming Readouts for filtered Companies
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#076281",
            color: "#fff",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "13rem",
            minHeight: "3.5rem",
            fontWeight: "bold",
            marginRight: "1%",
          }}
          onClick={getFilteredHistoricalReadouts}
        >
          Historical Readouts for filtered Companies
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#076281",
            color: "#fff",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "13rem",
            minHeight: "3.5rem",
            fontWeight: "bold",
            marginRight: "1%",
          }}
          onClick={handleResetAll}
        >
          Reset All Filters
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#076281",
            color: "#fff",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "13rem",
            minHeight: "3.5rem",
            fontWeight: "bold",
            marginRight: "1%",
          }}
          onClick={handleResetSorting}
        >
          Reset Sorting
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#076281",
            color: "#fff",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "13rem",
            minHeight: "3.5rem",
            fontWeight: "bold",
          }}
          onClick={handleCsvDownload}
        >
          Download CSV
        </Button>
        {/* <Grid item >
             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontWeight: "bold"}} onClick={saveState}>SaveState</Button> 
            </Grid>
            <Grid item >
            <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontWeight: "bold"}} onClick={restoreState}>RestoreState</Button>
            </Grid> */}
      </div>

      <div style={gridStyle} className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={companyScreenerRowData?.data?.data}
          columnDefs={columnDefs}
          supressMenuHide={true}
          components={components}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          pagination={true}
          enableBrowserTooltips={true}
        ></AgGridReact>
      </div>
    </div>
  );
};

//  <Box sx={{width:"100%"}}>
//              <Grid container
//              columns={24}
//              spacing={1}
//         >
//             <Grid item xs={24} sm={12} md={12} lg={4} xl={4} >
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontSize:"12px",overflow: "hidden", textOverflow: "ellipsis", width: "18%"}} onClick={getFilteredUpcomingReadouts}>Upcoming Readouts for filtered Companies</Button>
//             </Grid>
//             <Grid item  xs={24} sm={12} md={12} lg={4} xl={4}>
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontSize:"12px",overflow: "hidden", textOverflow: "ellipsis", width: "18%"}} onClick={getFilteredHistoricalReadouts}>Historical Readouts for filtered Companies</Button>
//             </Grid>
//             <Grid item  xs={24} sm={8} md={8} lg={2} xl={2}>
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontSize:"12px",overflow: "hidden", textOverflow: "ellipsis", width: "18%"}} onClick={handleResetAll}>Reset All Filters</Button>
//             </Grid>
//             <Grid item  xs={24} sm={8} md={8} lg={2} xl={2}>
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontSize:"12px",overflow: "hidden", textOverflow: "ellipsis", width: "18%"}} onClick={handleResetSorting}>Reset Sorting</Button>
//             </Grid>
//             <Grid item  xs={24} sm={8} md={8} lg={2} xl={2}>
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontSize:"12px",overflow: "hidden", textOverflow: "ellipsis", width: "18%"}} onClick={handleCsvDownload}>Download CSV</Button>
//             </Grid>
//             {/* <Grid item >
//              <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontWeight: "bold"}} onClick={saveState}>SaveState</Button>
//             </Grid>
//             <Grid item >
//             <Button variant="contained" style={{backgroundColor:"#076281",color:"#fff",fontWeight: "bold"}} onClick={restoreState}>RestoreState</Button>
//             </Grid> */}
//         </Grid>

//         </Box>
