import React from "react";
import { Grid, Paper, TextField } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@mui/material/Button";
import "./index.css";

export const BarGrapghFilter = ({
  dbr,
  dar,
  setDbr,
  setDar,
  GraphDataCalculation,
  copyOfTableData,
}) => {
  const dbrList = [
    {
      display: "1",
      value: 1,
    },
    {
      display: "7",
      value: 7,
      defaultValue: 7,
    },
    {
      display: "30",
      value: 30,
    },
    {
      display: "90",
      value: 90,
    },
    {
      display: "365",
      value: 365,
    },
  ];

  const darList = [
    {
      display: "0",
      value: 0,
    },
    {
      display: "2",
      value: 2,
      defaultValue: 2,
    },
    {
      display: "7",
      value: 7,
    },
  ];

  const handleDbrChange = (e) => {
    setDbr(e.target.value);
  };

  const handleDarChange = (e) => {
    setDar(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    GraphDataCalculation(copyOfTableData, dbr, dar);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} columns={22}>
          <Grid item xs={20} md={20} lg={6} xl={6}>
            <Paper elevation={0}>
              <TextField
                fullWidth
                select
                label="Days Before Readout"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dbr}
                onChange={handleDbrChange}
                selected={7}
              >
                {dbrList.map(({ display: name, value, defaultValue }) => (
                  <MenuItem
                    key={name}
                    value={value}
                    defaultValue={defaultValue}
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Paper>
          </Grid>
          <Grid item xs={20} md={20} lg={4} xl={4}>
            <TextField
              fullWidth
              disabled
              label="Readout Day"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="   0"
            ></TextField>
          </Grid>
          <Grid item xs={20} md={20} lg={6} xl={6}>
            <Paper elevation={0}>
              <TextField
                fullWidth
                select
                label="Days After Readout"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dar}
                onChange={handleDarChange}
                selected={2}
              >
                {darList.map(({ display: name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Paper>
          </Grid>
          <Grid item xs={20} md={20} lg={6} xl={6}>
            <Button
              className="customButton filterbutton"
              variant="contained"
              type="submit"
              size="large"
              style={{ backgroundColor: "#076281", fontWeight: "bold" }}
            >
              Show
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
