import { Button } from "@material-ui/core";
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./news.scss";
import { Paper } from "@mui/material";

const NewsDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const { pageNumber } = location.state;
  const ticker = location.state?.ticker || "fierce_news";

  const goBack = () => {
    if (pageNumber) {
      history.push(`/newsplot?page=${pageNumber}&ticker=${ticker}`);
    } else {
      history.push(`/newsplot?ticker=${ticker}`);
    }
  };

  return (
    // <div>
    //   <div>
    //     <Button
    //       style={{
    //         backgroundColor: "#fff",
    //         color: "#000",
    //         boxShadow: "1px 1px #D3D3D3",
    //         padding: "10px 20px",
    //         borderRadius: "5px",
    //       }}
    //       onClick={() => {
    //         window.history.go(-1);
    //       }}
    //     >
    //       <ArrowBackIcon fontSize="small" />
    //       Back
    //     </Button>
    //   </div>
    //   <div>{location.search}</div>
    // </div>
    <div className="divmain1">
      <div className="titleNews">
        <div className="backdiv">
          <Paper elevation={1}></Paper>
          <Button
            style={{
              backgroundColor: "#207080",

              // color: "#000",
              color: "white",
              boxShadow: "1px 1px #D3D3D3",
              //change it into %
              // padding: "1% 2%",
              borderRadius: "0.5%",
            }}
            onClick={() => {
              // window.history.go(-1);
              goBack();
            }}
            // onClick={(e) => history.goBack()}
          >
            <ArrowBackIcon fontSize="small" />
            <b>Back</b>
          </Button>
        </div>
        <Paper>
          <div className="title_news">
            <i>{location.state.title}</i>
          </div>
        </Paper>
      </div>

      <div className="newsPara">
        {/* <Paper className="detailstime" style={{ justifyContent: "center" }}> */}
        {location.state.date != "Invalid date" ? location?.state?.date : ""}
        {/* </Paper>  */}

        <Paper className="detailsPaper">{location.state.news}</Paper>
      </div>
    </div>
  );
};

export default NewsDetail;
