import { getData, setData, removeItem } from "./storageService";

export const clearSession = (removeList) => {
  if (removeList) {
    removeList.forEach((key) => {
      removeItem(key);
    });
  }
};
//let InitialRefreshToken = getData("Refresh_Token") ;

export const addSession = (session) => {
  setData("isLoggedIn", true);
  setData(`Token`, session.access);
  setData(`Refresh_Token`, session.refresh);
  /* if (InitialRefreshToken === null || InitialRefreshToken === session.refresh) {
    setData(`Refresh_Token`, session.refresh);
  } */

  //  setData("userFirstName", session.first_name);
  // setData("userLastName", session.last_name);
  // setData(`Token`, `Basic ${session.token}`);
};
export const addSessionForRefresh = (session) => {
  setData("isLoggedIn", true);
  setData(`Token`, session.access);
};

export const checkIfLogin = () => getData("isLoggedIn") || false;

export const getAuthHeader = () => getData(`Token`);
