import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "./Form";
import CustomizedTables from "./CustomizedTables";
import "./index.css";
import apiClient from "../../../utils";
import { startUpcomingSearch } from "./logic";
import { Pagination } from "@mui/material";
//import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { apiFailure } from "../../../logic.js";
import { userStatus } from "../GlobalStateReducer/GlobalState";
import { useRef } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";

//import { useState } from "react";
// import apiCall from "../AllUpcomingReadouts/logic";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const COL_LIST = [
  {
    key: "ticker",
    label: "Ticker",
    tableCellClassName: "my-class",
  },
  { key: "note", label: "Catalyst Info" },
  { key: "drug_name", label: "Drugs" },
  { key: "indication", label: "Indications" },
  {
    key: "label",
    label: "Stage",
    // customCell: (row, index) => <div className="test-div">{row.fat}</div>,
  },
  { key: "mcap", label: "Market Cap (Mn)" },
  { key: "cmp", label: "CMP" },
  { key: "catalyst_date", label: "Expected date" },
  { key: "days_from_today", label: "Days left" },
  { key: "analysed1", label: "Analysed", id: "pk_id" },
];

let API_CONFIG = {
  upcomingCalender: {
    method: "GET",
    url: "/calendar/upcomings/",
    data: {},
  },
  upcomingCalenderDownload: {
    method: "GET",
    url: "/calendar/upcomingsDownload/",
    data: {},
  },
  userStatus: {
    method: "GET",
    url: "/userstatus/",
  },
};

const PageSize = 10;

var tableData = "";

const AllUpcomingReadouts = () => {
  const gridRef = useRef();
  const [isAdmin, setIsAdmin] = useState({ status: null });
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParam = useSelector(
    (state) => state.UpcomingSearchParamData.searchParam
  );
  const upcomingFilteredData = useSelector(
    (state) => state.globalState.upComingFilteredData
  );

  const [pageNumber, setPageNumber] = React.useState(1);
  const [initial, setInitial] = useState(1); //For Initial Render

  //const [search, setSearch] = useState(null);
  const [upcoming, setUpComing] = React.useState({ data: null });
  const onSearch = (queryParam) => {
    history.replace(`${history.location.pathname}?${queryParam.toString()}`);
    dispatch(startUpcomingSearch(queryParam.toString()));
    // setSearch(queryParam);
    // console.log({ query: queryParam.toString() });
  };

  // tableData = (upcoming.data);

  const apiCall = (queryData) => {
    let apiPayload;
    if (upcomingFilteredData && initial === 1) {
      apiPayload = { ...API_CONFIG.upcomingCalender };
      apiPayload.url += `filter`;
      apiPayload.method = "POST";
      apiPayload.data = upcomingFilteredData;
      setInitial(2);
    } else {
      apiPayload = { ...API_CONFIG.upcomingCalender };
      apiPayload.url += `?${queryData}`;
      // setLoading(true);
    }

    apiClient(apiPayload)
      .then((res) => {
        setUpComing({ data: res });
      })
      .catch((err) => {
        // if(err.response.status==401){
        //   history.push("/landingPage");
        // }
        console.log({ "error from all upcoming": err });
        dispatch(apiFailure(err));
      });
  };

  const handleChangePage = (_event, pageNo) => {
    const queryParam = new URLSearchParams(history.location.search);
    queryParam.set("page", pageNo);
    queryParam.set("page_size", PageSize);
    apiCall(queryParam.toString());
    setPageNumber(pageNo);
  };
  const renderPages = () => {
    let totalItems = upcoming?.data?.data?.count;
    tableData = upcoming.data;
    if (totalItems > 0) {
      let pages = Math.ceil(totalItems / PageSize);
      return pages;
    }
  };

  useEffect(() => {
    if (searchParam) {
      setPageNumber(1);
    }
    const searchParamQuery = new URLSearchParams(searchParam);
    apiCall(searchParamQuery.toString());
  }, [searchParam]);

  useEffect(() => {
    getUserStatus();
  }, []);

  const getUserStatus = () => {
    const apiPayload = { ...API_CONFIG.userStatus };
    apiClient(apiPayload)
      .then((res) => {
        if (res.data.is_staff) {
          setIsAdmin({ status: "YES" });
          dispatch(userStatus(res.data.is_staff));
        } else {
          setIsAdmin({ status: "NO" });
          dispatch(userStatus(res.data.is_staff));
        }
      })
      .catch((err) => console.log({ message: err }));
  };

  const onCsvDownload = () => {
    const searchParamQuery = new URLSearchParams(searchParam);

    if (searchParamQuery.has("page")) {
      searchParamQuery.delete("page");
    }
    if (searchParamQuery.has("page_size")) {
      searchParamQuery.delete("page_size");
    }
    apiCallForCsvDownload(searchParamQuery.toString())
      .then((response) => {
        // Download the CSV file
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "UpcomingReadouts.csv";
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("CSV download error:", error);
      });
  };

  const apiCallForCsvDownload = (queryData) => {
    const apiPayload = { ...API_CONFIG.upcomingCalenderDownload };
    apiPayload.url += `?${queryData}`;
    return apiClient(apiPayload);
    // .then((res) => {
    //   console.log(res);
    //   exportToCsv(res.data.results);
    // })
    // .catch((err) => {
    //   dispatch(apiFailure(err));
    // });
  };

  // const downloadFile = ({ data, fileName, fileType }) => {
  //   // Create a blob with the data we want to download as a file
  //   const blob = new Blob([data], { type: fileType });
  //   // Create an anchor element and dispatch a click event on it
  //   // to trigger a download
  //   const a = document.createElement("a");
  //   a.download = fileName;
  //   a.href = window.URL.createObjectURL(blob);
  //   const clickEvt = new MouseEvent("click", {
  //     view: window,
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   a.dispatchEvent(clickEvt);
  //   a.remove();
  // };
  // const exportToCsv = (apiData) => {
  //   // Headers for each column
  //   let headers = [
  //     "Ticker, Catalyst Info, Drug, Indications, Stage, Market cap (Mn), CMP, Expected date, Days left, Analysed",
  //   ];

  //   // Convert users data to a csv
  //   let usersCsv = apiData.reduce((acc, user) => {
  //     const {
  //       ticker,
  //       note,
  //       drug_name,
  //       indication,
  //       label,
  //       mcap,
  //       cmp,
  //       catalyst_date,
  //       days_from_today,
  //       analysed1,
  //     } = user;
  //     acc.push(
  //       [
  //         `"${ticker}"`,
  //         `"${note}"`,
  //         `"${drug_name}"`,
  //         `"${indication}"`,
  //         `"${label}"`,
  //         `"${mcap}"`,
  //         `"${cmp}"`,
  //         `"${catalyst_date}"`,
  //         `"${days_from_today}"`,
  //         `"${analysed1}"`,
  //       ].join(",")
  //     );
  //     return acc;
  //   }, []);

  //   downloadFile({
  //     data: [...headers, ...usersCsv].join("\n"),
  //     fileName: "UpcomingReadouts.csv",
  //     fileType: "text/csv",
  //   });
  // };

  return (
    <>
      <div>
        <h2
          style={{
            fontWeight: "bold",
            textAlign: "left",
            padding: "2% 0 0 2%",
          }}
        >
          All Upcoming Readouts
        </h2>
        <p className="sub_heading">
          Details of all upcoming readouts. Attractive Readouts are picked from
          this list for further Analysis.
        </p>
      </div>

      <Form onSearch={onSearch} onCsvDownload={onCsvDownload} />
      {upcoming.data && isAdmin.status ? (
        <div className="table_container">
          <CustomizedTables
            ref={gridRef}
            columnList={COL_LIST}
            className="upcoming-table"
            //  data={data?.results}
            data={
              upcoming.data?.data?.results ? upcoming.data.data.results : []
            }
            count={upcoming.data?.data ? upcoming.data.data : ""}
            isAdmin={isAdmin.status}
          />
        </div>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {upcoming.data?.data?.count > 0 && isAdmin.status && (
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          variant="outlined"
          shape="rounded"
          color="primary"
          //page={pageNumber}
          count={renderPages()}
          page={pageNumber}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default AllUpcomingReadouts;
export { tableData };
