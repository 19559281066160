import React from "react";
// import "./index.css";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@mui/material";

export const TopHeader = ({ headData }) => {
  return (
    <div style={{ padding: "1.5%" }}>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: "100%",
              height: 120,
              backgroundColor: "#002E64",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" component="h5" style={{ color: "white" }}>
              {headData?.disease ? headData.disease : "-"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} md={4}>
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#05C00D",
                borderRadius: "5px",
                marginBottom: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="body1"
                style={{ color: "white" }}
              >
                +ve data
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 57,
                backgroundColor: "#E25252",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="body1"
                style={{ color: "white" }}
              >
                -ve data
              </Typography>
            </Box>
          </div>
        </Grid>

        <Grid item xs={6} md={4}>
          <div className="">
            <Box
              sx={{
                width: "100%",
                height: 57,
                border: "2px solid #05C00D",
                borderRadius: "5px",
                marginBottom: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="body1"
                style={{ color: "#05C00D" }}
              >
                {headData?.reward ? headData.reward.toFixed(2) : "-"}%
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 57,
                border: "2px solid #E25252",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="body1"
                style={{ color: "#E25252" }}
              >
                {headData?.risk ? headData.risk.toFixed(2) : "-"}%
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
