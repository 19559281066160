import React, { useState, useEffect } from "react";
import apiClient from "../../../utils/apiClient";
import { BubblePlot } from "./BubblePlot";
import { useHistory } from "react-router-dom";
import MySelect from "./MySelect.js";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
// import Paper from "@mui/material/Paper";
import "./index.scss";

let API_CONFIG = {
  cashMcapChart: {
    method: "GET",
    url: "/cashmcapchart/",
  },
  uniqueTA: {
    method: "GET",
    url: "/uniqueTA/",
  },
};

export const ScatterPlot = () => {
  const history = useHistory();
  const [, /* plotData */ setPlotData] = useState({ data: null });
  const [selected, setSelected] = useState([]);
  const [option, setOption] = useState([]);
  const [dummySelect, setDummySelected] = useState(null);
  const [value1, setValue1] = useState("All");
  const [dataSet, setDataSet] = useState({
    data1: null,
    data2: null,
    data3: null,
    data4: null,
  });
  const searchParam = new URLSearchParams(history.location.search);

  const getData = async () => {
    const payload = { ...API_CONFIG.uniqueTA };
    apiClient(payload)
      .then((res) => {
        console.log(res);
        // let obj = {};
        const arr = res.data.map((item) => {
          return { label: item.uniqueTA, value: item.uniqueTA };
        });
        setOption(arr);
      })
      .catch((err) => console.log(err));
  };

  const filterUpcomingReadoutsCompany = (event) => {
    setValue1("Upcoming");
    searchParam.set("upcomings", "yes");
    history.replace(`?${searchParam.toString()}`);
    apiCall(searchParam);
  };

  const ResetUpcomingReadoutsCompany = () => {
    setValue1("All");
    if (value1 == "All") {
      searchParam.delete("analyticsSearch");
      searchParam.delete("upcomings");
      history.replace("/companysplot");
      apiCall();
      setDummySelected(null);
    } else {
      searchParam.delete("analyticsSearch");
      searchParam.set("upcomings", "yes");
      history.replace(`?${searchParam.toString()}`);
      apiCall(searchParam);
      setDummySelected(null);
    }
  };

  const getPlotData = (data) => {
    let _0to100Mn = data?.filter(
      (ele) => ele?.Market_cap_category === "0-100Mn"
    );
    let _100Mnto1Bn = data?.filter(
      (ele) => ele?.Market_cap_category === "100Mn-1Bn"
    );
    let _1Bnto100Bn = data?.filter(
      (ele) => ele?.Market_cap_category === "1Bn-10Bn"
    );
    let _10BnPlus = data?.filter(
      (ele) => ele?.Market_cap_category === "10Bn +"
    );

    setDataSet({
      data1: _0to100Mn,
      data2: _100Mnto1Bn,
      data3: _1Bnto100Bn,
      data4: _10BnPlus,
    });
  };

  useEffect(() => {
    apiCall();
    getData();
  }, []);

  useEffect(() => {
    console.log("select", selected);
  }, [selected]);

  const apiCall = (paramArr) => {
    const apiPayload = { ...API_CONFIG.cashMcapChart };
    if (paramArr) {
      apiPayload.url += `?${paramArr}`;
    }
    apiClient(apiPayload)
      .then((res) => {
        setPlotData(res?.data);
        getPlotData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDummyChange = (optionSelected, optionValues) => {
    const TA = [];

    setDummySelected(optionSelected);
    if (!optionValues) {
      optionValues = optionSelected;
    }
    optionValues.map((item) => {
      if (item.value) {
        TA.push(item.value.trim());
      }
    });

    setSelected(TA);

    if (searchParam.has("analyticsSearch")) {
      searchParam.delete("analyticsSeach");
      searchParam.set("analyticsSearch", TA.join(","));
    } else {
      searchParam.set("analyticsSearch", TA);
    }

    history.replace(`?${searchParam.toString()}`);
    apiCall(searchParam.toString());
  };

  const Option = (props) => {
    return (
      <div style={{ textAlign: "left" }}>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            Companies
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();

  return (
    <>
      <div className="outerDiv">
        <div className="innerdiv">
          <h1 className="heading">Analytics</h1>
        </div>
        {/* justifyContent: "right" + width 80%   1 */}
        <div className="selectdiv">
          <div className="selectdiv2">
            <MySelect
              className="select"
              options={option}
              isMulti
              closeMenuOnSelect={false}
              clearableValue={false}
              hideSelectedOptions={true}
              components={{
                Option,
                MultiValue,
                animatedComponents,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                clearableValue: "false",
              }}
              onChange={handleDummyChange}
              // allowSelectAll={true}
              value={dummySelect}
              placeholder={
                <p className="para">
                  Filter Therapeutic Areas For Upcoming Readouts
                </p>
              }
            />

            <Button
              className="x_btn"
              variant="outlined"
              //variant="contained"
              onClick={ResetUpcomingReadoutsCompany}
            >
              X
            </Button>
          </div>
          <div className="textarea">
            {/* removed  elevation={0} sx={{ width: "100%", minWidth: "400px" }} */}
            {/* <Paper  className="paperEle" > */}
            <TextField
              className="dropdiv"
              select
              label="Companies"
              name="company"
              defaultValue={value1}
              variant="outlined"

              // value={m}
              //onChange={handleMarketCapChange}
            >
              <MenuItem value="All" onClick={ResetUpcomingReadoutsCompany}>
                {" "}
                All Companies{" "}
              </MenuItem>
              <MenuItem
                value="Upcoming"
                onClick={filterUpcomingReadoutsCompany}
              >
                {" "}
                Companies with Upcoming Readouts
              </MenuItem>
            </TextField>
            {/* </Paper> */}
          </div>
        </div>
        {/* </Box> */}
      </div>

      <div className="bubblePlot">
        {dataSet.data4 && <BubblePlot dataSet={dataSet} />}
      </div>
    </>
  );
};
