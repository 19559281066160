import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
// import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Grid from "@mui/material/Grid";

import { makeStyles } from "@material-ui/core";

import { Filter } from "@material-ui/icons";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { tableData } from ".";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import apiClient from "../../../utils";
import { dispatch } from "d3";
import { apiFailure } from "../../../logic";
import DownloadIcon from "@mui/icons-material/Download";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

let API_CONFIG = {
  historicalReadoutsData: {
    method: "GET",
    url: "/calendar/historical/",
    data: {},
  },
  changeSentiment: {
    method: "PUT",
    url: "/calendar/updatesentiment",
    data: {},
  },
  userStatus: {
    method: "GET",
    url: "/userstatus/",
  },
};

const defaultValues = {
  search: "",
  marketCap: "",
  Stage: "",
  analyzed: "",
};
const Form = ({ onSearch: propOnSearch, onCsvDownload }) => {
  const history = useHistory();
  const [formValues, setFormValues] = useState(defaultValues);
  const [marketCap, setMarketCap] = useState("");
  const [stage, setStage] = useState([]);
  const [analyzed, setAnalyzed] = useState("");
  const [isAdmin, setIsAdmin] = useState({ status: null });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const Market = [
    {
      display: "Mega Cap - 200Bln and More",
      value: "200000-2000000",
    },
    {
      display: "Large Cap - 10Bln to 200 Bln",
      value: "10000-200000",
    },
    {
      display: "Mid Cap - 1Bln to 10 Bln",
      value: "1000-10000",
    },
    {
      display: "Small Cap - 100 Mln to 1 Bln",
      value: "100-1000",
    },
    {
      display: "Micro Cap - Less than 100 Mln",
      value: "0-100",
    },
  ];

  const Stage = [
    "Phase 1",
    "Phase 1/2",
    "Phase 2",
    "Phase 2/3",
    "Phase 3",
    "Phase 4",
    "PDUFA",
  ];

  const getUserStats = () => {
    const apiPayload = { ...API_CONFIG.userStatus };
    apiClient(apiPayload)
      .then((res) => {
        if (res.data.is_staff) {
          // setIsAdmin({status:"YES"});
          // isAdmin = { status: "YES" };
          setIsAdmin({ status: "YES" });
        } else {
          // setIsAdmin({status:"NO"});
          setIsAdmin({ status: "NO" });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(apiFailure(err));
      });
  };

  var tableData1 = JSON.parse(JSON.stringify(tableData))?.data?.results;

  // tableData1 = JSON.stringify(tableData1);
  console.log(tableData1);

  useEffect(() => {
    getUserStats();
  }, []);

  //Download CSV
  /*  const onBtnCsvExport = useCallback(() => {
  gridRef.current.api.exportDataAsCsv();
}, []); */

  const handleMarketCapChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setMarketCap(typeof value === "string" ? value.split(",") : value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChangeStage = (event) => {
    const {
      target: { name, value },
    } = event;
    setStage(typeof value === "string" ? value.split(",") : value);

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAnalyzedChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setAnalyzed(value);

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { search, marketCap, Stage, analyzed } = formValues;

    const searchParam = new URLSearchParams(history.location.search);
    if (search !== "") {
      searchParam.set("search", search);
    } else {
      searchParam.delete("search");
    }
    if (marketCap !== "") {
      let marketData = marketCap.split("-");
      searchParam.set("marketCapStart", `${marketData[0]}`);
      searchParam.set("marketCapEnd", `${marketData[1]}`);
    } else {
      searchParam.delete("marketCapStart");
      searchParam.delete("marketCapEnd");
    }
    if (Stage.length > 0) {
      const stageData = Stage.join(",");
      searchParam.set("stage", `${stageData}`);
    } else {
      searchParam.delete("stage");
    }
    if (analyzed !== "") {
      searchParam.set("analyzed", `${analyzed}`);
    } else {
      searchParam.delete("analyzed");
    }
    searchParam.set("page", 1);
    searchParam.set("page_size", 10);

    propOnSearch(searchParam);
  };

  const handleReset = () => {
    history.push("/upcomingReadouts");
    const searchParam = new URLSearchParams(history.location.search);
    searchParam.set("page", 1);
    searchParam.set("page_size", 10);
    propOnSearch(searchParam);
    console.log(searchParam, "on reset search params");
    setMarketCap([]);
    setStage([]);
    setAnalyzed("");
    setFormValues(defaultValues);
    window.location.reload();
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      padding: "0 2%",
    },
    item: {},
  }));
  const classes = useStyles();

  const onCsvGenerate = () => {
    onCsvDownload();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        className={classes.container}
        gap={1}
        columns={22}
        // justifyContent="center"
      >
        {" "}
        {/*above grid columns changed from 21  */}
        {/* <Grid item xs={20} lg={5} xl={7} md={14} className={classes.item}>   */}
        <Grid item xs={21} md={6} lg={3} xl={3} className={classes.item}>
          <Paper elevation={0}>
            <TextField
              fullWidth
              label="Search Ticker,Drug or Disease"
              value={null}
              name="search"
              onChange={handleInputChange}
            ></TextField>
          </Paper>
        </Grid>
        <Grid item xs={21} md={6} lg={3} xl={3} className={classes.item}>
          <Paper elevation={0}>
            <TextField
              fullWidth
              select
              label="MarketCap ($)"
              name="marketCap"
              value={marketCap}
              onChange={handleMarketCapChange}
            >
              {Market.map(({ display: name, value }) => (
                <MenuItem key={name} value={value}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={21} md={8} lg={3} xl={3} className={classes.item}>
          <Paper elevation={0}>
            <TextField
              fullWidth
              select
              label="Stage"
              name="Stage"
              value={stage}
              onChange={handleChangeStage}
              SelectProps={{
                multiple: true,
              }}
            >
              {Stage.map((name1) => (
                <MenuItem key={name1} value={name1}>
                  {name1}
                </MenuItem>
              ))}
              ;
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={21} md={5} lg={3} xl={3} className={classes.item}>
          <Paper elevation={0} sx={{ width: "100%", minWidth: "100px" }}>
            <TextField
              select
              label="Analyzed"
              sx={{ width: "100%", minWidth: "100px" }}
              value={analyzed}
              name="analyzed"
              onChange={handleAnalyzedChange}
            >
              <MenuItem value="TRUE">Yes</MenuItem>
              <MenuItem value="to be analysed">No</MenuItem>
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={21} md={5} lg={4.5} xl={5} className={classes.item}>
          <Button
            className="button customeButton filterbutton"
            variant="contained"
            type="submit"
            size="large"
            startIcon={<Filter />}
          >
            Apply Filters
          </Button>
        </Grid>
        <Grid item xs={21} md={5} lg={2.5} xl={3} className={classes.item}>
          <Button
            className="button customeButton filterbutton"
            variant="contained"
            type="reset"
            size="large"
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
        {isAdmin?.status === "YES" && (
          <Grid item xs={21} md={4} lg={0.5} xl={1} className={classes.item}>
            <Button
              className="button customeButton filterbutton"
              variant="contained"
              type="submit"
              size="large"
              onClick={onCsvGenerate}
            >
              <DownloadIcon></DownloadIcon>
              {/* Download */}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Form;
