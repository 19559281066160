import React, { useRef, useState, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../logic.js";
import apiClient from "../../../utils/apiClient";
// import { FundTable } from "./FundTable.jsx";
import {
  addHistoricalFilteredData,
  addUpcomingFilteredData,
  // sideBarKey,
} from "../GlobalStateReducer/GlobalState.js";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CustomHeader } from "./CustomHeader";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

let API_CONFIG = {
  fundingCompanyList: {
    method: "GET",
    url: "/fundholdings/",
  },
  allFundList: {
    method: "GET",
    url: "/allfundslist/",
  },
  topFundList: {
    method: "GET",
    url: "/topfundslist/",
  },
};

// const FUND_COL_LIST = [
//   {
//     key:"ticker",label:"Ticker",
//   },
//   {
//     key:"company",label: "Company Name"
//   },
//   {
//     key:"currentShares",label:"Number of Shares"
//   },
//   {
//     key:"Value_of_holding_mln",label:"Value of holding ($Mn)"
//   },
//   {
//     key:"totalShares",label:"Ownership(%)"
//   },
//   {
//     key:"date",label:"Date of reporting"
//   },
//   {
//     key:"change",label:"Change in no. of shares from last reporting"
//   },
//   {
//     key:"change_p",label: "Change in %"
//   },
// ];

export const InstitutionHoldings = () => {
  const inputRef = useRef("");
  const [companyListSuggest, setCompanyListSuggest] = useState([]);
  const [fundingCompanyList, setFundingCompanyList] = useState({ data: null });
  const [nameAfterSuggestion, setNameAfterSuggestion] = useState(
    "Perceptive Advisors LLC"
  );
  const [topFundList, setTopFundList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  //Grid Varaibles ==>
  const gridRef = useRef();

  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2).toString();
  };

  const usFormatter = (params) => {
    return Intl.NumberFormat("en-US").format(Number(params.value));
  };

  const handleCompanyNameClick = (name) => {
    history.push(`/company_info/?symbol=${name}`);
    //window.colState = gridRef.current.columnApi.getColumnState();
    // console.log("Saved State",gridRef.current.columnApi.getColumnState());
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ticker",
      field: "ticker",
      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "Not contains",
          "Equals",
          "Not equal",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellRendererFramework: function (params) {
        return (
          <a onClick={() => handleCompanyNameClick(params.value)}>
            {params.value}
          </a>
        );
      },
      cellClass: "tickerCell",
      maxWidth: 100,
    },
    {
      headerName: "Company Name",
      field: "company",
      minWidth: 280,
      filter: "agTextColumnFilter",
      pinned: "left",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      headerClass: "companyHeader",
      cellClass: "companyCell",
    },
    {
      headerName: "Number of Shares",
      field: "currentShares",
      filter: "agNumberColumnFilter",
      valueFormatter: usFormatter,
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 190,
    },
    {
      headerName: "Value of holding ($Mn)",
      field: "Value_of_holding_mln",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 220,
    },
    {
      headerName: "Ownership (%)",
      field: "totalShares",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 170,
    },
    {
      headerName: "Date of reporting",
      field: "date",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "Not contains",
          "Equals",
          "Not equal",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      minWidth: 180,
    },
    {
      headerName: "Change in no. of shares from last reporting",
      field: "change",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: usFormatter,
      minWidth: 350,
    },
    {
      headerName: "Change in %",
      field: "change_p",
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
      minWidth: 170,
    },
  ]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    };
  });

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      sortable: true,
      suppressMovable: true,
      cellClass: ["suppress-movable-col"],
      resizable: false,
      headerComponentParams: {
        menuIcon: "fa-bars",
      },
      cellStyle: { fontSize: "0.8rem" },
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    container: {
      padding: "0 2%",
    },
    item: {},
  }));
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    getTableData(inputRef.current.value.trimStart());
    setNameAfterSuggestion(inputRef.current.value);
  };

  const handleFocus = () => {
    //  setSuggestedFunds(true);
    inputRef.current.value = "";
    setCompanyListSuggest([]);
  };

  //  const handleBlur =() => {
  //     setSuggestedFunds(false);
  //  };

  const handleSuggestionClick = (name) => {
    inputRef.current.value = name;
    setCompanyListSuggest([]);
  };

  const handleInputChange = () => {
    if (!inputRef.current.value.trim()) {
      setCompanyListSuggest([]);
      return;
    }
    const apiPayload = { ...API_CONFIG.allFundList };
    apiPayload.url += `?search=${inputRef.current.value}`;
    apiClient(apiPayload)
      .then((res) => {
        setCompanyListSuggest(res.data);
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };

  const getTableData = (name) => {
    if (name === "") {
      defaultTableData();
    } else {
      const apiPayload = { ...API_CONFIG.fundingCompanyList };
      apiPayload.url += `?fund=${name}`;
      apiClient(apiPayload)
        .then((res) => {
          setFundingCompanyList({ data: res });
        })
        .catch((err) => {
          console.log({ message: err });
          dispatch(apiFailure(err));
        });
    }
  };

  const defaultTableData = () => {
    const apiPayload = { ...API_CONFIG.fundingCompanyList };
    apiPayload.url += `?fund=Perceptive Advisors LLC`;
    apiClient(apiPayload)
      .then((res) => {
        setFundingCompanyList({ data: res });
        setNameAfterSuggestion("Perceptive Advisors LLC");

        // console.log(res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const topCompayData = () => {
    const apiPayload1 = { ...API_CONFIG.topFundList };
    apiClient(apiPayload1)
      .then((res) => {
        setTopFundList(res.data);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    defaultTableData();
    topCompayData();
  }, []);

  const handleUpComingReadouts = () => {
    const companyList = fundingCompanyList.data.data.map((ele) => ele.ticker);
    // dispatch(sideBarKey("10"));
    dispatch(addUpcomingFilteredData(companyList));
    history.push("/upcomingReadouts");
  };

  const handleHistoricalReadouts = () => {
    const companyList = fundingCompanyList.data.data.map((ele) => ele.ticker);

    // dispatch(sideBarKey("11"));
    dispatch(addHistoricalFilteredData(companyList));
    history.push(`/historicalreadouts/`);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        style={{
          fontWeight: "bold",
          textAlign: "left",
          paddingLeft: "2%",
          margin: "2% 0",
        }}
      >
        Institutional Holdings
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container className={classes.container} spacing={3} columns={20}>
          <Grid item xs={10} md={10} lg={10} xl={10} className={classes.item}>
            <Paper elevation={0}>
              <TextField
                fullWidth
                label="Search for fund name"
                inputRef={inputRef}
                name="search"
                autoComplete="off"
                onChange={handleInputChange}
                onFocus={handleFocus}
              ></TextField>
              <div style={{ position: "relative" }}>
                {inputRef.current.value && companyListSuggest[0] && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0",
                      transform: "translate(0,100%)",
                      zIndex: "100",
                      backgroundColor: "#FFF",
                      width: "100%",
                      borderBottom: "1px solid #808080",
                    }}
                  >
                    <>
                      <div
                        style={{
                          paddingLeft: "2%",
                          height: "auto",
                          maxHeight: "150px",
                          overflowY: "scroll",
                        }}
                      >
                        {companyListSuggest.map((ele) => {
                          return (
                            <Typography
                              gutterBottom
                              varaint="button"
                              key={ele.id}
                              onClick={() => {
                                handleSuggestionClick(ele.funds);
                              }}
                              style={{ cursor: "pointer", textAlign: "left" }}
                            >
                              {ele.funds}
                            </Typography>
                          );
                        })}
                      </div>

                      <div
                        style={{
                          paddingLeft: "2%",
                          height: "auto",
                          maxHeight: "300px",
                          overflowY: "scroll",
                          borderTop: "1px solid #808080",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="h2"
                          style={{ fontStyle: "italic", textAlign: "left" }}
                        >
                          Suggested (Top Funds on the basis of no. of biotech
                          holdings)
                        </Typography>
                        {topFundList[0] &&
                          topFundList.map((ele) => {
                            return (
                              <Typography
                                gutterBottom
                                varaint="button"
                                key={ele.id}
                                onClick={() => {
                                  handleSuggestionClick(ele.topfunds);
                                }}
                                style={{ cursor: "pointer", textAlign: "left" }}
                              >
                                {ele.topfunds}
                              </Typography>
                            );
                          })}
                      </div>
                    </>
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={10} lg={3} xl={3} md={3} className={classes.item}>
            <Button
              className="customeButton filterbutton"
              variant="contained"
              type="submit"
              size="large"
              style={{
                background: "#076281",
                fontWeight: "bold",
                color: "#FFFFFF",
              }}
            >
              Show Funds Holdings
            </Button>
          </Grid>
          <Grid item xs={10} lg={3} xl={3} md={3} className={classes.item}>
            <Button
              className="customeButton filterbutton"
              variant="contained"
              type="submit"
              size="large"
              onClick={handleUpComingReadouts}
              style={{
                background: "#076281",
                fontWeight: "bold",
                color: "#FFFFFF",
              }}
            >
              Upcoming Readouts
            </Button>
          </Grid>
          <Grid item xs={10} lg={3} xl={3} md={3} className={classes.item}>
            <Button
              className="customeButton filterbutton"
              variant="contained"
              type="submit"
              size="large"
              onClick={handleHistoricalReadouts}
              style={{
                background: "#076281",
                fontWeight: "bold",
                color: "#FFFFFF",
                fontSize: "1rem",
              }}
            >
              Historical Readouts
            </Button>
          </Grid>
        </Grid>
      </form>
      <div style={{ padding: "0 2%" }}>
        <Paper elevation={3} sx={{ padding: "0 2%", marginTop: "2%" }}>
          {/* {(fundingCompanyList.data) &&
        <FundTable columnList={FUND_COL_LIST}
         data={fundingCompanyList?.data?.data ? 
         fundingCompanyList.data.data 
         : ["No Funds Data Found"]} 
         companyName={nameAfterSuggestion} />} */}
          <Typography
            variant="body1"
            component="h3"
            sx={{ textAlign: "left", padding: "1.5% 0" }}
          >
            *Showing Results for{" "}
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              {nameAfterSuggestion}
            </span>
            {nameAfterSuggestion === "Perceptive Advisors LLC" && (
              <span> - (default)</span>
            )}{" "}
          </Typography>
          <div
            style={{ width: "100%", height: "67vh" }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              ref={gridRef}
              rowData={fundingCompanyList?.data?.data}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              supressMenuHide={true}
              components={components}
              pagination={true}
            ></AgGridReact>
          </div>
        </Paper>
      </div>
    </>
  );
};
