// /// action
// export const startHistoricalSearch = (data) => ({
//   type: "HISTORICAL_SEARCH_UPDATE",
//   data,
// });

// const getUrlParam = () => {
//   const queryParam = new URLSearchParams(window.location.search);
//   // const searchParam = "/historicalreadouts";

//   queryParam.set("page", 1);
//   queryParam.set("page_size", 10);
//   return queryParam.toString();
//   //return searchParam.toString();
// };

// const StartHistoricalSearchReducer = (state, action) => {
//   if (action.type === "HISTORICAL_SEARCH_UPDATE") {
//     return { searchParam: action.data };
//   }
//   return {
//     searchParam: getUrlParam(),
//   };
// };

// export const getHistoricalListReducer = {
//   // countriesList: getCountriesListReducer,
//   HistoricalSearchParamData: StartHistoricalSearchReducer,
// };

import { apiBegin, apiSuccess, apiFailure } from "../../../logic";
import apiClient from "../../../utils";

// const API_CONFIG = {
//   upcomingCalender: {
//     method: "GET",
//     url: "/api/calendar/upcomings",
//   },
// };

let API_CONFIG = {
  historicalReadoutsData: {
    method: "GET",
    url: "/calendar/historical/",
    data: {},
  },
  changeSentiment: {
    method: "PUT",
    url: "/calendar/updatesentiment",
    data: {},
  },
  userStatus: {
    method: "GET",
    url: "/userstatus/",
  },
};

// Api to downlaod data from form

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

/* ___________ACTIONS_______________ */

const getHistoricalRedoutesList = () => ({
  type: "GET_HISTORICAL_DATA",
});

const getHistoricalRedoutesSuccess = (data) => ({
  type: "GET_HISTORICAL_DATA_SUCCESS",
  data,
});

const getHistoricalRedoutesError = (error) => ({
  type: "GET_HISTORICAL_DATA_ERROR",
  error,
});

export const getHistoricalRedoutesReset = () => ({
  type: "GET_HISTORICAL_DATA_RESET",
});
// NEWLY  ADDED ACTION

/* ____________REDUCER____________ */
const getHistoricalRedoutesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "GET_HISTORICAL_DATA":
      return {
        loading: true,
        isError: false,
        data: null,
        error: null,
        flag: false,
      };
    case "GET_HISTORICAL_DATA_SUCCESS":
      return {
        loading: false,
        isError: false,
        data: action.data,
        error: null,
        flag: true,
      };
    case "GET_HISTORICAL_DATA_ERROR":
      return {
        loading: false,
        isError: true,
        data: null,
        error: action.error,
        flag: false,
      };

    case "GET_HISTORICAL_DATA_RESET":
      return {
        ...defaultState,
      };

    default:
      return { ...state };
  }
};

/* ____________API CALL______________ */

const startGetHistoricalRedoutesApiCall = (queryParams) => (dispatch) => {
  const apiConfig = { ...API_CONFIG.historicalReadoutsData };
  if (queryParams) {
    apiConfig.url += `?${queryParams}`;
   
  }
  dispatch(apiBegin());
  dispatch(getHistoricalRedoutesList());


  apiClient(apiConfig)
    .then((result) => {
     
      dispatch(apiSuccess());
      dispatch(getHistoricalRedoutesSuccess(result.data)); // grid  --> table

     
    })
    .catch((err) => {
      dispatch(apiFailure(err));
      dispatch(getHistoricalRedoutesError(err));
    });
};

export default startGetHistoricalRedoutesApiCall;

// actions

export const startHistoricalSearch = (data) => ({
  type: "HISTORICAL_SEARCH_UPDATE",
  data,
});

const getUrlParam = () => {
  const queryParam = new URLSearchParams(window.location.search);
  // console.log(queryParam);
  /* searchParam.delete("advanceSearch");
  searchParam.delete("source");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 10);
  
 */
  queryParam.set("page", 1);
  queryParam.set("page_size", 10);

  return queryParam.toString();
};

const StartUpcomingSearchReducer = (state, action) => {
  console.log("Start Upcoming Reducer", action, state);

  if (action.type === "HISTORICAL_SEARCH_UPDATE") {
    return { searchParam: action.data };
  }
  return {
    searchParam: getUrlParam(),
  };
};

export const getUpcomingListReducer = {
  // countriesList: getCountriesListReducer,
  UpcomingSearchParamData: StartUpcomingSearchReducer,
};

export const historicalReducer = {
  HistoricalRedoutes: getHistoricalRedoutesReducer,
};
