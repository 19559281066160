/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { session, headerUtils } from "../../utils";
import { resetFlag } from "../login/logic";
import { Menu, Button } from "antd";
import { ReactSVG } from "react-svg";
import "antd/dist/antd.css";
import "./Sidebar.scss";
import { HistoryOutlined, LogoutOutlined } from "@ant-design/icons";
import logo from "../assests/logo.svg";
import readoutlogo from "../assests/Upcoming.svg";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import { sideBarKey } from "../MainBody/GlobalStateReducer/GlobalState";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation } from "react-router-dom";

const { SubMenu } = Menu;

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

const Sidebar = ({ history, myKey }) => {
  const history1 = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [openKeys, setOpenKeys] = React.useState(["sub1"]);
  const [newKey /* setNewKey */] = useState("9");

  const url = "https://aiagents.perpetualblock.io/";
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = () => {
    session.clearSession(["isLoggedIn", "Token", "Refresh_Token"]);
    headerUtils.discardHeader();
    dispatch(resetFlag());
    history1.push("/landingPage");
    window.location.reload();
  };

  const handleHome = () => {
    dispatch(sideBarKey("9"));
    history.push("/home");
  };

  // useEffect(()=>{
  //    setNewKey(myKey);
  // },[myKey]);

  return (
    <div className="sidebar">
      <div className="brandlogoSide" onClick={handleHome}>
        <ReactSVG src={logo} />
      </div>
      <div className="side_menu">
        {newKey && (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            // style={{ width: 300, marginTop: 50 }}
            // defaultSelectedKeys={myKey}
            selectedKeys={myKey ? myKey : newKey}
          >
            {" "}
            <Menu.Item
              // key="9"
              icon={<HomeIcon />}
              title="Home"
              className={
                location.pathname.includes("home")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              onClick={() => {
                history.push("/home");
                // setNewKey("9");
                // dispatch(sideBarKey(null));
              }}
            >
              Home
            </Menu.Item>
            <SubMenu
              key="sub4"
              className={
                location.pathname.includes("upcomingReadouts") ||
                location.pathname.includes("analyseddata")
                  ? "upcoming-catalyst-calendar-active"
                  : "upcoming-catalyst-calendar"
              }
              icon={<ReactSVG src={readoutlogo} />}
              title="Upcoming Catalyst Calendar"
            >
              <Menu.Item
                // key="16"
                className={
                  location.pathname.includes("upcomingReadouts")
                    ? "sidebar-options-active"
                    : "sidebar-options"
                }
                onClick={() => {
                  history.push("/upcomingReadouts");
                  // setNewKey("16");
                  // dispatch(sideBarKey(null));
                }}
              >
                <Link to="/">All Upcoming Readouts</Link>
              </Menu.Item>
              <Menu.Item
                key="10"
                className={
                  location.pathname.includes("analyseddata")
                    ? "sidebar-options-active"
                    : "sidebar-options"
                }
                onClick={() => {
                  history.push("/analyseddata");
                  // setNewKey("10");
                  // dispatch(sideBarKey(null));
                }}
              >
                <Link to="/analyseddata">Analyzed Readouts</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              className={
                location.pathname.includes("historicalreadouts")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              key="11"
              icon={<HistoryOutlined />}
              onClick={() => {
                history.push("/historicalreadouts");
                // setNewKey("11");
                // dispatch(sideBarKey(null));
              }}
            >
              Historical Readouts
            </Menu.Item>
            <Menu.Item
              key="12"
              className={
                location.pathname.includes("companyscreener")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              icon={<ScreenSearchDesktopIcon />}
              onClick={() => {
                history.push("/companyscreener");
                // setNewKey("12");
                // dispatch(sideBarKey(null));
              }}
            >
              Company Screener
            </Menu.Item>
            <Menu.Item
              key="13"
              className={
                location.pathname.includes("institutionholdings")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              icon={<AccountBalanceIcon />}
              onClick={() => {
                history.push("/institutionholdings");
                // setNewKey("13");
                // dispatch(sideBarKey(null));
              }}
            >
              Institutional Holdings
            </Menu.Item>
            <Menu.Item
              key="14"
              className={
                location.pathname.includes("companysplot")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              icon={<BubbleChartIcon />}
              onClick={() => {
                history.push("/companysplot");
                // setNewKey("14");
                // dispatch(sideBarKey(null));
              }}
            >
              Analytics
            </Menu.Item>
            <Menu.Item
              key="15"
              className={
                location.pathname.includes("newsplot")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              icon={<NewspaperIcon />}
              onClick={() => {
                history.push("/newsplot");

                // setNewKey("15");

                // dispatch(sideBarKey(null));
              }}
            >
              News
            </Menu.Item>
            <Menu.Item
              key="17"
              // className={
              //   location.pathname.includes("")
              //     ? "sidebar-options-active"
              //     : "sidebar-options"
              // }
              // icon={<NewspaperIcon />}
              icon={<img src="./Frame 385.svg" style={{ width: "8%" }}></img>}
              onClick={() => {
                window.open(url, "_blank");
                // setNewKey("15");

                // dispatch(sideBarKey(null));
              }}
            >
              Alpha Bot
            </Menu.Item>
            {/* <Menu.Item
              key="16"
              className={
                location.pathname.includes("portfolio")
                  ? "sidebar-options-active"
                  : "sidebar-options"
              }
              // icon={<NewspaperIcon />}
              icon={<img src="./Portfolio.svg" style={{ width: "8%" }}></img>}
              onClick={() => {
                history.push("/portfolio");

                // setNewKey("15");

                // dispatch(sideBarKey(null));
              }}
            >
              Portfolio
            </Menu.Item> */}
          </Menu>
        )}
      </div>
      <div className="logout_btn">
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          icon={<LogoutOutlined />}
          onClick={handleClick}
          // loading={searchLoading}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
