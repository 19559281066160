import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import {useDispatch,useSelector} from "react-redux";

import apiClient from "../../../../../utils";
import ChecklistTable from "./ChecklistTable";
// import "./index.css";
import { Paper } from "@material-ui/core";
import Form from "./Form";
import { CircularProgress, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../../../logic.js";
// import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const COL_LIST = [
  {
    key: "Category",
    label: "Category",
    tableCellClassName: "my-class",
  },
  { key: "Parameter", label: "Parameter" },
  {
    key: "Value",
    label: "Value",
  },
  { key: "Score", label: "Score" },
];

//Data for bottom table =>
const COL_LIST1 = [
  {
    key: "Category",
    label: "Category",
    tableCellClassName: "my-class",
  },
  { key: "Parameter", label: "Parameter" },
  {
    key: "Value",
    label: "Value",
    // customCell: (row, index) => <div className="test-div">{row.fat}</div>,
  },
];

let API_CONFIG = {
  checklist_Results: {
    method: "GET",
    url: "/checklists/",
  },
};

const CheckList = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  // const searchParam = useSelector((state)=>state.checkListCategoryData.searchParam);

  const { hashid } = useParams();
  const [score, setScore] = useState(null);
  const [info, setInfo] = useState(null);
  const [checkListData, setcheckListData] = useState({
    data: null,
    category: "All",
  });
  const [initialScore, setInitialScore] = useState("");
  const [initialInfo, setInitialInfo] = useState("");

  const [value, setValue] = React.useState("1"); //Tab State

  const handleChange = (event, newValue) => {
    //handle Tab State
    setValue(newValue);
  };

  const apiCall = (hashId) => {
    const apiPayload = { ...API_CONFIG.checklist_Results };
    apiPayload.url += `?hashid=${hashId}`;

    apiClient(apiPayload)
      .then((res) => {
        setcheckListData({ data: res });
        dataToLocalVariable(res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    apiCall(hashid);
  }, [hashid]);

  function dataToLocalVariable(res) {
    setInitialScore(res.data[0].score_based);
    setInitialInfo(res.data[0].info_based);
    // console.log("from checklist screen", initialScore,initialInfo);
  }

  const checkListFilter = (e) => {
    const category = e.target.value;

    if (category === "All") {
      setScore(initialScore);
      setInfo(initialInfo);
      setcheckListData({
        category: category,
        data: {
          data: [
            {
              ...checkListData.data.data[0],
              score_based: [...initialScore],
              initial_info: [...initialInfo],
            },
          ],
        },
      });
    } else {
      setScore(
        initialScore.filter((ele) => ele.Category.indexOf(e.target.value) >= 0)
      );
      setInfo(
        initialInfo.filter((ele) => ele.Category.indexOf(e.target.value) >= 0)
      );
      setcheckListData({
        category: category,
        data: {
          data: [
            {
              ...checkListData.data.data[0],
              score_based: [...initialScore].filter((ele) => {
                return ele.Category.indexOf(e.target.value) >= 0;
              }),
              info_based: [...initialInfo].filter((ele) => {
                return ele.Category.indexOf(e.target.value) >= 0;
              }),
            },
          ],
        },
      });
    }
  };

  return (
    <>
      {checkListData.data ? (
        <div style={{ width: "100%", height: "96vh" }}>
          <div
            style={{
              display: "flex",
              padding: "2% 0 0 2%",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#000",
                boxShadow: "1px 1px #D3D3D3",
                padding: "10px 20px",
                borderRadius: "5px",
              }}
              onClick={() => {
                window.history.go(-1);
              }}
            >
              <ArrowBackIcon fontSize="small" />
              Back
            </Button>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              style={{
                fontWeight: "bold",
                marginLeft: "3%",
                marginTop: "0.5%",
              }}
            >
              Check List-{checkListData.data?.data?.[0].ticker}
            </Typography>
          </div>
          <Form
            checkListFilter={checkListFilter}
            category={checkListData.category}
          />
          <div style={{ width: "100%" }}>
            <Paper style={{ width: "95%", margin: "auto", height: "80vh" }}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      <Tab label="Scoring based parameters" value="1" />
                      <Tab label="Information based parameters" value="2" />
                    </TabList>
                  </Box>
                  <>
                    <TabPanel value="1">
                      <div>
                        {checkListData.data?.data && (
                          <ChecklistTable
                            columnList={COL_LIST}
                            data={
                              score
                                ? score
                                : initialScore
                                ? initialScore //checkListData.data.data[0].score_based
                                : []
                            }
                            top={true}
                          />
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div className="bottomTable">
                        {checkListData.data?.data && (
                          <ChecklistTable
                            columnList={COL_LIST1}
                            data={
                              info
                                ? info
                                : initialInfo
                                ? initialInfo //checkListData.data.data[0].info_based
                                : []
                            }
                            //data={rows1}
                          />
                        )}
                      </div>
                    </TabPanel>
                  </>
                </TabContext>
              </Box>
            </Paper>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default CheckList;

/*
import React, { useState, useEffect } from "react";
import { useParams} from "react-router-dom";
// import {useDispatch,useSelector} from "react-redux";

import apiClient from "../../../../../utils";
import ChecklistTable from "./ChecklistTable";
import "./index.css";
import { Paper } from "@material-ui/core";
import Form from "./Form";
import { CircularProgress,Box } from "@mui/material";
import { ArrowBackIcon } from '@mui/icons-material/ArrowBack';
import { ArrowBackIcon } from '@mui/icons-material/ArrowBack';
import { ArrowBackIcon } from '@mui/icons-material/ArrowBack';
const COL_LIST = [
  {
    key: "Category",
    label: "Category",
    tableCellClassName: "my-class",
  },
  { key: "Parameter", label: "Parameter" },
  {
    key: "Value",
    label: "Value",
  },
  { key: "Score", label: "Score" },
];

//Data for bottom table =>
const COL_LIST1 = [
  {
    key: "Category",
    label: "Category",
    tableCellClassName: "my-class",
  },
  { key: "Parameter", label: "Parameter" },
  {
    key: "Value",
    label: "Value",
    // customCell: (row, index) => <div className="test-div">{row.fat}</div>,
  },
];

let API_CONFIG = {
  checklist_Results: {
    method: "GET",
    url: "/checklists/",
  },
};

const CheckList = () => {
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const searchParam = useSelector((state)=>state.checkListCategoryData.searchParam);

  const { hashid } = useParams();
  const [checkListData, setcheckListData] = useState({ data: null });
  // const [apiFlag,setApiFlag] = useState(true);
  // const [scores,setScores] = useState([]);
  // const [infos,setInfos] = useState([]);
  const apiCall = (hashId) => {
    const apiPayload = { ...API_CONFIG.checklist_Results };
    apiPayload.url += `?hashid=${hashId}`;

    
    apiClient(apiPayload)
      .then((res) => {
        setcheckListData({ data: res });
        console.log("from checklist screen", checkListData?.data);
      })
      .catch((err) => console.log({ message: err }));
  };

  useEffect(() => {
    apiCall(hashid);
  }, [hashid]);
    const checkListFilter = (category) => {
      
      //  if(category === "drug_related"){
      //    setScores(checkListData.data?.data?.[0]?.score_based?.filter(ele=> ele.Category==="Drug related"));
      //    setInfos(checkListData.data?.data?.[0]?.info_based?.filter(ele=> ele.Category==="Drug related"));

      //  }
  }; 
  
             
  return (
    <>
    {checkListData.data ?  <div style={{ width: "100%", height: "110vh" }}>
        <h2 className="heading">Check List-{checkListData.data?.data?.[0].ticker}</h2>
        <Form checkListFilter={checkListFilter} />
        <div style={{ width: "100%", height: "100%" }}>
          <Paper style={{ width: "95%", margin: "auto" }}>
            <div className="topTable">
              <ChecklistTable
                columnList={COL_LIST}
                data={
                  checkListData.data?.data
                    ? checkListData.data.data[0].score_based
                    : []
                }
                top={true}
                //data={rows}
              />
            </div>

            <div className="bottomTable">
              <ChecklistTable
                columnList={COL_LIST1}
                data={
                  checkListData.data?.data
                    ? checkListData.data.data[0].info_based
                    : []
                }
                //data={rows1}
              />
            </div>
          </Paper>
        </div>
      </div> :  <Box
          sx={{
            height:"100%",
            width:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          >
            <CircularProgress color="inherit"/>
          </Box>}
     
    </>
  );
};

export default CheckList;
*/
