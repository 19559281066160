import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Box, Stack, Button } from "@mui/material";
import loginSideImage from "../assests/loginSidePopupImage.png";
import pbloginlogo from "../assests/pbloginlogo.svg";
import { ReactSVG } from "react-svg";
// import apiClient from "../../utils/apiClient";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../logic.js";
import axios from "axios";
import { storeUserName } from "../MainBody/GlobalStateReducer/GlobalState";

// let API_CONFIG = {
//     postEmail :{
//         method:"POST",
//         url:"/forgetpassword/",
//         data:{}
//     },

//     forgetPassword :{
//         method:"PUT",
//         url:"/resetpassword/",
//         data:{}
//     }
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  margin: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

export const EmailModal = ({ emailModal, setEmailModal, setOtpModal }) => {
  const dispatch = useDispatch();
  const [registeredMail, setRegisteredMail] = useState("");

  const handleEmail = (e) => {
    const { value } = e.target;
    setRegisteredMail(value);
    //console.log(registeredMail);
  };

  const handleClose = () => {
    setRegisteredMail("");
    setEmailModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const apiPayload  = {...API_CONFIG.postEmail};
    // apiPayload.data = {
    //     username:registeredMail
    // };
    const data = { username: registeredMail };
    axios
      .post(
        "http://thealphaexplorer.perpetualblock.io/api/forgetpassword/",
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.detail === "Success Message") {
          alert("OTP send to your registered Email");
          setEmailModal(false);
          //console.log(registeredMail);
          dispatch(storeUserName(registeredMail));
          setRegisteredMail("");
          setOtpModal(true);
        }
      })
      .catch((err) => {
        if (err.message.includes("404")) {
          alert("Email does not match with registered Email");
          setRegisteredMail("");
        } else if (err.message.includes("500")) {
          alert("Server is not responding");
        }
        //console.log(err.message.includes("404"),"from email modal");
        else {
          dispatch(apiFailure(err));
        }
      });
  };

  return (
    <div>
      <Modal
        open={emailModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack direction="row">
              <Box sx={{ width: "50%", padding: "5%", margin: "0 auto" }}>
                <ReactSVG src={pbloginlogo} style={{ textAlign: "center" }} />
                <Typography
                  id="modal-modal-title"
                  gutterBottom
                  variant="h6"
                  component="h2"
                  style={{ fontWeight: "bold" }}
                >
                  Please Enter Your Registered Email
                </Typography>
                <Box
                  sx={{
                    padding: "4% 0",
                  }}
                >
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    Email*
                  </Typography>
                  <input
                    placeholder="write your registered Email"
                    className="loginInputSection"
                    onChange={handleEmail}
                    type="email"
                    autoComplete="off"
                    value={registeredMail}
                  />
                </Box>

                <Box sx={{ margin: "3% 0" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="loginPageLoginButton"
                  >
                    Get OTP
                  </Button>
                </Box>
              </Box>

              <div style={{ width: "50%" }}>
                <img
                  src={loginSideImage}
                  alt="bioPharmaImage"
                  className="sideImageLogo"
                />
              </div>
            </Stack>
          </Box>
        </form>
      </Modal>
    </div>
  );
};
