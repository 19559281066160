import React from "react";
import { Box, Typography, Paper, Grid, Stack } from "@mui/material";

export const WACCoutput = ({ WaccData }) => {
  return (
    <Paper elevation={12} sx={{ padding: "1.5%" }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          component="h2"
          style={{ margin: "10px 0", fontWeight: "bold" }}
        >
          WACC output
        </Typography>
        {/* <IconButton aria-label="delete" size="small">
            <Filter
              fontSize="inherit"
              style={{ color: "#076281" ,visibility: "hidden"}}
            />
            <span style={{visibility: "hidden"}}>edit section</span>
          </IconButton> */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} style={{}}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Weight of Debt
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.weight_of_debt).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Weight of Equity
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.weight_of_equity).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Cost of debt
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.cost_of_debt).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Cost of equity
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.cost_of_equity).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  WACC
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.default_wacc).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                height: 180,
                margin: "31px 0",
                backgroundColor: "#076281",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                component="body1"
                style={{ color: "white", margin: "31px 0" }}
              >
                Discount Rate used for discounting Cashflow (Default wacc)
              </Typography>
              <Box
                sx={{
                  width: "80%",
                  margin: "auto",
                  height: 55,
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(WaccData.default_wacc).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
