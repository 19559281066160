import "./indexpage.css";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sideBarKey } from "../GlobalStateReducer/GlobalState";
// import { sideBarKey } from "../MainBody/GlobalStateReducer/GlobalState";
import Brandlogo from "./../../assests/AlfhaExplorerSVG.svg";
import XBI_Icon from "./../../assests/XBI_Icon.png";
import TextField from "@mui/material/TextField";
import Nasdaqlogo from "./../../assests/NasdaqLogo.png";
// const { Search } = Input;
import { TradingChart } from "./TradeViewComp";
// import SearchIcon from "@material-ui/icons/Search";
import { useState } from "react";
import NewsCards from "../Newsfeeds/Newscards";
import Advancers from "./AdvancersTable";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { apiFailure } from "../../../logic";
import Autocomplete from "@material-ui/lab/Autocomplete";
import apiClient from "../../../utils";
import { useEffect } from "react";
import { Avatar } from "antd";
import Decliners from "./DeclinersTable";

const HomeComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [widgetbtn, setWidgetbtn] = useState("XBI");
  const [value, setValue] = useState("a");
  const [advancers, setAdvancers] = useState("");
  const [decliners, setDecliners] = useState("");
  const [search, setSearch] = useState("");
  const [option, setOption] = useState("");
  const [newsdata, setNewsData] = useState("");

  useEffect(() => {
    setAdvancersData();
    setDeclinersData();
    setOptions();
    setNews();
  }, []);
  let API_CONFIG = {
    ADVANCERS: {
      method: "GET",
      url: "/topmovers/?type=advancers",
      data: {},
    },
    DECLINERS: {
      method: "GET",
      url: "/topmovers/?type=decliners",
      data: {},
    },
    COMPANY_NAME: {
      method: "GET",
      url: "/company_info/",
      data: {},
    },
    NEWS: { method: "GET", url: "/news/", data: {} },
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const setAdvancersData = () => {
    const apiPayload = { ...API_CONFIG.ADVANCERS };

    apiClient(apiPayload)
      .then((res) => {
        setAdvancers(res);
      })
      .catch((err) => {
        console.log({ message: err });

        dispatch(apiFailure(err));
      });
  };
  const setDeclinersData = () => {
    const apiPayload = { ...API_CONFIG.DECLINERS };

    apiClient(apiPayload)
      .then((res) => {
        setDecliners(res);
      })
      .catch((err) => {
        console.log({ message: err });

        dispatch(apiFailure(err));
      });
  };
  const tickers = "fierce_news";
  const setNews = () => {
    const apiPayload = { ...API_CONFIG.NEWS };
    apiPayload.url += `?ticker=${tickers}`;
    apiClient(apiPayload)
      .then((res) => {
        setNewsData(res);
      })
      .catch((err) => {
        console.log({ message: err });

        dispatch(apiFailure(err));
      });
  };

  const COL_LIST_ADV = [
    { key: "ticker", label: "Ticker" },
    { key: "company", label: "Company " },
    { key: "close", label: "Close [$]" },
    { key: "change_p", label: "Change %" },
  ];
  const changeWidget = (symbol) => {
    setWidgetbtn(symbol);
  };
  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  // };
  const setOptions = () => {
    const apiPayload = { ...API_CONFIG.COMPANY_NAME };

    apiClient(apiPayload)
      .then((res) => {
        let arr = res?.data.map((data, idx) => {
          return { label: data.company_name, value: data.ticker };
        });
        setOption(arr);
      })
      .catch((err) => {
        console.log({ message: err });

        dispatch(apiFailure(err));
      });
  };
  const getNewsPage = () => {
    history.push("/newsplot/");
  };
  useEffect(() => {
    if (search) {
      history.push(`/company_info/?symbol=${search}`);
    }
  }, [search]);

  // const onSearchClick = () => {};

  return (
    <div className="maindiv">
      <div className="brandlogo">
        <img className="img_logo" src={Brandlogo} alt="brand logo "></img>
      </div>
      <div className="barsearch">
        <div style={{ display: "flex" }}>
          {/* <Paper elevation={0}> */}
          {/* <TextField
            style={{
              width: 300,
              backgroundColor: "white",
              justifyContent: "left",
            }}
            // className=" searchfield"
            onSearch={option}
            onChange={handleSearchChange}
            label="Search by Ticker , Company Name"
            name="search"
          ></TextField> */}
          {option && (
            <Autocomplete
              id="combo-box-demo"
              options={option}
              getOptionLabel={(option) => option?.label}
              onChange={(event, option) => setSearch(option?.value)}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Ticker , Company Name"
                  variant="outlined"
                  backgroundColor="white"
                />
              )}
            />
          )}
          {/* <Button
            className="home search"
            variant="contained"
            size="large"
            onClick={onSearchClick}
            style={{
              width: "20px",
              height: "60px",
              color: "#fff",
              backgroundColor: "#076281",
            }}
            // startIcon={SearchIcon}
          >
            <SearchIcon></SearchIcon>
          </Button> */}
        </div>
      </div>
      <div className="newscss">
        <span className="spantag0">
          <b>Latest Biotech News</b>
        </span>
      </div>
      <div className="firsthalf">
        <section className="sectionplot1">
          <div style={{ width: " 100%", height: "90%", cursor: "pointer" }}>
            {" "}
            <NewsCards data={newsdata}></NewsCards>
            <span className="newsLink" onClick={getNewsPage}>
              View More
            </span>
          </div>
        </section>
        <section className="sectionplot">
          <div
            style={{
              height: "9%",
              display: " flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <Button
                className="icon_btn1"
                startIcon={<Avatar shape="square" src={XBI_Icon} />}
                style={{ width: "100%" }}
                onClick={() => changeWidget("XBI")}
              >
                <b>XBI</b>
              </Button>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              {" "}
              <Button
                className="icon_btn1"
                startIcon={<Avatar shape="square" src={Nasdaqlogo} />}
                style={{ variant: "contained", width: "100%" }}
                onClick={() => changeWidget("NBI")}
              >
                <b>NBI</b>
              </Button>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              {" "}
              <Button
                className="icon_btn1"
                startIcon={<Avatar shape="square" src={Nasdaqlogo} />}
                style={{ variant: "contained", width: "100%" }}
                onClick={() => changeWidget("IXIC")}
              >
                <b>IXIC</b>
              </Button>
            </div>
          </div>
          {widgetbtn === "XBI" && (
            <div style={{ padding: "2%", height: " 90%" }}>
              <TradingChart symbol={widgetbtn} />
            </div>
          )}
          {widgetbtn === "NBI" && (
            <div style={{ padding: "2%", height: " 90%" }}>
              <TradingChart symbol={widgetbtn} />
            </div>
          )}
          {widgetbtn === "IXIC" && (
            <div style={{ padding: "2%", height: " 90%" }}>
              <TradingChart symbol={widgetbtn} />
            </div>
          )}
        </section>
      </div>
      <div className="activeplatform" style={{ display: "flex" }}>
        {/* <span className="spantag1"> <h2>Active Movers</h2></span> */}
        <span className="spantag1">
          {" "}
          <b>Active Movers</b>
        </span>
        <span className="spantag2">
          <b>Our Platform Elements</b>
        </span>
      </div>
      <div className="secondhalf">
        <section className="sectionplot3">
          {/* compnay table start */}
          <Box>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="ADVANCERS" value="a" />
                  <Tab label="DECLINERS" value="b" />
                </TabList>
              </Box>
              <TabPanel value="a">
                {
                  <Advancers
                    columnList={COL_LIST_ADV}
                    data={
                      advancers?.data ? advancers.data : ["No Advancers Found"]
                    }
                  ></Advancers>
                }
              </TabPanel>
              <TabPanel value="b">
                {
                  <Decliners
                    columnList={COL_LIST_ADV}
                    data={
                      decliners?.data ? decliners.data : ["No Decliners Found"]
                    }
                  ></Decliners>
                }
              </TabPanel>
            </TabContext>
          </Box>
          {/* compnay table end */}
        </section>

        <section className="sectionplot">
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 15%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.3%",
              border: "2px white ",
              marginTop: "0.3%",
              borderTop: "3px",
              // borderSpacing : "2px"
            }}
            onClick={() => {
              history.push("/upcomingReadouts");
              dispatch(sideBarKey("16"));
            }}
          >
            All Upcoming Readouts
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              width: " 100%",
              fontWeight: "bold",
              fontSize: "0.85vw",
              height: " 14%",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              history.push("/analyseddata");
              dispatch(sideBarKey("10"));
            }}
          >
            Analyzed Readouts with detailed Due-Diligence
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 14%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              history.push("/historicalreadouts");
              dispatch(sideBarKey("11"));
            }}
          >
            All Historical Readouts and thier Performances
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 14%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              history.push("/companyscreener");
              dispatch(sideBarKey("12"));
            }}
          >
            Company Screener based on Financials and Valuations
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 14%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              history.push("/institutionholdings");
              dispatch(sideBarKey("13"));
            }}
          >
            Institutional Holdings{" "}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 14%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              history.push("/companysplot");
              dispatch(sideBarKey("14"));
            }}
          >
            Analytics- Graphical way to filter attractive valued companies
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#076281",
              color: "#fff",
              fontWeight: "bold",
              height: " 14%",
              width: " 100%",
              fontSize: "0.85vw",
              marginRight: "0.5%",
              marginLeft: "0.5%",
              marginBottom: "0.2",
              border: "2px solid white ",
            }}
            onClick={() => {
              getNewsPage();
              dispatch(sideBarKey("15"));
            }}
          >
            News
          </Button>
        </section>
      </div>
    </div>
  );
};

export default HomeComponent;
