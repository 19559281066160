import React from "react";

const Reward = ({ reWard }) => {
  // console.log("from reward component",reWard);
  return (
    <div>
      <p style={{ color: "#05c00d", fontWeight: "bold" }}>
        {reWard ? `${Number(reWard.rr_upside).toFixed(2)}%` : "-"}
      </p>
      <p style={{ color: "#cf1d08", fontWeight: "bold" }}>
        {reWard ? `${Number(reWard.rr_downside).toFixed(2)}%` : "-"}
      </p>

      {reWard?.long_short && (
        <p style={{ fontWeight: "bold" }}>
          Trade Type : {reWard ? reWard?.long_short : "-"}
        </p>
      )}
    </div>
  );
};
export default Reward;
