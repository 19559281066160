import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import "./index.scss";
import ModalForm from "./ModalForm";
// import LineChart from "./LineChart";
import ActivePosition from "./ActivePositionTable";
// import AddPosition from "./AddPosition";
import PortoflioModal from "./PortoflioModal";
import RealisedModal from "./RealisedModal";
import apiClient from "../../utils";
import { apiFailure } from "../../logic";
import { useDispatch } from "react-redux";
const Portfolio = () => {
  let API_CONFIG = {
    fetchPortfolio: {
      method: "GET",
      url: "/fetchAllPortfolio",
      data: {},
    },
    fetchPosition: {
      method: "GET",
      url: "/fetchActivePosition",
      data: {},
    },
  };
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState();
  const [availableFundValue, setAvailableFundValue] = useState();

  const [tableData, setTableData] = React.useState();

  const [realisedOpen, setRealisedOpen] = useState(false);

  const [portfolioOpen, setPortfolioOpen] = useState(false);

  const handleForm = () => {
    setModalOpen(true);
  };
  const handleOpen = () => {
    setPortfolioOpen(true);
  };
  const fetchActivePortfolios = () => {
    const apiPayload = { ...API_CONFIG.fetchPortfolio };

    apiClient(apiPayload)
      .then((res) => {
        setPortfolioOptions(res?.data);
        setPortfolioValue(res?.data[0]?.portfolioName);
        setAvailableFundValue(res?.data[0]?.availableFundBalance);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  const fetchActivePosition = () => {
    const apiPayload = { ...API_CONFIG.fetchPosition };
    apiPayload.url += `?name=${portfolioValue}`;
    apiClient(apiPayload)
      .then((res) => {
        setTableData(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };
  useEffect(() => {
    fetchActivePortfolios();
  }, []);

  useEffect(() => {
    fetchActivePosition();
  }, [portfolioValue]);
  let sumCurrentValue = 0;
  let sumTotalAmountInvested = 0;
  let sumEntryQuantity = 0;

  if (tableData) {
    for (const entry of tableData) {
      sumEntryQuantity += entry.entryPrice * entry.quantity;

      sumCurrentValue += entry.current_value;
      sumTotalAmountInvested += entry.Total_amount_invested;
    }
  }
  // availableFundValue &&
  //   setAvailableFundValue(availableFundValue - sumEntryQuantity);
  const renderOptions = () => {
    return portfolioOptions?.map((option, index) => (
      <MenuItem
        key={index}
        value={option?.portfolioName}
        onClick={() => setPortfolioValue(option?.portfolioName)}
      >
        {option?.portfolioName}
      </MenuItem>
    ));
  };
  return (
    <div className="portfolio">
      <RealisedModal
        realisedOpen={realisedOpen}
        setRealisedOpen={setRealisedOpen}
      />

      <ModalForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <PortoflioModal
        portfolioOpen={portfolioOpen}
        setPortfolioOpen={setPortfolioOpen}
      />
      <Grid container className="dropdown-grid">
        <Grid item>
          <Paper
            sx={{ width: 220, backgroundColor: "#002e64", color: "#ffffff" }}
          >
            <Box sx={{ width: 220 }}>
              <FormControl fullWidth className="formControl">
                {/* <InputLabel id="demo-simple-select-label" >
                  All time Change
                </InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={portfolioValue ? portfolioValue : ""}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    color: "#ffffff",
                  }}
                  // onChange={handleChange}
                  className="select-box"
                >
                  {portfolioOptions && renderOptions()}
                  {/* <MenuItem value={"portfolio1"}>Portfolio 1</MenuItem>
                  <MenuItem value={"porfolio2"}>Portfolio 2</MenuItem> */}
                  <MenuItem
                    value={"add"}
                    onClick={handleOpen}
                    style={{
                      color: "#002e64",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                  >
                    Add Portfolio &nbsp; &nbsp;
                    <AddIcon />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Paper sx={{ width: 220 }}>
            <Box sx={{ width: 220 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  All time Change
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Age"
                  // onChange={handleChange}
                >
                  <MenuItem value={10}>All Time Change</MenuItem>
                  <MenuItem value={20}>Monthly Change</MenuItem>
                  <MenuItem value={30}>Weekly Change</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item> */}
        <Button className="add-button" variant="contained" onClick={handleForm}>
          {" "}
          Add Positions &nbsp;
          <AddIcon />
        </Button>
        {/* </Grid> */}
      </Grid>
      {/* <LineChart></LineChart> */}
      <div className="second-row">
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">
              Available Fund Balance
            </Typography>
            <Typography className="text-paper-box-row2">
              $ {availableFundValue - sumEntryQuantity}
            </Typography>
          </Paper>
        </Box>
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">
              Total Amount Invested
            </Typography>
            <Typography className="text-paper-box-row2">
              {" "}
              $ {sumTotalAmountInvested}
            </Typography>
          </Paper>
        </Box>{" "}
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">Current Value</Typography>
            <Typography className="text-paper-box-row2">
              $ {sumCurrentValue}
            </Typography>
          </Paper>
        </Box>{" "}
        <Box className="paper-boxes">
          <Paper>
            <Typography className="text-paper-box">Unrealised P/L</Typography>
            <Typography className="text-paper-box-row2">
              $ {(sumCurrentValue - sumTotalAmountInvested).toFixed(2)}
              <br></br>
              {/* {((sumCurrentValue -sumTotalAmountInvested).toFixed(2) * 100/sumTotalAmountInvested).toFixed(2)} */}
            </Typography>
          </Paper>
        </Box>{" "}
        <Box
          className="paper-boxes-realised"
          onClick={() => setRealisedOpen(true)}
        >
          <Paper>
            <Typography className="text-paper-box">Realised Value</Typography>
            <Typography className="text-paper-box-row2">$ 50 (+5%)</Typography>
          </Paper>
        </Box>
      </div>

      <Paper style={{ margin: "0% 2%" }}>
        <Typography className="table-heading">Active Position</Typography>
        <div className="position-table">
          <ActivePosition tableData={tableData} />
        </div>
      </Paper>

      {/* <AddPosition modalOpen={modalOpen} setModalOpen={setModalOpen} /> */}
    </div>
  );
};

export default Portfolio;
