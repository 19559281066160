import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../../../../utils";
import Form from "./Form";
import "./index.css";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../../../logic.js";
import { Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

let API_CONFIG = {
  moa_Results: {
    method: "GET",
    url: "/moa/",
  },
};

const MoAResults = () => {
  const { hashid } = useParams();
  const [moaData, setMoaData] = useState({ data: null });
  const dispatch = useDispatch();

  const apiCall = (hashId) => {
    const apiPayload = { ...API_CONFIG.moa_Results };
    apiPayload.url += `?hashid=${hashId}`;

    apiClient(apiPayload)
      .then((res) => {
        setMoaData({ data: res });
        //console.log("from moa screen",res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    apiCall(hashid);
  }, [hashid]);

  return (
    <>
      <div style={{ display: "flex", padding: "2%", alignItems: "center" }}>
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "1px 1px #D3D3D3",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
          onClick={() => {
            window.history.go(-1);
          }}
        >
          <ArrowBackIcon fontSize="small" />
          Back
        </Button>
        <Typography
          variant="h5"
          gutterBottom
          component="h2"
          style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
        >
          Mechanisms of Action:{moaData.data?.data?.[0]?.ticker}
        </Typography>
      </div>
      <div className="prior_page">
        <div className="prior_data">
          <Form recData={moaData?.data?.data ? moaData.data.data : []} />
        </div>
      </div>
    </>
  );
};

export default MoAResults;
