import React, { useState } from "react";
import { Typography, Box, Button, Paper } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import apiClient from "../../../../../utils";
import { apiFailure } from "../../../../../logic";

let API_CONFIG = {
  feedbackMail: {
    method: "POST",
    url: "/riskreward/feedback",
    data: {},
  },
};

export const FeedbackPanel = () => {
  const { hashid } = useParams();
  const dispatch = useDispatch();

  const [feedbackText, setFeedBackText] = useState({
    text: "",
    hash_id: hashid,
  });

  const handleTextChange = (e) => {
    const { value } = e.target;
    setFeedBackText({ ...feedbackText, text: value });
  };

  const handleMail = () => {
    if (feedbackText.text === "") {
      return alert("Feedback cannot be empty");
    } else {
      const apiPayload = { ...API_CONFIG.feedbackMail };
      apiPayload.data = feedbackText;
      apiClient(apiPayload)
        .then((res) => {
          if (res.data === "Mail sent") {
            alert("Your feedback is recorded");
            setFeedBackText({ ...feedbackText, text: "" });
          }
        })
        .catch((err) => {
          console.log({ message: err });
          dispatch(apiFailure(err));
        });
    }
  };

  return (
    <Paper elevation={12} sx={{ marginTop: "2%", padding: "1.5%" }}>
      <Typography
        gutterBottom
        variant="h6"
        component="h3"
        style={{ margin: "10px 0", fontWeight: "bold" }}
      >
        Feedback Panel
      </Typography>
      <Box
        sx={{
          border: "1px dashed #ADADAD",
          width: "95%",
          margin: "auto",
        }}
      >
        <TextareaAutosize
          aria-label="Write your feedback here"
          placeholder="Write your feedback here"
          maxRows={6}
          minRows={6}
          style={{
            width: "100%",
            margin: "auto",
            border: "none",
            outline: "none",
            padding: "1.5%",
            fontSize: "20px",
          }}
          value={feedbackText.text}
          onChange={handleTextChange}
        ></TextareaAutosize>
        <Button
          size="large"
          variant="contained"
          style={{ backgroundColor: "#002E64", marginBottom: "1.5%" }}
          onClick={handleMail}
        >
          Mail to admin
        </Button>
      </Box>
    </Paper>
  );
};
