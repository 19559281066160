import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Typography, Box, Stack, Button } from "@mui/material";
import loginSideImage from "../assests/loginSidePopupImage.png";
import pbloginlogo from "../assests/pbloginlogo.svg";
import { ReactSVG } from "react-svg";
// import apiClient from "../../utils/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { apiFailure } from "../../logic.js";
import axios from "axios";

// let API_CONFIG = {
//     postEmail :{
//         method:"POST",
//         url:"/forgetpassword/",
//         data:{}
//     },

//     forgetPassword :{
//         method:"PUT",
//         url:"/resetpassword/",
//         data:{}
//     }
// };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  margin: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

export const OtpModal = ({ otpModal, setOtpModal }) => {
  const GlobalUsername = useSelector((state) => state.globalState.username);
  console.log("from OtpModal userName", GlobalUsername);
  const dispatch = useDispatch();
  const [otpInfo, setOtpInfo] = useState({
    otp: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOtpInfo((val) => ({ ...val, [name]: value }));
    // console.log(otpInfo);
  };

  const handleClose = () => {
    setOtpModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { ...otpInfo, username: GlobalUsername };
    const { otp, password, confirm_password } = otpInfo;
    if (otp === "" || password === "" || confirm_password == "") {
      return alert("Field Cannot be empty");
    } else if (password !== confirm_password) {
      alert("Passwords do not match");
      setOtpInfo({ ...otpInfo, password: "", confirm_password: "" });
    } else {
      axios
        .put(
          "http://thealphaexplorer.perpetualblock.io/api/resetpassword/",
          data
        )
        .then((res) => {
          console.log("from otp response", res);
          if (res.data === "Password Changed Successfully") {
            alert("Password Changed Successfully");
            setOtpModal(false);
          }
          setOtpInfo({
            username: "",
            otp: "",
            password: "",
            confirm_password: "",
          });
        })
        .catch((err) => {
          if (err.message.includes("400")) {
            alert("OTP is incorrect");
            setOtpInfo({
              ...otpInfo,
              otp: "",
            });
          } else if (err.message.includes("500")) {
            alert("Server is not responding");
          } else {
            dispatch(apiFailure(err));
          }
        });
    }
  };

  return (
    <div>
      <Modal
        open={otpModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack direction="row">
              <Box sx={{ width: "50%", padding: "5%", margin: "0 auto" }}>
                <ReactSVG src={pbloginlogo} style={{ textAlign: "center" }} />
                <Typography
                  id="modal-modal-title"
                  gutterBottom
                  variant="h6"
                  component="h2"
                  style={{ fontWeight: "bold" }}
                >
                  Enter OTP and New Password
                </Typography>
                <Box
                  sx={{
                    padding: "4% 0",
                  }}
                >
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    OTP
                  </Typography>
                  <input
                    placeholder="Enter your OTP"
                    className="loginInputSection"
                    onChange={handleChange}
                    name="otp"
                    value={otpInfo.otp || ""}
                    autoComplete="off"
                  />
                </Box>

                <Box
                  sx={{
                    padding: "0 0",
                  }}
                >
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    New Password
                  </Typography>
                  <input
                    placeholder="Enter your new password"
                    className="loginInputSection"
                    onChange={handleChange}
                    value={otpInfo.password || ""}
                    name="password"
                    autoComplete="off"
                    type="password"
                  />
                </Box>

                <Box
                  sx={{
                    padding: "4% 0",
                  }}
                >
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    Confirm Password
                  </Typography>
                  <input
                    placeholder="Re-enter your new password "
                    className="loginInputSection"
                    onChange={handleChange}
                    value={otpInfo.confirm_password || ""}
                    name="confirm_password"
                    autoComplete="off"
                    type="password"
                  />
                </Box>

                <Box sx={{ margin: "3% 0" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="loginPageLoginButton"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>

              <div style={{ width: "50%" }}>
                <img
                  src={loginSideImage}
                  alt="bioPharmaImage"
                  className="sideImageLogo"
                />
              </div>
            </Stack>
          </Box>
        </form>
      </Modal>
    </div>
  );
};
