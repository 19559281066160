/* eslint-disable no-unused-expressions */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./index.css";
import { useHistory } from "react-router-dom";

import apiClient from "../../../utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
    //fontSize:12,
    // border: "1px solid black",
    // borderRadius: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    // border: "1px solid black",
    // borderRadiusLeftTop: "10px",
    // borderRadiusRightTop: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: "1px solid black",
    // borderRadius: "10px",
  },
}));

let API_CONFIG = {
  // upcomingCalender: {
  //   method: "GET",
  //   url: "/calendar/historical/",
  // },
  historicalData: {
    method: "GET",
    url: "/calendar/historical/",
  },
  changeSentiment: {
    method: "PUT",
    url: "/calendar/updatesentiment",
    data: {},
  },
};

export default function CustomizedTableHistorical({
  setReRunUseEffect,
  setHistorical,
  columnList,
  data,
  count,
  isAdmin,
  option,
  optionMcap,
  optionStage,
  optionSentiment,
  startDate,
  endDate,
  search,
  TA,
}) {
  const history = useHistory();
  // const dispatch = useDispatch();
  //  const searchParam = useSelector(
  //   (state) => state.s.searchParam
  // )
  const apiCall = (queryData) => {
    const apiPayload = { ...API_CONFIG.historicalData };

    apiPayload.url += `${queryData}`;
    // apiPayload.url += queryParams;  // prev
    // apiPayload.url += searchParam;
    // setLoading(true);

    apiClient(apiPayload)
      .then((res) => {
        //renderStatusChange(res);
        //console.log(res);
        let statusPutMessage = res.data.message;
        console.log("this is from historical data : ", statusPutMessage);
        window.location.reload();
      })
      .catch((err) => console.log({ message: err }));
  };
  const handleSentimentClick = (rowId, param) => {
    const id = rowId;
    const value = param;
    const apiPayload = { ...API_CONFIG.changeSentiment };
    apiPayload.data = {
      id: id,
      sentiment: value,
    };
    apiClient(apiPayload)
      .then((res) => {
        if (res.data === "Field_updated") {
          setReRunUseEffect((current) => !current);
          // setHistoricalReadoutRowData({ data: null });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong"); //
        // dispatch(apiFailure(err));
      });
  };
  const handleStatusChange = (id) => {
    apiCall(id);
  };

  const renderHeadCols = () => {
    return columnList.map((row, index) => {
      if (index === 0) {
        return (
          <StyledTableCell
            key={`head_${row.label}_${index}`}
            align="center"
            className="coutom_headCol"
            style={{ position: "sticky", left: 0, zIndex: 10 }}
          >
            {row.label}
          </StyledTableCell>
        );
      } else {
        return (
          <StyledTableCell
            key={`head_${row.label}_${index}`}
            align="center"
            className="coutom_headCol"
          >
            {row.label}
          </StyledTableCell>
        );
      }
    });
  };

  const handleTradeViewClick = (symbol) => {
    history.push(`/company_info/?symbol=${symbol}`);
  };

  const renderCells = (row) => {
    return columnList.map((col, index) => {
      if (index === 0) {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells firstCellOFEachRow"
            onClick={() => handleTradeViewClick(row[col.key])}
            style={{ position: "sticky", left: 0, background: "white" }}
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
          </StyledTableCell>
        );
      } else if (index === 9) {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells1"
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
            {isAdmin === "YES" && row?.[col.key] === "to be analysed" && (
              <p
                className="analysedText"
                style={{ color: "#076281", fontSize: "12px" }}
                onClick={() => handleStatusChange(row?.[col.id])}
              >
                Change Status
              </p>
            )}
          </StyledTableCell>
        );
      } else {
        if (col.key === "Sentiment") {
          if (isAdmin === "YES") {
            return (
              <StyledTableCell
                // className={col.tableCellClassName || ""}
                key={`row_${col.key}_${index}`}
                align="center"
                className="table_cells1"
              >
                {typeof row?.[col.key] === "number" ||
                typeof row?.[col.key] === "string" ? (
                  row[col.key]
                ) : (
                  <div>
                    <a
                      onClick={() => handleSentimentClick(row._id, "Positive")}
                    >
                      P
                    </a>
                    /
                    <a
                      onClick={() => handleSentimentClick(row._id, "Negative")}
                    >
                      N
                    </a>
                    /
                    <a
                      onClick={() =>
                        handleSentimentClick(row._id, "Inconclusive")
                      }
                    >
                      I
                    </a>
                  </div>
                )}
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                // className={col.tableCellClassName || ""}
                key={`row_${col.key}_${index}`}
                align="center"
                className="table_cells1"
              >
                {typeof row?.[col.key] === "number" ||
                typeof row?.[col.key] === "string"
                  ? row[col.key]
                  : "-"}
              </StyledTableCell>
            );
          }
        } else {
          return (
            <StyledTableCell
              // className={col.tableCellClassName || ""}
              key={`row_${col.key}_${index}`}
              align="center"
              className="table_cells1"
            >
              {/* col.customCell?.(row, index) || row?.[col.key] */}
              {typeof row?.[col.key] === "number" ||
              typeof row?.[col.key] === "string"
                ? row[col.key]
                : "-"}
            </StyledTableCell>
          );
        }
      }
    });
  };

  const renderTableRows = () => {
    if (data?.results?.length > 0) {
      return data?.results?.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ));
    } else {
      return <h3>No data found</h3>;
    }
  };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "5px",
          marginBottom: "-5px",
        }}
      >
        
      </div> */}
      <p
        style={{
          display: "flex",
          paddingRight: " 2%",
          justifyContent: "right",
        }}
      >
        Showing {data?.results?.length} of {data?.count} results
      </p>

      <TableContainer
        component={Paper}
        sx={{ maxHeight: "60vh" }}
        className="Historical_table1"
        //    classes={{ root: classes.customTableContainer }}
      >
        {/*  <p style={{ textAlign: "right", padding: "5px", padding: "1%" }}>
        Showing {data.length} of {count?.count} results
      </p> */}
        <Table
          sx={{ minWidth: 500 }}
          aria-label="customized sticky table"
          stickyHeader
          className="Historical_table1_content"
        >
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
          {/*  <TableFooter>
            <TableRow>
              <TablePagination count={10} />
            </TableRow>
          </TableFooter> */}
        </Table>
      </TableContainer>
      {/*   <TablePagination
        sx={{ position: "relative" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
}
