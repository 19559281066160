import React from "react";
import { Box, Typography, Paper, Grid, Stack } from "@mui/material";
// import Stack from "@mui/material/Stack";
// import Styles from "./index.module.css";
// import TextField from "@mui/material/TextField";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";

export const MainInput = ({ inputData }) => {
  return (
    <Paper elevation={12} sx={{ padding: "1.5%" }}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ margin: "10px 0", fontWeight: "bold" }}
        >
          Input / Assumptions on DCF screen
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} style={{}}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Debt on books (in Mn)
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Debt_on_books).toFixed(
                  2
                )}`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Equity on books
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Equity_on_books).toFixed(
                  2
                )}`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Interest rate
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Interest_rate).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Tax rate
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Tax_rate).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Risk free rate
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Risk_free_rate).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Return on market
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Return_on_market).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Beta
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Beta).toFixed(
                  2
                )}`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  Operating Costs (% of revenue)
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.Operating_costs).toFixed(
                  2
                )}%`}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: "100%",
                  height: 55,
                  backgroundColor: "#076281",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  component="body1"
                  style={{ color: "white" }}
                >
                  No of O/S Shares (in Mn)
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#E5E5E5",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>{`${Number(inputData.No_of_os_shares).toFixed(
                  2
                )}`}</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {/* <Typography variant="h6" component="h2" style={{margin:"10px 0",fontWeight:"bold" }}>
            Input / Assumptions on DCF screen
          </Typography>
         
                 <Grid container spacing={1}>
                   <Grid item xs={12}>
                       <Stack direction="row" spacing={1}  style={{  }}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Debt on books (in Mn)
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                 </Stack>
                   </Grid>
                  <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Equity on books
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                  <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Interest rate
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                    <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Tax rate
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                   <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Risk free rate
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                   <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Return on market
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                   <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Beta
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                   <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      No of O/S shares (in Mn)
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                  <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Box
                    sx={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#076281",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="body1"
                      style={{ color: "white" }}
                    >
                      Operating Cost (% of revenue)
                    </Typography>
                  </Box>
                   <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#E5E5E5",
                     borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>682</Typography>
                  </Box>
                </Stack>
                  </Grid>
                 </Grid>    */}
      </Box>
    </Paper>
  );
};
