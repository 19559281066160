import React from "react";
import logo from "../../../../assests/MoaPic.png";
//import Button from "@material-ui/core/Button";
/* import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText"; */

//import SearchIcon from "@material-ui/icons/Search";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import { Button, Input, TextareaAutosize } from "@material-ui/core";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Paper } from "@mui/material";

const Form = ({ recData }) => {
  return (
    <>
      <div>
        <Card sx={{ width: "100%", margin: "auto" }}>
          <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
            <Paper elevation={3}>
              <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={recData[0]?.images ? recData[0].images : logo}
                alt="green iguana"
                align="left"
              />
            </Paper>

            <CardContent align="left" style={{ margin: "25px 0" }}>
              <Typography gutterBottom variant="h5" component="div">
                {recData[0]?.heading ? recData[0].heading : null}
              </Typography>
              {recData[0]?.descriptions.length > 0
                ? recData[0]?.descriptions.map((item, index) => {
                    return (
                      <Typography variant="h6" color="text.secondary">
                        {index + 1}. {item}
                      </Typography>
                    );
                  })
                : null}
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
};
export default Form;
