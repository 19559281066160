import * as React from "react";
// import { useState } from "react";
import "./news.scss";
import "./newsindex.css";
// import { apiFailure } from "../../../logic";
// import apiClient from "../../../utils";
// import { useDispatch } from "react-redux";
// import { useEffect } from "react";
import { Card } from "antd";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { Paper } from "@mui/material";

const NewsCards = ({ data, pageNumber, search1 }) => {
  console.log("this data", pageNumber);
  const history = useHistory();

  // useEffect(() => {
  //   showNews();
  // }, []);
  // const [news, setNews] = useState("");
  // const dispatch = useDispatch();
  // let API_CONFIG = {
  //   NEWS: {
  //     method: "GET",
  //     url: "/news/?ticker=fierce_news",
  //     data: {},
  //   },
  // };
  // const showNews = () => {
  //   const apiPayload = { ...API_CONFIG.NEWS };
  //   console.log(apiPayload);
  //   apiClient(apiPayload)
  //     .then((res) => {
  //       console.log("Showing News", res);
  //       setNews(res.data.results);
  //     })
  //     .catch((err) => {
  //       console.log({ message: err });

  //       dispatch(apiFailure(err));
  //     });
  // };

  const handleNews = (title, news, date) => {
    const date1 = moment(date).local().format("dddd, MMMM Do YYYY, h:mm:ss a");
    history.push({
      pathname: "/newsplot/details",

      state: {
        update: true,
        title: title,
        date: date1,
        news: news,
        pageNumber: pageNumber,
        ticker: search1,
      },
    });
  };

  return (
    <div>
      {/* {console.log("news", data)} */}
      <div className="carddiv">
        {data?.data?.count != 0 ? (
          data?.data?.results?.map((row, idx) => {
            // console.log("ressss", row);
            return (
              <Paper style={{ borderRadius: "5%", border: "2px" }} key={row}>
                <Card
                  sx={{ display: "flex", maxHeight: "10%", cursor: "pointer" }}
                  style={{
                    color: "black",
                    paddingLeft: "1%",
                    marginBottom: "0.5%",
                    borderColor: "skyblue",
                    borderRadius: "5%",
                    fontSize: "100%",
                  }}
                  onClick={(e) =>
                    handleNews(row?.title, row?.news, row?.Date_updated)
                  }
                >
                  <div style={{ textAlign: "left", cursor: "pointer" }}>
                    {row.title}
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {/*   {moment(row.Date_updated).utc().format("YYYY-MM-DD")} */}
                    {row?.Date_updated != null && (
                      <i>
                        {
                          moment(row?.Date_updated)
                            .local()
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")
                          /* .format("YYYY-MM-DD , h:mm:ss a") */
                        }
                        {console.log(
                          "date updated",
                          row?.Date_updated,
                          "utc format",
                          moment(row?.Date_updated)
                            .local()
                            .format("dddd, MMMM Do YYYY, h:mm:ss a")
                        )}
                      </i>
                    )}{" "}
                  </div>
                </Card>
              </Paper>
            );
          })
        ) : (
          <Card
            style={{
              color: "black",
              paddingLeft: "1%",
              marginBottom: "0.5%",
              borderColor: "skyblue",
              borderRadius: "5%",
              fontSize: "100%",
              fontWeight: "bolder",
            }}
          >
            No Data Found
          </Card>
        )}
      </div>
      {/* <div className="paginateDiv">
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          variant="outlined"
          shape="rounded"
          color="primary"
          //page={pageNumber}
          count={renderPages()}
          // page={pageNumber}
          // onChange={handleChangePage}
        />
      </div> */}
    </div>
  );
};
export default NewsCards;
