import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";

const Advancers = ({ columnList, data }) => {
  const history = useHistory();
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#076281",
      color: theme.palette.common.white,
      //fontSize:12,
      // border: "1px solid black",
      // borderRadius: "5px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 18,
      // border: "1px solid black",
      // borderRadiusLeftTop: "10px",
      // borderRadiusRightTop: "10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      // border: "1px solid black",
      // borderRadius: "10px",
    },
  }));
  const renderHeadCols = () => {
    return columnList.map((row, index) => {
      if (index === 0) {
        return (
          <StyledTableCell
            key={`head_${row.label}_${index}`}
            align="center"
            className="coutom_headCol"
            style={{ position: "sticky", left: 0, zIndex: 10 }}
          >
            {row.label}
          </StyledTableCell>
        );
      } else {
        return (
          <StyledTableCell
            key={`head_${row.label}_${index}`}
            align="center"
            className="coutom_headCol"
          >
            {row.label}
          </StyledTableCell>
        );
      }
    });
  };

  const handleTradeViewClick = (symbol) => {
    history.push(`/company_info/?symbol=${symbol}`);
  };

  const renderCells = (row) => {
    return columnList.map((col, index) => {
      if (index === 0) {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells firstCellOFEachRow"
            onClick={() => handleTradeViewClick(row[col.key])}
            style={{ position: "sticky", left: 0, background: "white" }}
          >
            {/* col.customCell?.(row, index) || row?.[col.key] */}
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
          </StyledTableCell>
        );
      } else if (index === 3) {
        const i = row[col.key];
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells2"
          >
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? `${i}%`
              : "-"}
          </StyledTableCell>
        );
      } else {
        return (
          <StyledTableCell
            // className={col.tableCellClassName || ""}
            key={`row_${col.key}_${index}`}
            align="center"
            className="table_cells2"
          >
            {typeof row?.[col.key] === "number" ||
            typeof row?.[col.key] === "string"
              ? row[col.key]
              : "-"}
          </StyledTableCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (data.length > 0) {
      return data.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ));
    } else {
      return <h3>No data found</h3>;
    }
  };
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "40vh" }}
        className="Active_movers_table"
      >
        <Table
          sx={{ minWidth: 500 }}
          aria-label="customized sticky table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Advancers;
