import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
//import { useHistory } from "react-router-dom";
//import { session } from "../../utils";
// import Button from "../Button";
import apiCall from "./logic";
import "./login.scss";
import Modal from "@mui/material/Modal";
import { Typography, Box, Stack, Button } from "@mui/material";
import loginSideImage from "../assests/loginSidePopupImage.png";
import pbloginlogo from "../assests/pbloginlogo.svg";
import { ReactSVG } from "react-svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  margin: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

const Login = React.memo(function Login({
  open,
  setOpen,
  setOpenAccount,
  setEmailModal,
}) {
  //const history = useHistory();
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  //const [openVal, setOpenVal] = useState(stat);

  // const data = useSelector((state) => state.userData.data);
  // console.log(data);
  /* 
  useEffect(() => {
    if (session.checkIfLogin()) {
      history.push("/");
    }
  }, []); */
  /* 
  useEffect(() => {
    if (session.checkIfLogin()) {
      history.push("/");
    } 
    if (flag) {
      history.push("/");
    }
  }, [flag]); */

  /*   const onLogin = (e) => {
    e.preventDefault();
    const payload = {
      username,
      password,
    };
    dispatch(apiCall(payload));
  };

  const onUserNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
  };
  const onPasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
  }; */

  const handleUserModalOpen = () => {
    setOpen(false);
    setOpenAccount(true);
  };

  const handleClose = () => {
    setUserName("");
    setPassword("");
    setOpen(false);
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setUserName(value);
  };
  const handlePassword = (e) => {
    const { value } = e.target;
    // console.log("this is from changed password :", e);
    setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      username,
      password,
    };
    dispatch(apiCall(payload, setUserName, setPassword));
    //console.log({ username, password });
  };

  const handleForgetPassword = () => {
    setOpen(false);
    setEmailModal(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style}>
            <Stack direction="row">
              <Box sx={{ width: "50%", padding: "5%", margin: "0 auto" }}>
                <ReactSVG src={pbloginlogo} style={{ textAlign: "center" }} />
                <Typography
                  id="modal-modal-title"
                  gutterBottom
                  variant="h6"
                  component="h2"
                  style={{ fontWeight: "bold" }}
                >
                  LOGIN TO YOUR ACCOUNT
                </Typography>
                <Box
                  sx={{
                    padding: "4% 0",
                  }}
                >
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    Email*
                  </Typography>
                  <input
                    placeholder="write your username"
                    className="loginInputSection"
                    onChange={handleEmail}
                    value={username}
                    name="email"
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Typography sx={{ paddingLeft: "2%", fontWeight: "bold" }}>
                    Password*
                  </Typography>
                  <input
                    placeholder="write your password"
                    className="loginInputSection"
                    onChange={handlePassword}
                    value={password}
                    name="password"
                    autoComplete="off"
                    type="password"
                  />
                  <Typography
                    variant="subtitle2"
                    display="block"
                    style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      color: "#4F57AA",
                      marginRight: "10%",
                      cursor: "pointer",
                    }}
                    onClick={handleForgetPassword}
                  >
                    Forget password?
                  </Typography>
                </Box>
                <Box sx={{ margin: "3% 0" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    className="loginPageLoginButton"
                  >
                    Login
                  </Button>
                </Box>
                <Box>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    display="block"
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Not registered yet?
                    <span
                      onClick={handleUserModalOpen}
                      style={{ color: "#4F57AA", cursor: "pointer" }}
                    >
                      {" "}
                      Create an account
                    </span>{" "}
                  </Typography>
                </Box>
              </Box>

              <div style={{ width: "50%" }}>
                <img
                  src={loginSideImage}
                  alt="bioPharmaImage"
                  className="sideImageLogo"
                />
              </div>
            </Stack>
          </Box>
        </form>
      </Modal>
    </>
  );
});

export default Login;
