import React, { useState, useEffect } from "react";
import "./companyInfo.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stock from "../../assests/stock.svg";
import pricechart from "../../assests/piechart.svg";
import twitter from "../../assests/twitter.svg";
// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { TradingChart } from "./TradingChart";
import { FundamentalData } from "react-ts-tradingview-widgets";
import Modal from "@mui/material/Modal";
import apiClient from "../../../utils/apiClient";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../logic.js";
import { CompanyInfoTables } from "./CompanyInfoTables";
import { CompanyFundTable } from "./CompanyFundTable";
// import { NewsCards } from "../Newsfeeds/Newscards";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";

const COL_LIST = [
  {
    key: "catalyst_info",
    label: "Catalyst Info",
    tableCellClassName: "my-class",
  },
  {
    key: "analysed1",
    label: "Analysis Status",
  },
];

const COL_LIST1 = [
  {
    key: "catalyst_info",
    label: "Catalyst Info",
    tableCellClassName: "my-class",
  },
  {
    key: "analysed1",
    label: "Analysis Status",
  },
];

const FUND_COL_LIST = [
  {
    key: "name",
    label: "Fund Name",
  },
  {
    key: "date",
    label: "Date of reporting",
  },
  {
    key: "totalShares",
    label: "Ownership (%)",
  },
];

let API_CONFIG = {
  tradingViewSymbolData: {
    method: "GET",
    url: `/company_info/`,
  },
  companyUpcomingReadout: {
    method: "GET",
    url: `/calendar/upcomings/`,
  },
  companyHistoricalReadout: {
    method: "GET",
    url: `/calendar/historical/`,
  },
  companyFundHoldings: {
    method: "GET",
    url: `/fundholdings/`,
  },
};
let symbol;
const CompanyInfo = () => {
  const tradingViewSymbol = new URLSearchParams(window.location.search);
  symbol = tradingViewSymbol.get("symbol");
  //console.log(symbol);
  const [tradingViewData, setTradingViewData] = useState({ data: null });
  const [companyUpcomingReadouts, setCompanyUpcomingReadouts] = useState({
    data: null,
  });
  const [companyHistoricalReadouts, setCompanyHistoricalReadouts] = useState({
    data: null,
  });
  const [companyFundHoldings, setCompanyFundHoldings] = useState({
    data: null,
  });
  // Modal functionalities ==>
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("1"); //Tab State Mangement ==>

  const handleChange = (event, newValue) => {
    //tab Function
    setValue(newValue);
    //console.log(event);
  };

  const getTradingViewData = (symbol) => {
    const apiPayload = { ...API_CONFIG.tradingViewSymbolData };
    apiPayload.url += `?symbol=${symbol}`;
    apiClient(apiPayload)
      .then((res) => {
        setTradingViewData({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const getCompanyUpcomingReadouts = (symbol) => {
    const apiPayload = { ...API_CONFIG.companyUpcomingReadout };
    apiPayload.url += `?ticker=${symbol}`;
    apiClient(apiPayload)
      .then((res) => {
        setCompanyUpcomingReadouts({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const getCompanyHistoricalReadouts = (symbol) => {
    const apiPayload = { ...API_CONFIG.companyHistoricalReadout };
    apiPayload.url += `?search=${symbol}`;
    apiClient(apiPayload)
      .then((res) => {
        setCompanyHistoricalReadouts({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        setCompanyHistoricalReadouts(companyFundHoldings);
        dispatch(apiFailure(err));
      });
  };

  const getCompanyFundHoldings = (symbol) => {
    const apiPayload = { ...API_CONFIG.companyFundHoldings };
    apiPayload.url += `?ticker=${symbol}`;
    apiClient(apiPayload)
      .then((res) => {
        setCompanyFundHoldings({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  const history = useHistory();
  const showNews = () => {
    history.push({
      pathname: "/newsPlot",
      state: { ticker: symbol },
    });
  };

  useEffect(() => {
    getTradingViewData(symbol);
    getCompanyUpcomingReadouts(symbol);
    getCompanyHistoricalReadouts(symbol);
    getCompanyFundHoldings(symbol);
  }, []);
  // console.log(tradingViewData?.data?.data[0]);

  //Modal Container Style ==>
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      {tradingViewData?.data?.data[0] && (
        <div style={{ display: "flex", padding: "2%", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "1px 1px #D3D3D3",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
            onClick={() => {
              window.history.go(-1);
            }}
          >
            <ArrowBackIcon fontSize="small" />
            Back
          </Button>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
          >
            {tradingViewData.data.data[0].ticker} -{" "}
            {tradingViewData.data.data[0].company_name}{" "}
          </Typography>
        </div>
      )}
      {/* top heading container start */}
      {/* <div className="headingContainer">
       {tradingViewData?.data?.data[0] && <Typography variant="h6" component="h6" style={{textAlign: "left",paddingTop: "2%"}} > {tradingViewData.data.data[0].ticker} - {tradingViewData.data.data[0].company_name}</Typography>}
      </div> */}
      {/* top heading container end */}

      <div style={{ marginTop: "20px" }}>
        <Paper sx={{ padding: 2, width: "95%", margin: "auto" }}>
          {!tradingViewData?.data?.data[0] ? (
            "...loading"
          ) : (
            <Grid container gap={1} columns={19}>
              <Grid item xs={18} xl={6}>
                <Box>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{ fontWeight: "bold" }}
                  >
                    Market Price
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Paper
                      elevation={3}
                      sx={{ padding: "31px", width: "170px" }}
                    >
                      <div style={{ marginBottom: "5px" }}>
                        <img src={`${Stock}`} alt="stock" />
                      </div>
                      <Typography
                        variant="h5"
                        gutterBottom
                        component="h6"
                      >{`$${Number(tradingViewData.data.data[0].cmp).toFixed(
                        2
                      )}`}</Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        component="h6"
                        style={
                          Number(tradingViewData.data.data[0].return_1_week) > 0
                            ? { color: "green" }
                            : Number(
                                tradingViewData.data.data[0].return_1_week
                              ) <= 0
                            ? { color: "red" }
                            : { color: "black" }
                        }
                      >
                        {tradingViewData.data.data[0].return_1_week
                          ? `${tradingViewData.data.data[0].return_1_week}%`
                          : "-"}
                      </Typography>
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="h6"
                        style={{ color: "#BFBCBC" }}
                      >
                        Since Last Week
                      </Typography>
                    </Paper>
                    <Stack spacing={2}>
                      <Paper
                        elevation={3}
                        sx={{
                          padding: "22px 17px",
                          width: "200px",
                          cursor: "pointer",
                        }}
                        onClick={handleOpen}
                      >
                        <div>
                          <img
                            src={`${pricechart}`}
                            alt="piechart"
                            style={{ marginBottom: "2%", cursor: "pointer" }}
                            onClick={handleOpen}
                          />
                        </div>
                        <Typography
                          variant="body2"
                          gutterBottom
                          component="h6"
                          style={{ color: "#BFBCBC" }}
                        >
                          Company Fundamentals
                        </Typography>
                      </Paper>
                      <a
                        href={`https://twitter.com/search?q=%24${
                          symbol && symbol.toLowerCase()
                        }&src=typed_query`}
                      >
                        <Paper
                          elevation={3}
                          sx={{ padding: "22px 17px", width: "200px" }}
                        >
                          <div>
                            <img
                              src={`${twitter}`}
                              alt="piechart"
                              style={{ marginBottom: "2%" }}
                            />
                          </div>
                          <Typography
                            variant="body2"
                            gutterBottom
                            component="h6"
                            style={{ color: "#BFBCBC" }}
                          >
                            Stock mentions in twitter
                          </Typography>
                        </Paper>
                      </a>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={18} xl={6}>
                <Box>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{ fontWeight: "bold" }}
                  >
                    General Highlights
                  </Typography>
                  <Stack spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Paper elevation={3} sx={{ padding: 1, width: "200px" }}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          component="h6"
                          style={{ margin: "7px" }}
                        >
                          Market Cap ($)
                        </Typography>
                        <Typography variant="h5" gutterBottom component="h6">
                          {tradingViewData?.data?.data[0].mcap
                            ? new Intl.NumberFormat("en-US").format(
                                Math.trunc(
                                  Number(`${tradingViewData.data.data[0].mcap}`)
                                )
                              )
                            : "-"}
                        </Typography>
                      </Paper>
                      <Paper elevation={3} sx={{ padding: 1, width: "200px" }}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          component="h6"
                          style={{ margin: "7px" }}
                        >
                          Enterprise value ($)
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="h6"
                          style={
                            Number(tradingViewData?.data?.data[0].ev) > 0
                              ? { color: "green" }
                              : Number(tradingViewData?.data?.data[0].ev) < 0
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {tradingViewData?.data?.data[0].ev
                            ? new Intl.NumberFormat("en-US").format(
                                Math.trunc(
                                  Number(`${tradingViewData.data.data[0].ev}`)
                                )
                              )
                            : "-"}
                        </Typography>
                      </Paper>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Paper
                        elevation={3}
                        sx={{ padding: "8px 0 8px 8px", width: "200px" }}
                      >
                        <Typography variant="body2" gutterBottom component="h6">
                          Analyst Consensus
                          <span style={{ display: "block" }}>
                            Price Est.($)
                          </span>
                        </Typography>
                        <Typography variant="h5" component="h6">
                          {tradingViewData?.data?.data[0].analyst_target
                            ? `${Number(
                                tradingViewData.data.data[0].analyst_target
                              ).toFixed(1)}`
                            : "-"}
                        </Typography>
                      </Paper>
                      <Paper
                        elevation={3}
                        sx={{ padding: "8px 0 8px 8px", width: "200px" }}
                      >
                        <Typography variant="body2" component="h6">
                          Cash on books ($)
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ color: "#BFBCBC" }}
                        >
                          (As of MRQ)
                        </Typography>
                        <Typography variant="h5" component="h6">
                          {tradingViewData?.data?.data[0].cash
                            ? new Intl.NumberFormat("en-US").format(
                                Math.trunc(
                                  Number(`${tradingViewData.data.data[0].cash}`)
                                )
                              )
                            : "-"}
                        </Typography>
                      </Paper>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={18} xl={6}>
                <Box>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{ fontWeight: "bold" }}
                  >
                    Stock Performance
                  </Typography>
                  <Stack spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Paper
                        elevation={3}
                        sx={{ padding: "12px", width: "200px" }}
                      >
                        <Typography variant="body2" gutterBottom component="h6">
                          Last 1 months returns
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="h6"
                          style={
                            Number(
                              tradingViewData?.data?.data[0]?.return_1_month
                            ) > 0
                              ? { color: "green" }
                              : Number(
                                  tradingViewData?.data?.data[0].return_1_month
                                ) < 0
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {tradingViewData?.data?.data[0].return_1_month
                            ? `${Number(
                                tradingViewData.data.data[0].return_1_month
                              ).toFixed(1)}%`
                            : "-"}
                        </Typography>
                      </Paper>
                      <Paper
                        elevation={3}
                        sx={{ padding: "12px", width: "200px" }}
                      >
                        <Typography variant="body2" gutterBottom component="h6">
                          Last 3 months returns
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="h6"
                          style={
                            Number(
                              tradingViewData?.data?.data[0].return_3_month
                            ) > 0
                              ? { color: "green" }
                              : Number(
                                  tradingViewData?.data?.data[0].return_3_month
                                ) < 0
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {tradingViewData?.data?.data[0].return_3_month
                            ? `${Number(
                                tradingViewData?.data?.data[0].return_3_month
                              ).toFixed(1)}%`
                            : "-"}
                        </Typography>
                      </Paper>
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Paper
                        elevation={3}
                        sx={{ padding: "12px", width: "200px" }}
                      >
                        <Typography variant="body2" gutterBottom component="h6">
                          Last 6 months returns
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="h6"
                          style={
                            Number(
                              tradingViewData?.data?.data[0]?.return_6_month
                            ) > 0
                              ? { color: "green" }
                              : Number(
                                  tradingViewData?.data?.data[0]?.return_6_month
                                ) < 0
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {tradingViewData?.data?.data[0]?.return_6_month
                            ? `${Number(
                                tradingViewData?.data?.data[0].return_6_month
                              ).toFixed(1)}%`
                            : "-"}
                        </Typography>
                      </Paper>
                      <Paper
                        elevation={3}
                        sx={{ padding: "12px", width: "200px" }}
                      >
                        <Typography variant="body2" component="h6">
                          YTD Returns
                        </Typography>
                        <Typography
                          variant="h5"
                          gutterBottom
                          component="h6"
                          style={
                            Number(tradingViewData?.data?.data[0].return_ytd) >
                            0
                              ? { color: "green" }
                              : Number(
                                  tradingViewData?.data?.data[0].return_ytd
                                ) < 0
                              ? { color: "red" }
                              : { color: "black" }
                          }
                        >
                          {tradingViewData?.data?.data[0].return_ytd
                            ? `${Number(
                                tradingViewData?.data?.data[0].return_ytd
                              )}%`
                            : "-"}
                        </Typography>
                      </Paper>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          )}

          <div style={{ padding: "2%", height: "400px" }}>
            <TradingChart symbol={symbol} />
          </div>
          {/* modal start */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div id="modal-modal-description">
                <FundamentalData
                  colorTheme="light"
                  height={400}
                  width="100%"
                  symbol={symbol}
                ></FundamentalData>
              </div>
            </Box>
          </Modal>
          {/* modal end */}

          {/* compnay table start */}
          <Box
            sx={{
              width: "100%",
              typography: "body1",
              padding: "0 2%",
              height: "550px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="Upcoming Readouts" value="1" />
                  <Tab label="Historical Readouts" value="2" />
                  <Tab label="Institutional Holdings" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {companyUpcomingReadouts.data && (
                  <CompanyInfoTables
                    columnList={COL_LIST}
                    data={
                      companyUpcomingReadouts?.data?.data?.results
                        ? companyUpcomingReadouts.data.data.results
                        : ["No Upcoming Readouts Found"]
                    }
                    bol={true}
                  />
                )}
              </TabPanel>
              <TabPanel value="2">
                {companyHistoricalReadouts.data && (
                  <CompanyInfoTables
                    columnList={COL_LIST1}
                    data={
                      companyHistoricalReadouts?.data?.data.results
                        ? companyHistoricalReadouts.data.data.results
                        : ["No Historical Readouts Found"]
                    }
                  />
                )}
              </TabPanel>
              <TabPanel value="3">
                {companyFundHoldings.data && (
                  <CompanyFundTable
                    columnList={FUND_COL_LIST}
                    data={
                      companyFundHoldings?.data?.data
                        ? companyFundHoldings.data.data
                        : ["No Funds Data Found"]
                    }
                  />
                )}
              </TabPanel>
            </TabContext>
          </Box>
          <div>
            <Button variant="contained" onClick={showNews}>
              View News
            </Button>
          </div>
          {/* compnay table end */}
        </Paper>
      </div>
    </div>
  );
};
export default CompanyInfo;
