//Action Types ==>
const ADD_HISTORICAL_FILTERED_DATA = "ADD_HISTORICAL_FILTERED_DATA";
const REMOVE_HISTORICAL_FILTERED_DATA = "REMOVE_HISTORICAL_FILTERED_DATA";
const ADD_UPCOMING_FILTERED_DATA = "ADD_UPCOMING_FILTERED_DATA";
const REMOVE_UPCOMING_FILTERED_DATA = "REMOVE_UPCOMING_FILTERED_DATA";
const SIDEBAR_KEY = "SIDEBAR_KEY";
const ADD_USERNAME = "ADD_USERNAME";
const USER_STATUS = "USER_STATUS";

//Action ==>
export const userStatus = (payload) => {
  return {
    type: USER_STATUS,
    payload,
  };
};

export const storeUserName = (payload) => {
  return {
    type: ADD_USERNAME,
    payload,
  };
};

export const addHistoricalFilteredData = (payload) => {
  return {
    type: ADD_HISTORICAL_FILTERED_DATA,
    payload,
  };
};

export const removeHistoricalFilteredData = (payload) => {
  return {
    type: REMOVE_HISTORICAL_FILTERED_DATA,
    payload,
  };
};

export const addUpcomingFilteredData = (payload) => {
  return {
    type: ADD_UPCOMING_FILTERED_DATA,
    payload,
  };
};

export const removeUpcomingFilteredData = (payload) => {
  return {
    type: REMOVE_UPCOMING_FILTERED_DATA,
    payload,
  };
};

export const sideBarKey = (payload) => {
  return {
    type: SIDEBAR_KEY,
    payload,
  };
};

//Initial State ==>
const initialState = {
  isAdmin: null,
  username: null,
  historicalFilteredData: null,
  upComingFilteredData: null,
  sideBarKeyValue: null,
};

const globalStateReducer = (state = initialState, action) => {

  switch (action.type) {
    case USER_STATUS:
      return { ...state, isAdmin: action.payload };
    case ADD_USERNAME:
      return { ...state, username: action.payload };
    case ADD_HISTORICAL_FILTERED_DATA:
      return { ...state, historicalFilteredData: action.payload };
    case REMOVE_HISTORICAL_FILTERED_DATA:
      return { ...state, historicalFilteredData: action.payload };
    case ADD_UPCOMING_FILTERED_DATA:
      return { ...state, upComingFilteredData: action.payload };
    case REMOVE_UPCOMING_FILTERED_DATA:
      return { ...state, upComingFilteredData: action.payload };
    case SIDEBAR_KEY:
      return { ...state, sideBarKeyValue: action.payload };
    default:
      return state;
      
  }
};

export const appGlobalStateReducer = {
  globalState: globalStateReducer,
};
