import React, { useState, useEffect } from "react";
import AnalysedTable from "./AnalysedTable";
import apiClient from "../../../utils";
//import Form from "./Form";
import "./index.css";
import { Box, CircularProgress } from "@mui/material";
import { apiFailure } from "../../../logic.js";
import { useDispatch } from "react-redux";
let API_CONFIG = {
  analyzedResults: {
    method: "GET",
    url: "/analysed_catalysts/",
  },
};

const COL_LIST = [
  { key: "catalyst_infos", label: "Catalyst info" },
  { key: "drug_name", label: "Drug" },
  { key: "indication", label: "Indications" },

  {
    key: "risk_rewards",
    label: `Reward: 
  risk(%)`,
  },
  { key: "checklist_score_avg", label: "Check list score" },
  { key: "pos", label: "PoS" },
  { key: "moa", label: "MoA" },
  // { key: "prior_result", label: "Prior results" },
  { key: "nct_id", label: "NCT ID" },
  { key: "Analysis", label: "Analysis" },
 

];

const AnaLysedReadOuts = () => {
  const [analystData, setAnalystData] = useState({ data: null });
  const dispatch = useDispatch();
  const apiCall = () => {
    const apiPayload = { ...API_CONFIG.analyzedResults };

    //  apiPayload.url += `?${queryData}`;
    // setLoading(true);

    apiClient(apiPayload)
      .then((res) => {
        setAnalystData({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    // const searchParamQuery = new URLSearchParams(searchParam);
    //  apiCall(searchParamQuery.toString());
    apiCall();
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <h2
          style={{
            fontWeight: "bold",
            textAlign: "left",
            padding: "2% 0 0 2%",
          }}
        >
          Analyzed Readouts
        </h2>
        <p className="sub_heading">
          Upcoming readouts which have been analysed in-depth by analyst looking
          into biological and commercial aspects of the drug along with company
          financials
        </p>
      </div>
      {/* <h2 className="heading">Analyzed Readouts</h2>

      <p className="sub_heading">
        Upcoming readouts which have been analysed in-depth by analyst looking
        into biological and commercial aspects of the drug along with company
        financials
      </p> */}
      {/*      <Form /> */}

      {analystData.data ? (
        <div className="ana_container">
          <AnalysedTable
            columnList={COL_LIST}
            data={
              analystData?.data?.data
                ? analystData.data.data
                : ["No data found"]
            }
          />
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default AnaLysedReadOuts;
