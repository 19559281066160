import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../../../../utils";
import { TopHeader } from "./TopHeader";
//  import Styles from "./index.module.css";

// import Button from "@mui/material/Button";
import { MainInput } from "./MainInput";

import { Paper, Grid, Typography, Box, Button } from "@mui/material";
import { WACCoutput } from "./WACCoutput";
import { PipelineInputTable } from "./PipelineInputTable";
import { ResultTable } from "./ResultTable";
import { FeedbackPanel } from "./FeedbackPanel";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../../../logic.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const pipelineTableHeadings = [
  { key: "drugname", label: "Drug" },
  { key: "disease_name", label: "Disease" },
  { key: "phase", label: "Phase" },
  { key: "market_size", label: "Market Size (Mn)" },
  { key: "cagr", label: "CAGR" },
  { key: "pos_till_commercialisation", label: "PoS till Commercialization" },
  { key: "beg_market_share", label: "Beginning Market Share" },
  { key: "growth_till_peak", label: "Growth rate till peak" },
  { key: "declining_rate", label: "Declining rate" },
  { key: "commercialisation_year", label: "Year of commercialization" },
];

let API_CONFIG = {
  riskReward_Results: {
    method: "GET",
    url: "/riskreward/",
  },
};

const RiskReward = () => {
  const { hashid } = useParams();
  const [riskRewardData, setRiskRewardData] = useState({ data: null });
  const dispatch = useDispatch();

  const apiCall = (hashId) => {
    const apiPayload = { ...API_CONFIG.riskReward_Results };
    apiPayload.url += `?hashid=${hashId}`;

    apiClient(apiPayload)
      .then((res) => {
        setRiskRewardData({ data: res });
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    apiCall(hashid);
  }, [hashid]);

  return (
    <div>
      <Box
        sx={{
          padding: "2.5%",
        }}
      >
        <div style={{ display: "flex", padding: "2% 0", alignItems: "center" }}>
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#000",
              boxShadow: "1px 1px #D3D3D3",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
            onClick={() => {
              window.history.go(-1);
            }}
          >
            <ArrowBackIcon fontSize="small" />
            Back
          </Button>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
          >
            Reward Risk
          </Typography>
        </div>

        <Typography variant="h6" component="h2" align="left">
          Upside/Downside from the fair value
        </Typography>
      </Box>
      <Paper style={{ width: "95%", padding: "1.5%", margin: "auto" }}>
        <TopHeader
          headData={
            riskRewardData.data?.data ? riskRewardData.data.data[0] : []
          }
        />

        <Grid container spacing={3} sx={{ padding: "1.5%" }}>
          <Grid item xs={12} lg={6}>
            <MainInput
              inputData={
                riskRewardData.data?.data
                  ? riskRewardData.data.data[0].inputAssumption
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <WACCoutput
              WaccData={
                riskRewardData.data?.data
                  ? riskRewardData.data.data[0].waccs
                  : ""
              }
            />
          </Grid>
        </Grid>
        <PipelineInputTable
          pipilineHeading={pipelineTableHeadings}
          pipeLineData={
            riskRewardData.data?.data
              ? riskRewardData.data.data[0].pipeline_and_revenues
              : []
          }
        />
        <ResultTable
          resultTableData={
            riskRewardData.data?.data ? riskRewardData.data.data[0].result : []
          }
        />
        <FeedbackPanel />
      </Paper>
    </div>
  );
};
export default RiskReward;
