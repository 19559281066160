import React, { useState, /*useCallback,*/ useRef, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";

import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";

import "./index.css";

// import DownloadIcon from "@mui/icons-material/Download";
import apiClient from "../../../utils/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { apiFailure } from "../../../logic.js";
import { useHistory } from "react-router-dom";
import { BarDiagram1 } from "./BarDiagram";
import { BarGrapghFilter } from "./BarGraphFilter";
import { removeHistoricalFilteredData } from "../GlobalStateReducer/GlobalState";
import AdvanceSearchModal from "./AdvanceSearchModal";
import CustomizedTableHistorical from "./HistoricalNewTable";
import { Pagination } from "@mui/material";
import startGetHistoricalRedoutesApiCall from "./logic";
import { Form } from "antd";
// import CustomizedTableHistorical from "./HistoricalNewTable";
// import {removeHistoricalFilteredData} from "../GlobalStateReducer/GlobalState";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

let API_CONFIG = {
  historicalReadoutsData: {
    method: "GET",
    url: "/calendar/historical/",
    data: {},
  },
  historicalReadoutsDataDownload: {
    method: "GET",
    url: "/calendar/historicalDownload/",
    data: {},
  },
  changeSentiment: {
    method: "PUT",
    url: "/calendar/updatesentiment",
    data: {},
  },

  userStatus: {
    method: "GET",
    url: "/userstatus/",
  },
  historicalGraphData: {
    method: "GET",
    url: "/calendar/historicalgraph/",
    data: {},
  },
  historicalFilteredGraphData: {
    method: "POST",
    url: "/calendar/filtergraph/",
    data: {},
  },
};

// let isAdmin = { status: null };

export const HistoricalReadoutsGrid = () => {
  const gridRef = useRef();

  const [pageNumber, setPageNumber] = React.useState(1);

  // const containerStyle = useMemo(()=>({width:"100%", height:"100%",}),[]);
  // const gridStyle = useMemo(()=>({width:"100%",height:"87.5%",padding:"0 2%"}),[]);
  const dispatch = useDispatch();
  const history = useHistory();
  // const isAdmin = useSelector((state)=>state.globalState.isAdmin);
  //const [isAdmin,setIsAdmin] = useState({status:null});
  const historicalFilteredData = useSelector(
    (state) => state.globalState.historicalFilteredData
  );

  //Bar Filter State ==>
  const [dbr, setDbr] = useState(7);
  const [dar, setDar] = useState(2);

  //Bar varaible declaration ==>
  const [copyOfTableData, setCopyOfTableData] = useState([]);
  const [copyOfTableData1, setCopyOfTableData1] = useState([]);
  const [pastWeekIndex1to0Avg, setPastWeekIndex1to0Avg] = useState(null);
  const [pastWeekStock1to0Avg, setPastWeekStock1to0Avg] = useState(null);
  const [pastWeekNasdaq1to0Avg, setPastWeekNasdaq1to0Avg] = useState(null);

  const [pastMonthIndex1to0Avg, setPastMonthIndex1to0Avg] = useState(null);
  const [pastMonthStock1to0Avg, setPastMonthStock1to0Avg] = useState(null);
  const [pastMonthNasdaq1to0Avg, setPastMonthNasdaq1to0Avg] = useState(null);

  const [sinceFeb21Index1to0Avg, setSinceFeb21Index1to0Avg] = useState(null);
  const [sinceFeb21Stock1to0Avg, setSinceFeb21Stock1to0Avg] = useState(null);
  const [sinceFeb21Nasdaq1to0Avg, setSinceFeb21Nasdaq1to0Avg] = useState(null);

  const [beforeFeb21Index1to0Avg, setBeforeFeb21Index1to0Avg] = useState(null);
  const [beforeFeb21Stock1to0Avg, setBeforeFeb21Stock1to0Avg] = useState(null);
  const [beforeFeb21Nasdaq1to0Avg, setBeforeFeb21Nasdaq1to0Avg] =
    useState(null);

  const [count1, setCount1] = useState(null);
  const [count2, setCount2] = useState(null);
  const [count3, setCount3] = useState(null);
  const [count4, setCount4] = useState(null);

  const [graphDataCount, setGraphDataCount] = useState();
  const [reRunUseEffect, setReRunUseEffect] = useState(false);
  const [filter, setFilter] = useState({ flag: false, data: null });

  const [option, setOption] = useState([]); // Ta
  const [optionMcap, setOptionMcap] = useState([]);
  const [optionStage, setOptionStage] = useState([]);
  const [optionSentiment, setOptionSentiment] = useState([]);
  //const dateNow = new Date().toISOString().split("T")[0].split("-");
  const [startDate, setStartDate] = useState(); // from date
  const [endDate, setEndDate] = useState(); //  to date
  const [search, setSearch] = useState();
  const [TA, setTA] = useState([]);
  const [isAdmin, setIsAdmin] = useState({ status: null });
  const handleCompanyNameClick = (name) => {
    history.push(`/company_info/?symbol=${name}`);
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2).toString();
  };

  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };

  // const updateSentimentColumn = (id,value) => {
  //      const rowNode = gridRef.current.api.getRowNode(id);
  //            rowNode.setDataValue("Sentiment", value);
  //            console.log(rowNode,"from function");
  // };
  // const { data: newData } = useSelector((state) => state.HistoricalRedoutes);

  const handleSentimentClick = (params, value) => {
    const id = params.data._id;
    // console.log(id, value, "sentiment click");
    const apiPayload = { ...API_CONFIG.changeSentiment };
    apiPayload.data = {
      id: id,
      sentiment: value,
    };
    apiClient(apiPayload)
      .then((res) => {
        if (res.data === "Field_updated") {
          setReRunUseEffect((current) => !current);
          setHistoricalReadoutRowData({ data: null });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong"); //
        dispatch(apiFailure(err));
      });
  };

  const [, /* historicalReadoutRowData */ setHistoricalReadoutRowData] =
    useState({
      data: null,
    });
  const COL_LIST = [
    {
      key: "ticker",
      label: "Ticker",
      tableCellClassName: "my-class",
    },
    { key: "company_name", label: "Company Name" },
    { key: "drug_name", label: "Drugs" },
    { key: "indication", label: "Disease" },
    {
      key: "label",
      label: "Stage",
      // customCell: (row, index) => <div className="test-div">{row.fat}</div>,
    },
    { key: "TA", label: "Therapeutic Area" },
    { key: "Sentiment", label: "Sentiment" },
    { key: "mcap", label: "Market Cap ($ Mn)" },
    { key: "date", label: "Catalyst Date" },
    { key: "note", label: "Catalyst", id: "pk_id" },
  ];
  const [historical, setHistorical] = useState({ data: null });
  const getHistoricalData = () => {
    const apiPayload = { ...API_CONFIG.historicalReadoutsData };
    apiClient(apiPayload)
      .then((res) => {
        setCopyOfTableData(res.data); //making copy of Whole Table Data
        setCopyOfTableData1(res.data); //making copy of Whole Table Data again for reset==>
        setHistorical({ data: res.data }); //  initial data
        // console.log("call", res.data);
        setHistoricalReadoutRowData({ data: res });
        // console.log("data from historical readouts screen", res);
        //GraphDataCalculation(res.data, dbr, dar); //
        // console.log("After function call");
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };
  const [form] = Form.useForm(); // reset functinality
  const resetModal = () => {
    setOptionMcap([]);
    setOptionStage([]);
    setOptionSentiment([]);
    form.resetFields();
    setSearch();
    setTA();
    setStartDate();
    setEndDate();

    //setHistorical(historical);    //

    // console.log("this message is from resetAll function ..............");
  };

  const resetAll = () => {
    // setOptionMcap([]);
    // setOptionStage([]);
    // setOptionSentiment([]);
    // form.resetFields();
    //setHistorical(historical); M   //
    resetModal();
    getHistoricalData(); // table change  - going into loop
    setFilter({ flag: false, data: null });
    //  setHistorical([]);
    setDar(2);
    setDbr(7);
    getHistoricalGraphData(); ///  graph chnage

    // console.log("this message is from resetAll function ..............");
  };

  const getHistoricalFilteredDataFromCompanyScreener = (companyName) => {
    const apiPayload = { ...API_CONFIG.historicalReadoutsData };
    apiPayload.url += `filter`;
    apiPayload.method = "POST"; ///
    apiPayload.data = companyName;

    apiClient(apiPayload)
      .then((res) => {
        // setCopyOfTableData(res.data); //making copy of Whole Table Data
        // setCopyOfTableData1(res.data); //making copy of Whole Table Data again for reset==>
        // setHistorical({ data: res.data });
        // console.log("graph inside filter ", filter);
        setFilter({ flag: true, data: res.data });
        // console.log("graph inside filter after data set ", filter);

        // setHistoricalReadoutRowData({ data: res });
        // console.log("filtered data from historical readouts screen", res);
        // GraphDataCalculation(res.data);
        // console.log("After function call");
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };

  const getHistoricalFilteredGraphDataFromCompanyScreener = (companyName) => {
    const apiPayload = { ...API_CONFIG.historicalFilteredGraphData };
    apiPayload.url += `?fromday=${dbr}&today=${dar}`;
    apiPayload.method = "POST"; ///
    apiPayload.data = companyName;

    apiClient(apiPayload)
      .then((res) => {
        // setCopyOfTableData(res.data); //making copy of Whole Table Data
        // setCopyOfTableData1(res.data); //making copy of Whole Table Data again for reset==>
        // setHistorical({ data: res.data });
        // console.log("graph inside filter ", filter);
        // setFilter({ flag: true, data: res.data });
        // console.log("graph inside filter after data set ", filter);

        // setHistoricalReadoutRowData({ data: res });
        // console.log("filtered data from historical readouts screen", res);
        GraphDataCalculation(res.data);
        dispatch(removeHistoricalFilteredData(null));
        // console.log("After function call");
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };

  const getUserStats = () => {
    const apiPayload = { ...API_CONFIG.userStatus };
    apiClient(apiPayload)
      .then((res) => {
        if (res.data.is_staff) {
          setIsAdmin({ status: "YES" });
          // isAdmin = { status: "YES" };
        } else {
          setIsAdmin({ status: "NO" });
          // isAdmin = { status: "NO" };
        }
        // console.log(res.data.is_staff, "Status from api");
      })
      .catch((err) => {
        console.log(err);
        dispatch(apiFailure(err));
      });
  };

  //
  //calling graph api
  const getHistoricalGraphData = () => {
    const apiPayload = { ...API_CONFIG.historicalGraphData };
    apiPayload.url += `?fromday=${dbr}&today=${dar}`;
    apiClient(apiPayload)
      .then((res) => {
        setCopyOfTableData(res.data); //making copy of Whole Table Data
        setCopyOfTableData1(res.data); //making copy of Whole Table Data again for reset==>

        GraphDataCalculation(res?.data);
      })
      .catch((err) => {
        console.log({ message: err });
        setColumnDefs([...columnDefs]);
        dispatch(apiFailure(err));
      });
  };
  // useEffect(() => {
  //   getHistoricalGraphData();
  // }, [dar, dbr]); //

  useEffect(() => {
    const searchParam = new URLSearchParams(history.location.search);

    if (dar && dbr) {
      // console.log("search", search);
      if (search && search !== "") {
        // console.log("search undefined");
        searchParam.set("search", search);
      } else {
        searchParam.delete("search");
      }

      if (TA && TA.length > 0) {
        searchParam.set("ta", TA.join(","));
      } else {
        searchParam.delete("ta");
      }
      // console.log("search param", searchParam.toString());
      if (startDate && startDate !== "") {
        searchParam.set("startdate", startDate);
      } else {
        searchParam.delete("startdate");
      }
      if (endDate && endDate !== "") {
        searchParam.set("enddate", endDate);
      } else {
        searchParam.delete("enddate");
      }

      if (optionMcap && optionMcap.length > 0) {
        let marketCapStart = "";
        let marketCapEnd = "";
        for (var i = 0; i < optionMcap.length; i++) {
          const values = optionMcap[i].split("-");
          marketCapStart += values[0] + ",";
          marketCapEnd += values[1] + ",";
          // values= [200,2000]
        }
        marketCapStart = marketCapStart.slice(0, marketCapStart.length - 1);
        marketCapEnd = marketCapEnd.slice(0, marketCapEnd.length - 1);
        // searchParam.set("marketCapStart", optionMCAP.join(","));
        searchParam.set("marketCapStart", marketCapStart);
        searchParam.set("marketCapEnd", marketCapEnd);
      } else {
        searchParam.delete("marketCapStart");
        searchParam.delete("marketCapEnd");
      }
      if (optionStage && optionStage.length > 0) {
        // console.log("marketCap values from searchparams : ", optionStage);
        searchParam.set("stage", optionStage.join(","));
      } else {
        searchParam.delete("stage");
      }
      if (optionSentiment && optionSentiment.length > 0) {
        searchParam.set("sentiment", optionSentiment.join(","));
      } else {
        searchParam.delete("sentiment");
      }
      getHistoricalFilteredGraphData(searchParam.toString());
    }
  }, [
    TA,
    dar,
    dbr,
    // dispatch,
    endDate,
    // history.location.search,
    optionMcap,
    optionSentiment,
    optionStage,
    search,
    startDate,
  ]);

  const getHistoricalFilteredGraphData = (params) => {
    const apiPayload = { ...API_CONFIG.historicalGraphData };
    apiPayload.url += `?${params}&fromday=${dbr}&today=${dar}`;
    apiClient(apiPayload)
      .then((res) => {
        if (res.data) {
          setCopyOfTableData(res.data);
          setCopyOfTableData1(res.data);
          GraphDataCalculation(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    const searchParam = new URLSearchParams(history.location.search);
    if (search && search !== "") {
      searchParam.set("search", search);
    } else {
      searchParam.delete("search");
    }

    if (TA && TA.length > 0) {
      searchParam.set("ta", TA.join(","));
    } else {
      searchParam.delete("ta");
    }
    if (startDate && startDate !== "") {
      searchParam.set("startdate", startDate);
    } else {
      searchParam.delete("startdate");
    }
    if (endDate && endDate !== "") {
      searchParam.set("enddate", endDate);
    } else {
      searchParam.delete("enddate");
    }

    if (optionMcap && optionMcap.length > 0) {
      let marketCapStart = "";
      let marketCapEnd = "";
      for (var i = 0; i < optionMcap.length; i++) {
        const values = optionMcap[i].split("-");
        marketCapStart += values[0] + ",";
        marketCapEnd += values[1] + ",";
      }
      marketCapStart = marketCapStart.slice(0, marketCapStart.length - 1);
      marketCapEnd = marketCapEnd.slice(0, marketCapEnd.length - 1);

      searchParam.set("marketCapStart", marketCapStart);
      searchParam.set("marketCapEnd", marketCapEnd);
    } else {
      searchParam.delete("marketCapStart");
      searchParam.delete("marketCapEnd");
    }

    if (optionStage && optionStage.length > 0) {
      searchParam.set("stage", optionStage.join(","));
    } else {
      searchParam.delete("stage");
    }
    if (optionSentiment && optionSentiment.length > 0) {
      searchParam.set("sentiment", optionSentiment.join(","));
    } else {
      searchParam.delete("sentiment");
    }
    const apiPayload = { ...API_CONFIG.historicalReadoutsData };
    apiPayload.url += `?${searchParam.toString()}`;

    apiClient(apiPayload)
      .then((res) => {
        if (res.data) {
          setHistorical({ data: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(apiFailure(err));
      });
  }, [
    TA,
    endDate,
    optionMcap,
    optionSentiment,
    optionStage,
    search,
    startDate,
  ]);

  var dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    buttons: ["clear", "reset", "apply"],
    closeOnApply: true,
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Ticker",
      field: "ticker",

      sortable: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "Not contains",
          "Equals",
          "Not equal",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellRendererFramework: function (params) {
        return (
          <a onClick={() => handleCompanyNameClick(params.value)}>
            {params?.value}
          </a>
        );
      },
      pinned: "left",
      cellClass: "tickerCell",
      maxWidth: 130,
    },
    {
      headerName: "Company Name",
      field: "company_name",
      minWidth: 220,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      pinned: "left",
      cellClass: "companyCell",
      headerClass: "companyHeader1",
    },
    {
      headerName: "Drug",
      field: "drug_name",
      minWidth: 220,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellClass: "companyCell",
      headerClass: "companyHeader1",
    },
    {
      headerName: "Disease",
      field: "indication",
      minWidth: 250,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellClass: "companyCell",
      headerClass: "companyHeader1",
    },
    {
      headerName: "Stage",
      field: "label",
      minWidth: 150,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellClass: "companyCell",
    },
    {
      headerName: "TA",
      field: "TA",
      minWidth: 190,
      filter: "agTextColumnFilter",
      sortable: true,
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },

      cellClass: "companyCell",
    },
    {
      headerName: "Sentiment",
      field: "Sentiment",
      sortable: true,
      minWidth: 150,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: [
          "contains",
          "notContains",
          "equals",
          "notEqual",
          "startsWith",
          "endsWith",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      cellRendererFramework: function (params) {
        return params.value ? (
          params.value
        ) : isAdmin.status === "YES" ? (
          <div>
            <a onClick={() => handleSentimentClick(params, "Positive")}>P</a>/
            <a onClick={() => handleSentimentClick(params, "Negative")}>N</a>/
            <a onClick={() => handleSentimentClick(params, "Inconclusive")}>
              I
            </a>
          </div>
        ) : (
          params.value
        );
      },
    },
    {
      headerName: "Current Mcap (Mn)",
      field: "mcap",
      sortable: true,
      minWidth: 200,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: [
          "inRange",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
        ],
        buttons: ["clear", "reset", "apply"],
        closeOnApply: true,
      },
      valueFormatter: currencyFormatter,
    },

    {
      headerName: "Catalyst Date",
      field: "date",
      filter: "agDateColumnFilter",
      filterParams: dateFilterParams,
      minWidth: 170,
      sortable: true,
    },

    {
      headerName: "Catalyst",
      field: "note",
      minWidth: 450,
      headerClass: "companyHeader",
      cellClass: "companyCell",
    },
  ]);
  console.log("nnnnnnnnnnnnnnew", copyOfTableData1);

  useEffect(() => {
    getUserStats();
    if (historicalFilteredData) {
      setFilter({ ...filter, flag: true });
      getHistoricalFilteredDataFromCompanyScreener(historicalFilteredData);
      getHistoricalFilteredGraphDataFromCompanyScreener(historicalFilteredData);
    } else {
      // console.log("rendered");
      getHistoricalData();
      // getHistoricalGraphData();
    }

    // console.log("useeffect called", historical, newData);
  }, [reRunUseEffect]);

  useEffect(() => {
    dispatch(startGetHistoricalRedoutesApiCall());
  }, []);

  // OLD RESET FUNCTIONALITY
  // const handleResetAll = useCallback(() => {
  //   gridRef.current.api.setFilterModel(null); //Only for filter reset ==>
  //   setCopyOfTableData([...copyOfTableData1]);
  // });

  const PageSize = 100;

  const handleChangePage = (_event, pageNo) => {
    const queryParam = new URLSearchParams(history.location.search);

    const apiConfig = { ...API_CONFIG.historicalReadoutsData };

    // console.log("page", pageNo);
    // console.log("page_size", PageSize);
    queryParam.set("page", pageNo);
    queryParam.set("page_size", PageSize);
    apiConfig.url += `?${queryParam}`;
    //apiCall(queryParam.toString());
    setPageNumber(pageNo);
    apiClient(apiConfig)
      .then((res) => {
        if (filter.flag) {
        } else {
          // console.log("default values : ", res.data);
          setHistorical({ data: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong");
        dispatch(apiFailure(err));
      });
  };

  const renderPages = () => {
    let totalItems = filter.flag
      ? filter?.data?.count
      : historical?.data?.count;

    if (totalItems > 0) {
      let pages = Math.ceil(totalItems / PageSize);
      return pages;
    }
  };

  //Download CSV
  // const onBtnCsvExport = useCallback(() => {
  //   gridRef.current.api.exportDataAsCsv();
  // }, []);

  // -----------------------------------download functionality--------------------------

  // const searchParam = useSelector(
  //   (state) => state.HistoricalRedoutes.searchParam
  // );

  // const searchParam = history.location.search;

  const onBtnCsvExport = () => {
    // gridRef.current.api.exportDataAsCsv();
    onCsvDownload();
  };
  const onCsvDownload = () => {
    const searchParam = new URLSearchParams(history.location.search);
    // console.log("search", search);
    if (search && search !== "") {
      // console.log("search undefined");
      searchParam.set("search", search);
    } else {
      searchParam.delete("search");
    }

    if (TA && TA.length > 0) {
      searchParam.set("ta", TA.join(","));
    } else {
      searchParam.delete("ta");
    }
    // console.log("search param", searchParam.toString());
    if (startDate && startDate !== "") {
      searchParam.set("startdate", startDate);
    } else {
      searchParam.delete("startdate");
    }
    // console.log("search param after start date : ", searchParam.toString());
    if (endDate && endDate !== "") {
      searchParam.set("enddate", endDate);
    } else {
      searchParam.delete("enddate");
    }

    if (optionMcap && optionMcap.length > 0) {
      let marketCapStart = "";
      let marketCapEnd = "";
      for (var i = 0; i < optionMcap.length; i++) {
        const values = optionMcap[i].split("-");
        marketCapStart += values[0] + ",";
        marketCapEnd += values[1] + ",";
      }
      marketCapStart = marketCapStart.slice(0, marketCapStart.length - 1);
      marketCapEnd = marketCapEnd.slice(0, marketCapEnd.length - 1);

      searchParam.set("marketCapStart", marketCapStart);
      searchParam.set("marketCapEnd", marketCapEnd);
    } else {
      searchParam.delete("marketCapStart");
      searchParam.delete("marketCapEnd");
    }
    // console.log("option stage ", optionStage);
    if (optionStage && optionStage.length > 0) {
      // console.log("marketCap values from searchparams : ", optionStage);
      searchParam.set("stage", optionStage.join(","));
    } else {
      searchParam.delete("stage");
    }
    if (optionSentiment && optionSentiment.length > 0) {
      searchParam.set("sentiment", optionSentiment.join(","));
    } else {
      searchParam.delete("sentiment");
    }
    apiCallForCsvDownload(searchParam.toString())
      .then((response) => {
        // Download the CSV file
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "HistoricalReadouts.csv";
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("CSV download error:", error);
      });
  };

  const apiCallForCsvDownload = (queryData) => {
    const apiPayload = { ...API_CONFIG.historicalReadoutsDataDownload };
    apiPayload.url += `?${queryData}`;

    // Return the axios.get promise
    return apiClient(apiPayload);
  };
  // const apiCallForCsvDownload = (queryData) => {
  //   const apiPayload = { ...API_CONFIG.historicalReadoutsDataDownload }; //
  //   apiPayload.url += `?${queryData}`;
  //   console.log("//////////////////", apiPayload);
  //   apiClient(apiPayload)
  //     .then((res) => {
  //       // console.log(res);
  //       exportToCsv(res.data.results);
  //     })
  //     .catch((err) => {
  //       dispatch(apiFailure(err));
  //     });
  // };

  // const downloadFile = ({ data, fileName, fileType }) => {
  //   // Create a blob with the data we want to download as a file
  //   const blob = new Blob([data], { type: fileType });
  //   // Create an anchor element and dispatch a click event on it
  //   // to trigger a download
  //   const a = document.createElement("a");
  //   a.download = fileName;
  //   a.href = window.URL.createObjectURL(blob);
  //   const clickEvt = new MouseEvent("click", {
  //     view: window,
  //     bubbles: true,
  //     cancelable: true,
  //   });
  //   a.dispatchEvent(clickEvt);
  //   a.remove();
  // };
  // const exportToCsv = (apiData) => {
  //   // Headers for each column
  //   let headers = [
  //     "Ticker, Company Name, Drug, Disease, Stage, Therapeutic Area, Sentiment, Market Cap, Catalyst Date, Catalyst",
  //   ];

  //   // Convert users data to a csv
  //   let usersCsv = apiData.reduce((acc, user) => {
  //     const {
  //       ticker,
  //       company_name,
  //       drug_name,
  //       indication,
  //       label,
  //       TA,
  //       Sentiment,
  //       mcap,
  //       date,
  //       note,
  //     } = user;
  //     acc.push(
  //       [
  //         `"${ticker}"`,
  //         `"${company_name}"`,
  //         `"${drug_name}"`,
  //         `"${indication}"`,
  //         `"${label}"`,
  //         `"${TA}"`,
  //         `"${Sentiment}"`,
  //         `"${mcap}"`,
  //         `"${date}"`,
  //         `"${note}"`,
  //       ].join(",")
  //     );
  //     return acc;
  //   }, []);

  //   downloadFile({
  //     data: [...headers, ...usersCsv].join("\n"),
  //     fileName: "HistoricalReadouts.csv",
  //     fileType: "text/csv",
  //   });
  // };

  function GraphDataCalculation(data) {
    console.log("graph data", data);
    setGraphDataCount(data?.total_count);
    // console.log("graph counts of graph elements : ",data?.total_count);
    setCount1(data?.pastweek_count);

    setPastWeekIndex1to0Avg(data?.readout_in_past_week_xbi);

    setPastWeekStock1to0Avg(data?.readout_in_past_week_stock);

    setPastWeekNasdaq1to0Avg(data?.readout_in_past_week_nasdaq);

    setCount2(data?.pastmonth_count);

    setPastMonthIndex1to0Avg(data?.readout_in_past_month_xbi);

    setPastMonthStock1to0Avg(data?.readout_in_past_month_stock);

    setPastMonthNasdaq1to0Avg(data?.readout_in_past_month_nasdaq);

    setCount3(data?.sincefeb_count);

    setSinceFeb21Index1to0Avg(data?.readout_sincefeb_xbi);

    setSinceFeb21Stock1to0Avg(data?.readout_sincefeb_stock);

    setSinceFeb21Nasdaq1to0Avg(data?.readout_sincefeb_nasdaq);

    setCount4(data?.beforefeb_count);

    setBeforeFeb21Index1to0Avg(data?.readout_beforefeb_xbi);

    setBeforeFeb21Stock1to0Avg(data?.readout_beforefeb_stock);

    setBeforeFeb21Nasdaq1to0Avg(data?.readout_beforefeb_nasdaq);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          flexWrap: "nowrap",
        }}
      >
        <div style={{ width: "47.5%", padding: "0 2%", marginTop: "2%" }}>
          <div>
            <h2 style={{ textAlign: "left", fontWeight: "bolder" }}>
              Historical Readouts
            </h2>
            <Typography
              style={{ textAlign: "left", margin: "2% 0", color: "#808080" }}
            >
              Details of all historicals readouts.
            </Typography>
          </div>
          <div style={{ marginTop: "2%" }}>
            <Typography
              variant="h6"
              component="h3"
              gutterBottom
              style={{ textAlign: "left", marginBottom: "2%" }}
            >
              Choose Return Timeframe
            </Typography>
            <BarGrapghFilter
              dbr={dbr}
              dar={dar}
              setDbr={setDbr}
              setDar={setDar}
              // GraphDataCalculation={GraphDataCalculation}
              copyOfTableData={copyOfTableData}
            />
            <ul style={{ marginTop: "3%", textAlign: "left" }}>
              <li>
                <Typography style={{ color: "#808080", fontSize: "0.8rem" }}>
                  The Chart shows avg. returns of readouts happened in 4
                  different time periods i.e. In Past week, In Past month,
                  Readouts since Feb’21 (that is when the Biotech Drawdown
                  started) and all Historical readouts from start of 2017 till
                  Feb’21.
                </Typography>
              </li>
              <li>
                <Typography style={{ color: "#808080", fontSize: "0.8rem" }}>
                  The Index returns are avg. returns of $xbi (S&P Biotech ETF)
                  in same date ranges as of the readouts.{" "}
                </Typography>
              </li>
            </ul>
            <br></br>
            <div className="buttonNmodal">
              <div className="buttonNmodal1">
                <AdvanceSearchModal
                  setReRunUseEffect={setReRunUseEffect}
                  option={option}
                  setOption={setOption}
                  setOptionMcap={setOptionMcap}
                  optionMcap={optionMcap}
                  setOptionStage={setOptionStage}
                  optionStage={optionStage}
                  setOptionSentiment={setOptionSentiment}
                  optionSentiment={optionSentiment}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  resetAll={resetModal}
                  setSearch={setSearch}
                  setTA={setTA}
                  form={form}
                />
              </div>
              <div className="buttonNmodal2">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#076281",
                    color: "#fff",
                    fontWeight: "bold",
                    // marginLeft: "2%",
                    fontSize: "0.85vw",
                    marginRight: "1%",
                    marginLeft: "1%",
                  }}
                  onClick={resetAll}
                >
                  Reset All Filters {/* of Table */}
                </Button>
                <Button
                  // variant="contained"
                  style={{
                    backgroundColor: "#076281",
                    color: "#fff",
                    fontWeight: "bold",
                    marginLeft: "1%",
                    fontSize: "0.85vw",
                  }}
                  onClick={onBtnCsvExport}
                >
                  {/* <DownloadIcon></DownloadIcon> */}
                  Download
                </Button>
              </div>
            </div>
          </div>
        </div>

        {beforeFeb21Nasdaq1to0Avg ? (
          <div
            style={{
              width: "52.5%",
              margin: "2% 2% 0 0",
              backgroundColor: "#fff",
            }}
          >
            <BarDiagram1
              bardata={{
                pastWeekIndex1to0Avg,
                pastWeekStock1to0Avg,
                pastMonthIndex1to0Avg,
                pastMonthStock1to0Avg,
                sinceFeb21Index1to0Avg,
                sinceFeb21Stock1to0Avg,
                beforeFeb21Index1to0Avg,
                beforeFeb21Stock1to0Avg,
                copyOfTableData,
                count1,
                count2,
                count3,
                count4,
                pastWeekNasdaq1to0Avg,
                pastMonthNasdaq1to0Avg,
                sinceFeb21Nasdaq1to0Avg,
                beforeFeb21Nasdaq1to0Avg,
              }}
            />
            {graphDataCount && (
              <div style={{ backgroundColor: "#fff", paddingBottom: "0.5%" }}>
                <Typography
                  variant="subtitle2"
                  component="h3"
                  gutterBottom
                  style={{
                    textAlign: "left",
                    paddingLeft: "5%",
                    color: "#000",
                  }}
                >
                  Count of Readouts :
                  <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    {graphDataCount}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="h3"
                  gutterBottom
                  style={{
                    textAlign: "left",
                    paddingLeft: "5%",
                    color: "#808080",
                  }}
                >
                  * Data in graph is on the basis of filters applied on table
                  below{" "}
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "52.5%",
              margin: "2% 2% 0 0",
              backgroundColor: "#fff",
            }}
          >
            <BarDiagram1
              bardata={{
                pastWeekIndex1to0Avg,
                pastWeekStock1to0Avg,
                pastMonthIndex1to0Avg,
                pastMonthStock1to0Avg,
                sinceFeb21Index1to0Avg,
                sinceFeb21Stock1to0Avg,
                beforeFeb21Index1to0Avg,
                beforeFeb21Stock1to0Avg,
                copyOfTableData,
                count1,
                count2,
                count3,
                count4,
                pastWeekNasdaq1to0Avg,
                pastMonthNasdaq1to0Avg,
                sinceFeb21Nasdaq1to0Avg,
                beforeFeb21Nasdaq1to0Avg,
              }}
            />

            <div style={{ backgroundColor: "#fff", paddingBottom: "0.5%" }}>
              <Typography
                variant="subtitle2"
                component="h3"
                gutterBottom
                style={{ textAlign: "left", paddingLeft: "5%", color: "#000" }}
              >
                Count of Readouts :
                <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  {graphDataCount}
                </span>
              </Typography>
              <Typography
                variant="subtitle2"
                component="h3"
                gutterBottom
                style={{
                  textAlign: "left",
                  paddingLeft: "5%",
                  color: "#808080",
                }}
              >
                * Data in graph is on the basis of filters applied on table
                below{" "}
              </Typography>
            </div>
          </div>
        )}
      </div>

      <div className="ag-theme-alpine">
        {isAdmin.status && historical?.data?.results && (
          <div className="table_container1">
            <CustomizedTableHistorical
              setReRunUseEffect={setReRunUseEffect}
              setHistorical={setHistorical}
              option={option}
              optionMcap={optionMcap}
              optionStage={optionStage}
              optionSentiment={optionSentiment}
              startDate={startDate}
              endDate={endDate}
              search={search}
              TA={TA}
              //  setTA={setTA}
              // resetAll={resetAll}
              // form={form}

              ref={gridRef}
              columnList={COL_LIST}
              //  data={data?.results}
              data={filter.flag ? filter.data : historical?.data}
              count={historical.data?.count ? historical?.data?.count : ""}
              isAdmin={isAdmin.status}
            />
            {/* {console.log("Status", isAdmin.status)} */}
          </div>
        )}
      </div>
      {historical?.data?.count > 0 && (
        <Pagination
          sx={{ display: "flex", justifyContent: "center" }}
          variant="outlined"
          shape="rounded"
          color="primary"
          //page={pageNumber}
          count={renderPages()}
          page={pageNumber}
          onChange={handleChangePage}
        />
      )}
    </div>
  );
};
