import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import { Pagination } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { DatePicker, Space } from "antd";
import apiClient from "../../../utils";
import { useDispatch } from "react-redux";
import { apiFailure } from "../../../logic";
import "./newsindex.css";
import NewsCards from "./Newscards";

const NewsHeading = () => {
  const [option, setOption] = useState([]);
  const [search1, setSearch1] = useState("fierce_news");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [news, setNews] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const PageSize = 10;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const setOptions = () => {
    const apiPayload = { ...API_CONFIG.COMPANY_NAME };
    apiClient(apiPayload)
      .then((res) => {
        let arr = res?.data.map((data, idx) => {
          return { label: data.company_name, value: data.ticker };
        });
        setOption(arr);
      })
      .catch((err) => {
        dispatch(apiFailure(err));
      });
  };

  const getFierceNews = () => {
    const apiPayload = { ...API_CONFIG.NEWS };
    const queryParam = new URLSearchParams();

    if (search1 && search1 !== "fierce_news") {
      queryParam.set("ticker", search1);
    } else {
      queryParam.set("ticker", "fierce_news");
    }

    queryParam.set("page", pageNumber);

    apiPayload.url = `/news?${queryParam.toString()}`;

    apiClient(apiPayload)
      .then((res) => {
        setNews(res);
      })
      .catch((err) => {
        console.log({ message: err });
        dispatch(apiFailure(err));
      });
  };

  useEffect(() => {
    setOptions();
    history.push(`/newsplot?ticker=fierce_news`);
    return () => {
      setNews("");
    };
  }, []);

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    const page = queryParam.get("page");
    const searchParam = queryParam.get("ticker");

    if (page) {
      setPageNumber(parseInt(page));
    }

    if (searchParam) {
      setSearch1(searchParam);
    }

    getFierceNews();
  }, [location.search]);

  const API_CONFIG = {
    COMPANY_NAME: {
      method: "GET",
      url: "/company_info/",
      data: {},
    },
    NEWS: { method: "GET", url: "/news/", data: {} },
  };

  const handleChangePage = (_event, pageNo) => {
    const queryParam = new URLSearchParams(history.location.search);
    const apiConfig = { ...API_CONFIG.NEWS };
    if (startDate && startDate !== "") {
      queryParam.set("startdate", startDate);
    } else {
      queryParam.delete("startdate");
    }
    if (endDate && endDate !== "") {
      queryParam.set("enddate", endDate);
    } else {
      queryParam.delete("enddate");
    }
    queryParam.set("ticker", search1);
    queryParam.set("page", pageNo);
    queryParam.set("page_size", PageSize);
    apiConfig.url += `?${queryParam}`;
    setPageNumber(pageNo);

    apiClient(apiConfig)
      .then((res) => {
        if (res.data) {
          setNews(res);
        }
      })
      .catch((err) => {
        dispatch(apiFailure(err));
      });
  };

  const renderPages = () => {
    let totalItems = news?.data?.count;
    if (totalItems > 0) {
      let pages = Math.ceil(totalItems / PageSize);
      return pages;
    }
  };

  const setStartDatef = (date, dateString, id) => {
    setStartDate(dateString);
  };

  const setEndDatef = (date, dateString, id) => {
    setEndDate(dateString);
  };

  useEffect(() => {
    filterData();
  }, [startDate, endDate]);

  const filterData = () => {
    const params = new URLSearchParams(history.location.search);
    if (startDate && startDate !== "") {
      params.set("startdate", startDate);
    } else {
      params.delete("startdate");
    }
    if (endDate && endDate !== "") {
      params.set("enddate", endDate);
    } else {
      params.delete("enddate");
    }
    const apiPayload = { ...API_CONFIG.NEWS };
    apiPayload.url += `?${params.toString()}`;

    apiClient(apiPayload)
      .then((res) => {
        setNews(res);
      })
      .catch((err) => {
        dispatch(apiFailure(err));
      });
  };

  //this function is called when ticker is changed in autocomplete component
  const handleAutocompleteChange = (_, selectedOption) => {
    if (selectedOption) {
      setSearch1(selectedOption.value);
      setPageNumber(1);
      const queryParam = new URLSearchParams(location.search);
      queryParam.set("ticker", selectedOption.value);
      queryParam.set("page", "1");
      history.push({ search: queryParam.toString() });
    }
  };

  // const handleAutocompleteChange = (_, selectedOption) => {
  //   if (selectedOption) {
  //     setSearch1(selectedOption.value);
  //     setPageNumber(1); // Reset pageNumber to 1

  //     // Update the URL with the new search1 and reset page
  //     const queryParam = new URLSearchParams(location.search);
  //     queryParam.set("ticker", selectedOption.value);
  //     queryParam.set("page", "1");
  //     history.push({ search: queryParam.toString() });

  //     // Call the API with the updated search1 and pageNumber
  //     getFierceNews();
  //   }
  // };

  return (
    <div>
      <div className="newsmain">
        <h1 style={{ fontWeight: "bold", textAlign: "left" }}>News</h1>
      </div>
      <form>
        <Grid container>
          <Grid item>
            {option && (
              <Autocomplete
                id="combo-box-demo"
                options={option}
                getOptionLabel={(option) => option.label}
                // disableClearable={true}
                onChange={handleAutocompleteChange}
                style={{
                  width: 300,
                  backgroundColor: "white",
                  marginLeft: "6%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search by Ticker, Company Name"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Grid>
          <div
            style={{
              width: " 70%",
              display: "block",
              alignItems: "left",
              height: "5%",
              paddingTop: "1%",
            }}
          >
            <Space style={{ paddingRight: "4%" }}>
              Start date:
              <DatePicker
                format={"YYYY-MM-DD"}
                placeholder="(YYYY-MM-DD)"
                onChange={(date, dateString) =>
                  setStartDatef(date, dateString, 1)
                }
              />
            </Space>
            <Space>
              End date:
              <DatePicker
                format={"YYYY-MM-DD"}
                placeholder="(YYYY-MM-DD)"
                onChange={(date, dateString) =>
                  setEndDatef(date, dateString, 1)
                }
              />
            </Space>
          </div>
        </Grid>
      </form>
      <div className="newscardsmain">
        <NewsCards data={news} pageNumber={pageNumber} search1={search1} />
      </div>
      <div>
        {news?.data?.count !== 0 && (
          <Pagination
            sx={{ display: "flex", justifyContent: "center" }}
            variant="outlined"
            shape="rounded"
            color="primary"
            page={pageNumber}
            count={renderPages()}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};

export default NewsHeading;
