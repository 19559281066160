/* eslint-disable no-unused-expressions */
import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
// import Paper from "@mui/material/Paper";
// import { makeStyles } from "@material-ui/core";
// import "./index.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
    // border: "1px solid black",
    // borderRadius: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    // border: "1px solid black",
    // borderRadiusLeftTop: "10px",
    // borderRadiusRightTop: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: "1px solid black",
    // borderRadius: "10px",
  },
}));

export default function CheckListTable({ columnList, data, top }) {
  const renderHeadCols = () => {
    return columnList.map((row, index) => (
      <StyledTableCell
        key={`head_${row.label}_${index}`}
        align="center"
        className="checklist_headCells"
      >
        {row.label}
      </StyledTableCell>
    ));
  };

  const renderCells = (row) => {
    return columnList.map((col, index) => (
      <StyledTableCell
        //  className={col.tableCellClassName || ""}
        // className="checklist_bodyCells"
        key={`row_${col.key}_${index}`}
        align="left"
      >
        {/* {console.log("/////////////// this", row[col.key])} */}
        {/* col.customCell?.(row, index) || row?.[col.key] */}
        {typeof row?.[col.key] === "number" ||
        typeof row?.[col.key] === "string"
          ? row[col.key] !== ""
            ? row[col.key]
            : "-"
          : "-"}
      </StyledTableCell>
    ));
  };

  const renderTableRows = () => {
    return data[0] ? (
      data.map((row, index) => (
        <StyledTableRow key={`row_${index}`}>{renderCells(row)}</StyledTableRow>
      ))
    ) : (
      <Typography
        variant="h6"
        component="h2"
        style={{
          textAlign: "left",
          fontWeight: "bold",
          fontStyle: "italic",
          padding: "5% 0",
        }}
      >
        "No Data Found"
      </Typography>
    );
  };

  // const useStyles = makeStyles({
  //   customTableContainer: {
  //     overflowX: "initial",
  //   },
  // });
  // const classes = useStyles();

  return (
    <div>
      <h3 className="checklist_results">
        {top ? "Scoring based Parameters" : "Information based Parameters"}
      </h3>
      <TableContainer
        //className="check_table"
        sx={{ height: "55vh" }}
      >
        {/*  <p style={{ textAlign: "right", padding: "5px" }}>Showing 15 out of 30</p> */}

        <Table sx={{}} aria-label="customized table" stickyHeader>
          <TableHead>
            <TableRow>{renderHeadCols()}</TableRow>
          </TableHead>
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
