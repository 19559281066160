import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Styles from "./index.module.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#076281",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    //backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const ResultTable = ({ resultTableData }) => {
  return (
    <TableContainer
      component={Paper}
      elevation={12}
      sx={{ marginTop: "30px", padding: "1.5%" }}
    >
      <Typography variant="h6"  component="h3" style={{ margin: "10px 0", fontWeight: "bold" }}>
        Output/Results
      </Typography>

      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Drug</StyledTableCell>
            <StyledTableCell align="center">Disease</StyledTableCell>
            <StyledTableCell align="center">NPV of Revenue</StyledTableCell>
            <StyledTableCell align="center">Contribution</StyledTableCell>
            <StyledTableCell align="center">Peak Sales</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resultTableData.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                className={Styles.pipelineTableHeader}
                component="th"
                scope="row"
              >
                {row.drug}
              </StyledTableCell>
              <StyledTableCell align="left">{row.disease}</StyledTableCell>
              <StyledTableCell align="center">{`${Number(row.npv).toFixed(2)}`}</StyledTableCell>
              <StyledTableCell align="center">
                {`${Number(row.contribution).toFixed(2)}%`}
              </StyledTableCell>
              <StyledTableCell align="center">{`${Number(row.peak_sales).toFixed(2)}`}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
