import React from "react";
import { Button, Typography, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RevenueImage from "../../../../../assests/Revenue.png";

export const RevenueGrowth = () => {
  return (
    <div>
      <div style={{ display: "flex", padding: "2%", alignItems: "center" }}>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: "#fff",
            color: "#000",
            boxShadow: "1px 1px #D3D3D3",
          }}
          onClick={() => {
            window.history.go(-1);
          }}
        >
          <ArrowBackIcon fontSize="small" />
          Back
        </Button>
        <Typography
          variant="h6"
          gutterBottom
          component="h2"
          style={{ fontWeight: "bold", marginLeft: "3%", marginTop: "0.5%" }}
        >
          DCF screen - instruction
        </Typography>
      </div>
      <Typography
        variant="h6"
        gutterBottom
        component="h5"
        style={{ fontWeight: "bold", margin: "0 2% 2% 2%", textAlign: "left" }}
      >
        Revenue Growth Assumptions
      </Typography>
      <div style={{ margin: "0 2%" }}>
        <Paper style={{ padding: "3%" }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h5"
            style={{ fontWeight: "bold", margin: "2.5% 0", textAlign: "left" }}
          >
            Peak Sales is assumed to reach after 5 years of commercialisation.
          </Typography>
          <Typography
            variant="button"
            component="h5"
            style={{ fontWeight: "bold", marginTop: "1%", textAlign: "left" }}
          >
            Page 23 : Lifetime_Trends_in_Biopharmaceutical_Innovation.pdf
          </Typography>
          <div style={{ display: "flex" }}>
            <a
              target="_blank"
              href="https://www.statnews.com/wp-content/uploads/2017/01/Lifetime_Trends_in_Biopharmaceutical_Innovation.pdf"
            >
              https://www.statnews.com/wp-content/uploads/2017/01/Lifetime_Trends_in_Biopharmaceutical_Innovation.pdf
            </a>
          </div>
          <img src={RevenueImage} alt="DCF" style={{ width: "100%" }} />
        </Paper>
      </div>
    </div>
  );
};
