import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { Box } from "@material-ui/core";
import { useColorIndication } from "./ColorIndication";
//import { arc } from "d3";

function ProgressArc(props) {
  const { svgWidth, arcWidth, progressPercentage, colorIndicator } = props;
  const svgHeight = svgWidth;
  const arcOuterRadius = svgWidth / 2;
  const arcInnerRadius = svgWidth / 2 - arcWidth;
  const arcGenerator = d3
    .arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(5);

  const progressArc = (value) =>
    arcGenerator({
      endAngle: 2 * Math.PI * value,
    });

  return (
    <div onClick={props.onClick} role="presentation">
      <svg height={svgHeight} width={svgWidth}>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path d={progressArc(1)} opacity="0.2" fill="gray" />
        </g>
        <g transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}>
          <path
            d={progressArc(progressPercentage / 100)}
            fill={colorIndicator}
          />
          <text x="-10" y="5">
            {`${progressPercentage}%`}
          </text>
        </g>
      </svg>
    </div>
  );
}

export function ProgressCircleWrapper(props) {
  // console.log(props.recPercent);
  const passPercent = parseInt(props.recPercent);
  const svgWidth = 65;
  const arcWidth = 5;
  const [progressPercentage, setProgressPercentage] = useState(0);
  const colorIndicator = useColorIndication(passPercent);
  /*   function valuetext(value) {
    return `${value}°C`;
  } */
  /*      function setProgressValue(event, value) {
    setProgressPercentage(value);
  }  */

  useEffect(() => {
    setProgressPercentage(passPercent);
  }, []);

  return (
    <Box padding="1rem" justifyContent="center">
      <ProgressArc
        svgWidth={svgWidth}
        arcWidth={arcWidth}
        progressPercentage={progressPercentage}
        colorIndicator={colorIndicator}
        onClick={props.onClick}
      />
    </Box>
  );
}
